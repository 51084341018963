var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FlotoCrudContainer',{ref:"list",staticClass:"h-100",attrs:{"as-table":"","columns":_vm.columns,"fetch-fn":_vm.getApprovers,"update-fn":_vm.updateApprover},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"name",fn:function({ item }){return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.email ? `${item.name} (${item.email})` : item.name)+" ")])]}},{key:"status",fn:function({ item, items }){return [_c('td',{class:{
        flex: true,
        'flex-wrap': true,
        'items-center': true,
      }},[_c('StatusIcon',{attrs:{"status":item.status,"show-status":"","size":"lg"}}),_c('div',{staticClass:"w-full"},[(item.status !== 'pending')?_c('small',{staticClass:"text-neutral"},[_vm._v(" "+_vm._s(_vm.$t('by'))+" "),(item.approvedUserId)?_c('FlotoUserDrawer',{attrs:{"user-id":item.approvedUserId,"disabled":_vm.isPortalLogin}}):_c('span',{staticClass:"text-primary"},[_vm._v("System")]),_vm._v(" "+_vm._s(_vm.$t('at'))+" "+_vm._s(_vm._f("datetime")(item.updatedAt))+" ")],1):_vm._e()]),(_vm.stage.makerCheckerApproval)?_c('div',{staticClass:"flex items-center mt-1"},[_c('ApprovalAssetUpdateDrawer',{attrs:{"stage-id":_vm.stage.approvalId},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc('asset_update'))+" ")]},proxy:true}],null,true)}),_c('span',{staticClass:"mx-2"},[_vm._v("|")])],1):_vm._e(),(item.status === 'pending' && !_vm.hideAction)?_c('div',{staticClass:"flex items-center mt-1"},[_c('FlotoDeleteBtn',{attrs:{"id":"ignore-btn","message":_vm.$t('confirm_ignore')},on:{"confirm":function($event){return _vm.ignoreApprover(item.id)}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',[_vm._v(" "+_vm._s(_vm.$t('ignore'))+" ")])]},proxy:true}],null,true)}),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_c('a',{attrs:{"id":"remind-btn"},on:{"click":function($event){return _vm.remindApproval(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('remind'))+" ")]),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_c('FlotoDeleteBtn',{attrs:{"id":"delete-btn","message":_vm.$t('confirm_delete_item', {
              item: `${_vm.$tc('Approver')}`,
            })},on:{"confirm":function($event){return _vm.deleteApprover(item.id, items)}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])]},proxy:true}],null,true)})],1):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }