import { generateId } from '../utils/id'
import { transformRecursive } from './recursive'

export function transformCategoryRecursive(categories) {
  return transformRecursive(categories, transformCategory)
}

export function transformCategory(category) {
  return {
    id: category.id,
    value: category.id,
    label: category.name,
    order: category.objOrder,
    name: category.name,
    description: category.description,
    disabled: category.disabled,
    children: [],
    canDelete: category.deleteableOob,
    archived: category.removed,
    canEdit: category.updatebleOob,
    parentId: category.parentId,
    categoryPrefix: category.categoryPrefix,
    requesterGroupIds: category.requesterGroupIds,
    companyIds: category.companyIds,
    companyWisePrefix:
      (category.companyWisePrefix || []).map(transformPrefixInfo) || [],
    readPermission: category.readPermission,
    color: category.colourCode,
  }
}

export function transformPrefixInfo(info) {
  return {
    guid: generateId(),
    companyId: info.companyId,
    prefixValue: info.prefixValue || '',
  }
}

export function transformCategoryConfig(config) {
  return {
    id: config.id,
    allowedRequestLeafNode: config.allowedRequestLeafNode,
    allowedProblemLeafNode: config.allowedProblemLeafNode,
    allowedAssetLeafNode: config.allowedAssetLeafNode,
    allowedChangeLeafNode: config.allowedChangeLeafNode,
    allowedReleaseLeafNode: config.allowedReleaseLeafNode,
    reportHierarchy: config.reportHierarchy,
    categoryPrefix: config.categoryPrefix,
  }
}

export function transformCategoryConfigForServer(config) {
  return {
    allowedRequestLeafNode: config.allowedRequestLeafNode,
    allowedProblemLeafNode: config.allowedProblemLeafNode,
    allowedAssetLeafNode: config.allowedAssetLeafNode,
    allowedChangeLeafNode: config.allowedChangeLeafNode,
    allowedReleaseLeafNode: config.allowedReleaseLeafNode,
    reportHierarchy: config.reportHierarchy,
    categoryPrefix: config.categoryPrefix,
    requesterGroupIds: config.requesterGroupIds,
    companyIds: config.companyIds,
    companyWisePrefix:
      (config.companyWisePrefix || []).map(transformPrefixInfoForServer) || [],
  }
}

export function transformPrefixInfoForServer(info) {
  return {
    companyId: info.companyId,
    prefixValue: info.prefixValue || '',
  }
}
