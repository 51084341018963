var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.compactView)?_c('div',{staticClass:"flex flex-1 items-center"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"flex items-center"},[_vm._t("icon-container",function(){return [(_vm.iconName)?_c('div',{staticClass:"flex items-center text-neutral",style:(_vm.applyColorInCompactView
                ? {
                    color: _vm.currentItem.color,
                  }
                : {})},[_vm._t("icon",function(){return [(_vm.customIcon)?_c('CustomIcon',{attrs:{"size":"lg","name":_vm.iconName}}):_c('MIcon',{attrs:{"name":_vm.iconName,"size":"lg"}})]})],2):_vm._e()]})],2)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.lable)+": "+_vm._s(_vm.currentItem.lable || _vm.currentItem.name || _vm.currentItem.text)+" ")])],1):_c('div',{staticClass:"flex flex-1 dropdown-trigger items-center",class:{
    'cursor-pointer': !_vm.disabled,
    'secondary-trigger': _vm.type === 'secondary',
    'mr-4': _vm.useMargin,
  }},[_vm._t("icon-container",function(){return [(_vm.iconName)?_c('div',{staticClass:"icon-container",style:({
        backgroundColor: _vm.currentStyle.backgroundColor,
        color: _vm.currentStyle.color,
      })},[_vm._t("icon",function(){return [(_vm.customIcon)?_c('CustomIcon',{attrs:{"name":_vm.iconName}}):_c('MIcon',{attrs:{"name":_vm.iconName,"size":_vm.type === 'secondary' ? 'lg' : '2x'}})]})],2):_vm._e()]}),_c('div',{staticClass:"details flex flex-1 flex-col text-ellipsis py-1 mx-1"},[(_vm.lable)?_c('div',{staticClass:"lable-text text-ellipsis",class:{ 'field-lable-required': _vm.required }},[_vm._v(" "+_vm._s(_vm.lable)+" ")]):_vm._e(),_c('div',{staticClass:"value-text text-ellipsis"},[_vm._v(" "+_vm._s(_vm.currentItem.lable || _vm.currentItem.name || _vm.currentItem.text)+" ")])]),(!_vm.disabled)?_c('div',{staticClass:"action-arrow"},[_c('CustomIcon',{attrs:{"name":"downArrow"}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }