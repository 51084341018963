<script>
import { getRolesApi } from '@modules/roles/roles-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoRolePicker',
  extends: _baseDropdownPicker,
  model: {
    event: 'change',
  },
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    value: { type: [Number, Array, String], default: undefined },
    mandatory: { type: Boolean, default: false },
  },
  data() {
    this.selectedEventName = 'roleSelected'
    return {
      loadedOptions: [],
    }
  },
  computed: {
    options() {
      return this.loadedOptions
    },
  },
  created() {
    getRolesApi(null, 1000, 0, { fullobject: false }).then(({ items }) => {
      this.$emit(
        'super-admin-role',
        (items || []).filter((i) => i.roleType === 'super_admin')
      )
      this.$emit(
        'dashboard-viewer-role',
        (items || []).filter((i) => i.name === 'Dashboard Viewer')
      )
      this.$emit(
        'report-viewer-role',
        (items || []).filter((i) => i.name === 'Report Viewer')
      )
      if (!this.value || !(this.value || []).length) {
        this.setDefaultValue(items)
      }
      return (this.loadedOptions = items.map((i) => ({ ...i, key: i.id })))
    })
  },
  methods: {
    setDefaultValue(options) {
      if (this.mandatory) {
        let defaultValue = options.find((v) => v.isDefault)
        if (!defaultValue) {
          defaultValue = options[0]
        }
        if (defaultValue) {
          this.$emit('change', [defaultValue.id])
          this.$emit('set-default-value', [defaultValue.id])
        }
      }
    },
  },
}
</script>
