var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex"},[_vm._t("bot-avatar"),_c('div',{staticClass:"content w-full",class:{ 'my-message': _vm.isMyMessage }},[(_vm.message.message)?_c('div',{staticClass:"flex flex-col",domProps:{"innerHTML":_vm._s(_vm.message.message)}}):_vm._e(),_c('div',{staticClass:"flex flex-col"},_vm._l((_vm.message.options),function(option,index){return _c('div',{key:index,staticClass:"flex mx-2 my-2 rounded action-message-options"},[_c('div',{staticClass:"py-3 px-2 icon-container"},[(
              (option.rightHeaderLink && option.rightHeaderLink.model) ||
              (option.leftHeaderLink && option.leftHeaderLink.model)
            )?_c('PluginMessageModuleIcon',{attrs:{"module-name":(option.rightHeaderLink && option.rightHeaderLink.model) ||
              (option.leftHeaderLink && option.leftHeaderLink.model)}}):_c('MIcon',{staticClass:"mx-1",attrs:{"name":"tire-rugged","size":"2x"}})],1),_c('div',{staticClass:"flex flex-col p-2 flex-1 min-w-0"},[(option.rightHeaderLink || option.leftHeaderLink)?_c('div',{staticClass:"flex flex-col",attrs:{"gutter":0}},[((option.leftHeaderLink || {}).text)?_c('div',{staticClass:"flex items-center pb-1"},[(option.leftHeaderLink && option.leftHeaderLink.model)?_c('FlotoLink',{key:option.leftHeaderLink.text,attrs:{"target":"_blank","to":_vm.getDetailLinkForModule(
                    option.leftHeaderLink.model,
                    option.leftHeaderLink.id,
                    _vm.$modules,
                    option.leftHeaderLink.model
                  ).link}},[_vm._v(" "+_vm._s((option.leftHeaderLink || {}).text)+" ")]):_c('div',[_vm._v(" "+_vm._s((option.leftHeaderLink || {}).text)+" ")])],1):_vm._e(),((option.rightHeaderLink || {}).text)?_c('div',{staticClass:"flex items-center pb-1"},[(option.rightHeaderLink && option.rightHeaderLink.model)?_c('FlotoLink',{key:option.rightHeaderLink.text,attrs:{"target":"_blank","to":_vm.getDetailLinkForModule(
                    option.rightHeaderLink.model,
                    option.rightHeaderLink.id,
                    _vm.$modules,
                    option.rightHeaderLink.model
                  ).link}},[_vm._v(" "+_vm._s((option.rightHeaderLink || {}).text)+" ")]):_c('div',[_vm._v(" "+_vm._s((option.rightHeaderLink || {}).text)+" ")])],1):_vm._e()]):_vm._e(),_vm._l((option.response),function(o){return _c('div',{key:o.key,staticClass:"flex flex-col",attrs:{"gutter":0}},[_c('div',{staticClass:"flex items-center pb-1 text-ellipsis"},[_vm._v(" "+_vm._s(o.key)+": "),_c('span',{staticClass:"page-secondary-text-color px-1 text-ellipsis",domProps:{"innerHTML":_vm._s(o.text)}})])])})],2)])}),0)]),_vm._t("sender-avatar")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }