var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FieldContainer',_vm._g(_vm._b({attrs:{"field":_vm.field,"section-id":_vm.sectionId}},'FieldContainer',_vm.containerAttrs,false),_vm.containerListeners),[(_vm.disabled)?_c('FlotoFormItem',{attrs:{"label":_vm.field.title,"rules":_vm.validationsRules}},[(_vm.currentValue)?[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.currentValue,undefined, _vm.field.attributes.allowTime))+" ")]:[_vm._v(" --- ")]],2):_c('FlotoFormItem',{attrs:{"label":_vm.field.title,"rules":_vm.validationsRules}},[_c('div',{class:{
        'w-full': _vm.field.attributes.widthClass !== 'w-full',
        'w-1/4': _vm.field.attributes.widthClass === 'w-full',
      }},[_c('FlotoDatePicker',_vm._g(_vm._b({attrs:{"show-time":_vm.field.attributes.allowTime,"name":_vm.field.title,"disabled":_vm.disabled ||
          _vm.mode === 'builder' ||
          _vm.field.attributes.systemManagedField ||
          _vm.isDisable,"read-only":_vm.readOnly},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'FlotoDatePicker',_vm.attrs,false),_vm.listeners))],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }