var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FlotoContentLoader',{attrs:{"loading":_vm.loading}},[(_vm.barcodesChunk.length)?_c('div',{staticClass:"flex flex-1 flex-col",attrs:{"id":"printableArea"}},_vm._l((_vm.barcodesChunk),function(barcodes,index){return _c('div',{key:index,staticClass:"flex",style:({ marginBottom: _vm.getMargin })},_vm._l((barcodes),function(barcode){return _c('div',{key:barcode,staticClass:"cursor-pointer",style:({
          width: _vm.getWidth,
          height: _vm.getHeight,
          marginRight: _vm.getMargin,
          backgroundImage: `url(${_vm.generateImageString(barcode)})`,
          backgroundSize: `${_vm.getWidth} ${_vm.getHeight}`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          border: '2px dashed #ccc',
        })})}),0)}),0):_vm._e(),(_vm.prepairedAssets.length)?_c('div',{staticClass:"flex flex-1 flex-col",attrs:{"id":"printableArea"}},_vm._l((_vm.prepairedAssets),function(asset,index){return _c('div',{key:index,style:({
        marginBottom: _vm.getMargin,
        width: _vm.getWidth,
        border: '2px dashed #ccc',
        padding: '5px',
      })},[_vm._l((asset),function(value,key){return _c('div',{key:key,staticStyle:{"word-wrap":"break-word"}},[(key && _vm.excludedFields.indexOf(key) === -1)?[_c('b',[_vm._v(_vm._s(key)+":")]),_vm._v(" "+_vm._s(value)+" ")]:_vm._e()],2)}),_c('div',{staticClass:"cursor-pointer",style:({
          width: '100%',
          height: _vm.getHeight,
          backgroundImage: `url(${_vm.generateImageString(asset.barCode)})`,
          backgroundSize: `${_vm.getWidth} ${_vm.getHeight}`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        })})],2)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }