var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MRow',{staticClass:"flex-grow",attrs:{"gutter":0}},[_c('MCol',{staticClass:"preview-holder",attrs:{"size":_vm.wrapperSize}},[(_vm.formFields.length === 0 && _vm.showNoData)?_c('div',[_vm._t("no-fields",function(){return [_c('FlotoNoData')]})],2):_c('FlotoContentLoader',{attrs:{"loading":_vm.loading}},[_c(_vm.withSubmit ? 'FlotoForm' : 'div',{key:_vm.renderCount,ref:"formRef",tag:"component",staticClass:"flex flex-wrap flex-col",class:{ 'read-only-form': _vm.disabled },attrs:{"id":"ticket-form"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([(_vm.withSubmit)?{key:"submit",fn:function(submitSlotData){return [_c('div',{class:_vm.isRtl ? 'text-left' : 'text-right'},[(!_vm.disabled)?_vm._t("actions",function(){return [_vm._t("submit",function(){return [_c('MButton',{attrs:{"id":"create-btn","type":"submit"}},[_vm._t("submit-text",function(){return [_vm._v(_vm._s(_vm.$t('create')))]})],2)]},{"reset":submitSlotData.submit}),_vm._t("reset",function(){return [_c('MButton',{staticClass:"ml-4",attrs:{"id":"cancel-btn","variant":"default"},on:{"click":submitSlotData.reset}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]},{"reset":submitSlotData.reset})]},{"submit":_vm.handleSubmit},submitSlotData):_vm._e()],2)]}}:null],null,true)},[_c('MRow',{staticClass:"flex-1 w-full",attrs:{"gutter":0}},[(
              (_vm.additionalCustomFormFields || []).length > 0 &&
              [_vm.$constants.REQUEST, _vm.$constants.SERVICE_CATALOG].indexOf(
                _vm.moduleName
              ) >= 0
            )?[_c('h5',[_vm._v(_vm._s(_vm.$tc('service_request'))+" "+_vm._s(_vm.$tc('field', 2)))]),_c('MDivider',{staticClass:"mt-0"}),_vm._l((_vm.additionalCustomFormFields),function(element){return [_c('Field',_vm._g(_vm._b({key:element.id,attrs:{"id":element.guid,"focus-event-brodcast":_vm.focusEventBrodcast,"disabled":_vm.disabled,"value":_vm.formValue[
                    element.isSystemField ? element.paramName : element.id
                  ],"group-id":_vm.formValue.groupId,"field":element,"module-name":_vm.moduleName,"validate-archived-value":_vm.validateArchivedValue,"api-field-update-fn":_vm.apiFieldUpdateFn,"apply-form-rules":_vm.applyFormRules,"field-rules-state":_vm.fieldRulesState,"form-value":_vm.formValue,"dynamic-field-options":_vm.dynamicFieldOptionsMap[element.id]},on:{"templateSelected":_vm.handleTemplateSelected,"requesterSelected":_vm.handleRequesterSelected,"requesterIdSelected":_vm.handleRequesterIdSelected,"update-value":($event, isDelay) =>
                    _vm.handleUpdate(
                      {
                        [element.isSystemField
                          ? element.paramName
                          : element.id]:
                          element.type === 'richtextarea'
                            ? $event || ''
                            : $event,
                      },
                      isDelay,
                      element.isSystemField ? element.paramName : element.id
                    ),"field-blur":function($event){return _vm.handleFieldBlur({
                    [element.isSystemField ? element.paramName : element.id]:
                      '',
                  })},"set-default-value":($event) =>
                    _vm.handleSetFieldDefaultValue(element.paramName, $event),"remove-executed-script":_vm.handleRemoveExexutedScript,"custom-sript-set-value-executed":_vm.handleFieldBlur}},'Field',{
                  ...(element.type !== 'section'
                    ? { 'data-guid': element.guid }
                    : {}),
                  'service-catalog-id': _vm.$attrs['service-catalog-id'],
                  'show-load-indicator': _vm.$attrs['show-load-indicator'],
                  'defalut-task-type-system-name':
                    _vm.$attrs['defalut-task-type-system-name'],
                },false),_vm.listeners),[(element.type === 'section')?_c('div',{staticClass:"w-full flex flex-row flex-wrap h-full content-start relative"},[_vm._l((element.fields),function(sectionField){return [_c('Field',_vm._g(_vm._b({key:sectionField.id,attrs:{"id":sectionField.guid,"data-guid":sectionField.guid,"focus-event-brodcast":_vm.focusEventBrodcast,"module-name":_vm.moduleName,"disabled":_vm.disabled,"value":_vm.formValue[
                          sectionField.isSystemField
                            ? sectionField.paramName
                            : sectionField.id
                        ],"field":sectionField,"validate-archived-value":_vm.validateArchivedValue,"api-field-update-fn":_vm.apiFieldUpdateFn,"apply-form-rules":_vm.applyFormRules,"field-rules-state":_vm.fieldRulesState,"dynamic-field-options":_vm.dynamicFieldOptionsMap[sectionField.id]},on:{"update-value":($event, isDelay) =>
                          _vm.handleUpdate(
                            {
                              [sectionField.isSystemField
                                ? sectionField.paramName
                                : sectionField.id]:
                                sectionField.type === 'richtextarea'
                                  ? $event || ''
                                  : $event,
                            },
                            isDelay,
                            sectionField.isSystemField
                              ? sectionField.paramName
                              : sectionField.id
                          ),"field-blur":function($event){return _vm.handleFieldBlur({
                          [sectionField.isSystemField
                            ? sectionField.paramName
                            : sectionField.id]: '',
                        })},"set-default-value":($event) =>
                          _vm.handleSetFieldDefaultValue(
                            sectionField.paramName,
                            $event
                          ),"remove-executed-script":_vm.handleRemoveExexutedScript,"custom-sript-set-value-executed":_vm.handleFieldBlur}},'Field',{
                        'service-catalog-id': _vm.$attrs['service-catalog-id'],
                        'show-load-indicator': _vm.$attrs['show-load-indicator'],
                        'defalut-task-type-system-name':
                          _vm.$attrs['defalut-task-type-system-name'],
                      },false),_vm.listeners))]})],2):_vm._e()])]}),_c('h5',[_vm._v(_vm._s(_vm.$tc('incident'))+" "+_vm._s(_vm.$tc('field', 2)))]),_c('MDivider',{staticClass:"mt-0"})]:_vm._e(),_vm._l((_vm.availableFormField),function(element){return [_c('Field',_vm._g(_vm._b({key:element.id,attrs:{"id":element.guid,"focus-event-brodcast":_vm.focusEventBrodcast,"disabled":_vm.disabled,"value":_vm.formValue[
                  element.isSystemField ? element.paramName : element.id
                ],"group-id":_vm.formValue.groupId,"field":element,"module-name":_vm.moduleName,"validate-archived-value":_vm.validateArchivedValue,"api-field-update-fn":_vm.apiFieldUpdateFn,"apply-form-rules":_vm.applyFormRules,"field-rules-state":_vm.fieldRulesState,"form-value":_vm.formValue,"dynamic-field-options":_vm.dynamicFieldOptionsMap[element.id]},on:{"templateSelected":_vm.handleTemplateSelected,"requesterSelected":_vm.handleRequesterSelected,"requesterIdSelected":_vm.handleRequesterIdSelected,"update-value":($event, isDelay) =>
                  _vm.handleUpdate(
                    {
                      [element.isSystemField
                        ? element.paramName
                        : element.id]:
                        element.type === 'richtextarea'
                          ? $event || ''
                          : $event,
                    },
                    isDelay,
                    element.isSystemField ? element.paramName : element.id
                  ),"field-blur":function($event){return _vm.handleFieldBlur({
                  [element.isSystemField ? element.paramName : element.id]:
                    '',
                })},"set-default-value":($event) =>
                  _vm.handleSetFieldDefaultValue(element.paramName, $event),"remove-executed-script":_vm.handleRemoveExexutedScript,"custom-sript-set-value-executed":_vm.handleFieldBlur}},'Field',{
                ...(element.type !== 'section'
                  ? { 'data-guid': element.guid }
                  : {}),
                'service-catalog-id': _vm.$attrs['service-catalog-id'],
                'defalut-task-type-system-name':
                  _vm.$attrs['defalut-task-type-system-name'],
                'user-type': _vm.$attrs['user-type'],
                'show-load-indicator': _vm.$attrs['show-load-indicator'],
                'manager-user-id': _vm.$attrs['manager-user-id'],
                ...([`${element.inputType}_disabled`] in _vm.$attrs
                  ? {
                      [`${element.inputType}_disabled`]:
                        _vm.$attrs[`${element.inputType}_disabled`],
                    }
                  : {}),
              },false),_vm.listeners),[(element.type === 'section')?_c('div',{staticClass:"w-full flex flex-row flex-wrap h-full content-start relative"},[_vm._l((element.fields),function(sectionField){return [_c('Field',_vm._g(_vm._b({key:sectionField.id,attrs:{"id":sectionField.guid,"data-guid":sectionField.guid,"focus-event-brodcast":_vm.focusEventBrodcast,"module-name":_vm.moduleName,"disabled":_vm.disabled,"value":_vm.formValue[
                        sectionField.isSystemField
                          ? sectionField.paramName
                          : sectionField.id
                      ],"field":sectionField,"validate-archived-value":_vm.validateArchivedValue,"api-field-update-fn":_vm.apiFieldUpdateFn,"apply-form-rules":_vm.applyFormRules,"field-rules-state":_vm.fieldRulesState,"dynamic-field-options":_vm.dynamicFieldOptionsMap[sectionField.id]},on:{"update-value":($event, isDelay) =>
                        _vm.handleUpdate(
                          {
                            [sectionField.isSystemField
                              ? sectionField.paramName
                              : sectionField.id]:
                              sectionField.type === 'richtextarea'
                                ? $event || ''
                                : $event,
                          },
                          isDelay,
                          sectionField.isSystemField
                            ? sectionField.paramName
                            : sectionField.id
                        ),"field-blur":function($event){return _vm.handleFieldBlur({
                        [sectionField.isSystemField
                          ? sectionField.paramName
                          : sectionField.id]: '',
                      })},"set-default-value":($event) =>
                        _vm.handleSetFieldDefaultValue(
                          sectionField.paramName,
                          $event
                        ),"remove-executed-script":_vm.handleRemoveExexutedScript,"custom-sript-set-value-executed":_vm.handleFieldBlur}},'Field',{
                      'service-catalog-id': _vm.$attrs['service-catalog-id'],
                      'show-load-indicator': _vm.$attrs['show-load-indicator'],
                      'defalut-task-type-system-name':
                        _vm.$attrs['defalut-task-type-system-name'],
                      'user-type': _vm.$attrs['user-type'],
                      'manager-user-id': _vm.$attrs['manager-user-id'],
                      ...([`${element.inputType}_disabled`] in _vm.$attrs
                        ? {
                            [`${element.inputType}_disabled`]:
                              _vm.$attrs[`${element.inputType}_disabled`],
                          }
                        : {}),
                    },false),_vm.listeners))]})],2):_vm._e()])]}),(!_vm.withSubmit)?_vm._t("additional-fields",null,null,_vm.formValue):_vm._e()],2)],1),(_vm.withSubmit)?_vm._t("additional-fields",null,null,_vm.formValue):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }