var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FieldContainer',_vm._g(_vm._b({attrs:{"field":_vm.field,"section-id":_vm.sectionId}},'FieldContainer',_vm.containerAttrs,false),_vm.containerListeners),[(['description', 'signature'].indexOf(_vm.field.inputType) >= 0)?_c('FlotoFormItem',{attrs:{"label":_vm.field.title,"rules":_vm.validationsRules}},[_c('FlotoRichEditor',_vm._g(_vm._b({attrs:{"rows":8,"affix-toolbar":false,"control-preview":_vm.disabled || _vm.mode === 'builder' || _vm.isDisable,"read-only":_vm.isDisable,"disabled":_vm.isDisable},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'FlotoRichEditor',_vm.attrs,false),_vm.listeners))],1):(_vm.field.inputType === 'access_level')?_c('FlotoFormItem',{attrs:{"id":"scope"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"mb-1 flex"},[_c('label',[_vm._v(" "+_vm._s(` ${_vm.$tc('scope')}`)+" ")]),_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"text-primary mx-2",attrs:{"name":"info-circle","size":"lg"}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$tc('scope_info'))+" ")])],1)]},proxy:true}])},[_c('ScopeRadioGroup',{attrs:{"disabled":_vm.attrs['access_level_disabled']},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}})],1):(_vm.field.inputType === 'password')?_c('PasswordInput',{attrs:{"id":"password","label":_vm.$t('password'),"vid":"password","rules":{ required: true },"wrapper-col":{ xs: 5 },"placeholder":_vm.$t('password')},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}}):(_vm.field.inputType === 'confirmPassword')?_c('PasswordInput',{attrs:{"id":"confirmPassword","label":_vm.$t('confirm_password'),"rules":{
      confirmed: 'password',
      required: true,
    },"wrapper-col":{ xs: 5 },"placeholder":_vm.$t('confirm_password')},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}}):(_vm.isSwitchField)?_c('FlotoFormItem',{attrs:{"label":_vm.field.title,"rules":_vm.validationsRules}},[_c('MSwitch',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled ||
        _vm.mode === 'builder' ||
        _vm.isDisable ||
        _vm.attrs[`${_vm.field.inputType}_disabled`],"size":"small"},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'MSwitch',_vm.attrs,false),_vm.listeners)),(_vm.field.inputType === 'mfa_enrolled' && _vm.formValue.mfaEnrolled)?_c('div',{staticClass:"ml-2"},[_c('a',{on:{"click":function($event){return _vm.$emit('mfa_enrolled_reset')}}},[_vm._v(" "+_vm._s(_vm.$tc('reset'))+" "+_vm._s(_vm.$tc('two_factor_authentication'))+" ")])]):_vm._e()],1):(_vm.inputComponent !== 'MInput')?_c('FlotoFormItem',{attrs:{"label":_vm.field.title,"rules":_vm.validationsRules}},[_c(_vm.inputComponent,_vm._g(_vm._b({tag:"component",class:{
        block: _vm.isDropdownField,
        'w-full': !_vm.isDropdownField || _vm.field.attributes.widthClass === 'w-1/2',
        'w-1/2': _vm.isDropdownField && _vm.field.attributes.widthClass === 'w-full',
        'pr-2': _vm.isDropdownField && _vm.field.attributes.widthClass === 'w-full',
      },attrs:{"as-input":"","allow-clear":_vm.allowClear,"mandatory":!_vm.allowClear && _vm.mode !== 'builder',"preview":_vm.disabled || _vm.isDisable || _vm.mode === 'builder',"disabled":_vm.disabled ||
        _vm.mode === 'builder' ||
        _vm.isDisable ||
        _vm.attrs[`${_vm.field.inputType}_disabled`],"mandatory-selection":"","hidden-options-keys":_vm.hiddenOptionsKeys,"visible-options-keys":_vm.visibleOptionsKeys,"filter-data":_vm.filterData,"time-unit":_vm.field.inputType === 'notify_before'
          ? _vm.formValue.notifyBeforeHoursTimeUnit || 'hours'
          : _vm.field.inputType === 'estimated_time'
          ? _vm.formValue.estimatedTimeUnit || 'hours'
          : undefined},on:{"update:timeUnit":_vm.handleTimeUnitSync},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'component',_vm.attrs,false),_vm.listeners)),(_vm.field.inputType === 'template' && _vm.currentValue)?_c('a',{on:{"click":_vm.handleResetTemplate}},[_c('MIcon',{attrs:{"name":"undo"}}),_vm._v(" "+_vm._s(_vm.$t('reset_to_default'))+" ")],1):_vm._e()],1):_c('FlotoFormItem',_vm._g(_vm._b({attrs:{"type":_vm.field.inputType === 'description' ? 'textarea' : undefined,"label":_vm.field.title,"rules":_vm.validationsRules,"disabled":_vm.disabled || _vm.mode === 'builder' || _vm.isDisable},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'FlotoFormItem',_vm.attrs,false),_vm.listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }