var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.isSupportChannelActive && _vm.initiated && _vm.hasChatSupportedModulesInLicense
  )?_c('portal',{attrs:{"to":"chat-container"}},[(!_vm.showChatBox)?_c('div',{staticClass:"chat-trigger flex items-center justify-center",on:{"click":_vm.handleShowBox}},[(
        (_vm.chatConfigContext || {}).config.chatLogo !== undefined &&
        (_vm.chatConfigContext || {}).config.chatLogo.length
      )?_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":(_vm.chatConfigContext || {}).config.chatLogoSrc,"height":"50","width":"50"}}):_c('MBadge',{staticClass:"flex items-center justify-center",attrs:{"count":_vm.unreadCount}},[_c('MIcon',{attrs:{"name":"comments","size":"2x"}})],1)],1):_c('ChatBox',_vm._b({attrs:{"room":_vm.channel,"socket":_vm.socket,"event-prefix":_vm.eventPrefix,"default-unread-count":0,"default-visible":"","can-minimize":false,"default-messages":[],"online-users":_vm.onlineUsers,"owner":_vm.chatOwner,"chat-config":(_vm.chatConfigContext || {}).config},on:{"on-minimize":function($event){_vm.showChatBox = false},"on-close":_vm.handleCloseChatBox},scopedSlots:_vm._u([(!_vm.connected)?{key:"connection-error",fn:function(){return [_c('ConnectionError')]},proxy:true}:null,{key:"remark",fn:function(){return [(_vm.message && _vm.loggedIn)?[_vm._v(" "+_vm._s(_vm.message)+" "),(
            (_vm.channel || {}).status === 'missed' ||
            (_vm.channel || {}).status === 'completed'
          )?_c('a',{staticClass:"cursor-pointer",on:{"click":_vm.handleRestartConversation}},[_c('br'),_vm._v(" Click here to Start New Conversation ")]):_vm._e()]:_vm._e(),(!_vm.loggedIn && _vm.isChatClosedByTechnician)?[_c('a',{staticClass:"cursor-pointer",on:{"click":_vm.handleRestartConversation}},[_c('br'),_vm._v(" Click here to Start New Conversation ")])]:_vm._e()]},proxy:true}],null,true)},'ChatBox',
      _vm.channel
        ? {}
        : {
            createMessageFn: _vm.createMessageFn,
          }
    ,false))],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }