<template>
  <FlotoContentLoader :loading="loading">
    <FormRulesProvider
      :key="service.id"
      :module-name="$constants.SERVICE_CATALOG"
      :parent-resource-id="service.id"
      :custom-fields="formFields"
    >
      <MRow :gutter="16" class="min-w-0">
        <MCol
          class="min-w-0"
          :class="{
            'bg-neutral-lightest': giveExternalUpdateOptoin,
            'py-2 px-6': giveExternalUpdateOptoin,
            rounded: giveExternalUpdateOptoin,
          }"
        >
          <MRow class="min-w-0">
            <MCol
              v-if="giveExternalUpdateOptoin"
              :size="12"
              style="justify-content: flex-end"
              class="flex min-w-0"
            >
              <MButton
                :disabled="disabled || service.archived"
                variant="neutral-light"
                class="mx-1"
                :shadow="false"
                shape="circle"
                @click="handleEditCustomField"
              >
                <MIcon name="pencil" />
              </MButton>
            </MCol>
            <MCol auto-size>
              <img :src="service.image" height="150" width="150" />
            </MCol>
            <MCol class="flex-1">
              <h5 class="text-primary">
                {{ service.name }}
                {{ service.archived ? `(${$t('archived')})` : '' }}
              </h5>
              <div
                v-if="
                  (!isPortalLogin && service.amount > 0) ||
                  (isPortalLogin && service.showInCustomerPortalAmount)
                "
                class="flex"
              >
                {{ $tc('cost') }} : {{ service.amount }}
                <CurrencyPicker
                  v-model="service.currencyId"
                  text-only
                  disabled
                />
              </div>
              <div>
                {{ service.serviceDescription }}
              </div>
              <div>
                {{ serviceCatagoryName }}
              </div>
            </MCol>
          </MRow>
          <MRow class="mt-4">
            <MCol :size="12">
              <component
                :is="useFormHelpterProvider ? 'FormHelperProvider' : 'div'"
              >
                <FormConsumer
                  v-if="form.id && formFields.length"
                  :value="resource"
                  :form-fields="formFields"
                  :disabled="additionalInfoDisabled"
                  :module-name="$constants.SERVICE_CATALOG"
                  apply-form-rules
                  :avoid-default-value="true"
                  @submit="handleFormSubmitted"
                >
                  <template v-slot:submit>
                    <MButton :loading="processing" type="submit">
                      {{ $t('update') }}
                    </MButton>
                  </template>
                  <template v-slot:reset>
                    <span />
                  </template>
                </FormConsumer>
              </component>
            </MCol>
          </MRow>
        </MCol>
      </MRow>
    </FormRulesProvider>
    <NoteRules ref="noteDrawer" :create-fn="handleNoteAdded" />
    <DialogRulesConfirmModal
      ref="dialogRulesConfirmModalRef"
      :module-name="$constants.SERVICE_CATALOG"
      @confirm="handleConfirmDialogRule"
    />
  </FlotoContentLoader>
</template>

<script>
import Bus from '@utils/emitter'
import Intersection from 'lodash/intersection'
import isEqual from 'lodash/isEqual'
import CurrencyPicker from '@components/data-picker/currency-picker'
import Pick from 'lodash/pick'
import { authComputed } from '@state/modules/auth'
import { OrganizationComputed } from '@state/modules/organization'
import { getServiceCatalogApi } from '@modules/service-catalog/service-catalog-api'
import { getFormApi } from '@modules/form/form-api'
import { CategoryComputed } from '@state/modules/category'
import FormConsumer from '@components/form-consumer.vue'
import { flattenFields } from '@data/form'
import { getFieldByRequesterAccess } from '@modules/support-portal/helpers/field-access'
import FormRulesProvider from '@components/providers/form-rules-provider/form-rules-provider'
import NoteRules from '@components/note-rules/note-rules'
import DialogRulesConfirmModal from '@components/dialog-rules/dialog-rules'
import { getServiceCatalogCustomRulesInfoApi } from '@modules/custom-rules/custom-rules-api'
import FormHelperProvider from '@components/form-helper/form-helper-provider.vue'
import { updateApi } from '../ticket-api'

export default {
  name: 'RequestInformationTab',
  components: {
    FormConsumer,
    FormRulesProvider,
    CurrencyPicker,
    NoteRules,
    DialogRulesConfirmModal,
    FormHelperProvider,
  },
  props: {
    resource: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    isApproval: { type: Boolean, default: false },
    isFormRulesAvailable: { type: Boolean, default: false },
    // eslint-disable-next-line
    useFormHelpterProvider: { type: Boolean, default: true },
    serviceCatalogForm: { type: Object, default: undefined },
  },
  data() {
    return {
      pendingChanges: null,
      loading: true,
      processing: false,
      service: {},
      form: {},
      serviceCatalogNoteRequiredFields: [],
      serviceCatalogDialogFields: [],
    }
  },
  computed: {
    ...authComputed,
    ...OrganizationComputed,
    ...CategoryComputed,
    serviceCatagoryName() {
      const item = this.serviceCatalogCategories.find(
        (c) => this.service.categoryId === c.id
      )
      if (item && item.name) {
        return item.name
      }
      return null
    },
    formFields() {
      const fields = (this.form.fields || []).filter(
        (f) => !f.isSystemField && f.inputType !== 'api'
      )
      if (this.isPortalLogin) {
        if (this.isApproval) {
          return fields.filter((f) => f.useOnPortal)
        }
        return getFieldByRequesterAccess(
          fields.filter((f) => f.useOnPortal),
          this.user
        )
      }
      return fields
    },
    additionalInfoDisabled() {
      if (this.disabled || this.service.archived) {
        return true
      }
      return this.isFormRulesAvailable
    },
    giveExternalUpdateOptoin() {
      return (
        !this.disabled && !this.service.archived && this.isFormRulesAvailable
      )
    },
  },
  created() {
    this.getService()
    const collapsehandler = () => {
      this.loading = true
    }
    Bus.$on('app:collapse:additional:info', collapsehandler)
    const expandhandler = () => {
      this.loading = false
    }
    Bus.$on('app:expand:additional:info', expandhandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('app:collapse:additional:info', collapsehandler)
      Bus.$on('app:expand:additional:info', expandhandler)
    })
  },
  methods: {
    getCustomRules() {
      const serviceCatalogId = this.resource.serviceCatalogId
      if (serviceCatalogId) {
        getServiceCatalogCustomRulesInfoApi([serviceCatalogId]).then((data) => {
          if (data[serviceCatalogId]) {
            this.serviceCatalogNoteRequiredFields =
              data[serviceCatalogId].noteRules || []
            this.serviceCatalogDialogFields =
              data[serviceCatalogId].dialogRules || []
          } else {
            this.serviceCatalogNoteRequiredFields =
              this[`${this.$constants.SERVICE_CATALOG}NoteRequiredFields`] || []
          }
        })
      }
    },
    handleNoteAdded(data) {
      return this.handleFormSubmitted(
        {
          noteData: data,
          ...this.pendingChanges,
        },
        true
      ).then(() => {
        this.$refs.noteDrawer.closeForm()
        this.refreshTicket(['work'])
      })
    },
    handleConfirmDialogRule() {
      return this.handleFormSubmitted(
        {
          ...this.pendingChanges,
        },
        true,
        true
      ).then(() => {
        this.$refs.dialogRulesConfirmModalRef.handleHide()
      })
    },
    handleCheckUpdatedFieldAvailableInNoteRequiredField(
      noteRequiredFields,
      change
    ) {
      let isUpdated = false
      noteRequiredFields.forEach((field) => {
        if (field in change) {
          const isSame = Array.isArray(change[field])
            ? !isEqual(change[field], this.resource[field])
            : change[field] !== this.resource[field]

          if (isSame) {
            isUpdated = true
            return isUpdated
          }
        }
      })
      return isUpdated
    },
    getService() {
      getServiceCatalogApi(
        this.resource.serviceCatalogId,
        {
          archived: true,
          skipValidate: true,
        },
        this.isPortalLogin
      ).then((data) => {
        this.service = data
        this.getServiceForm(data.archived)
        this.getCustomRules()
        this.loading = false
      })
    },
    getServiceForm(isArchived = false) {
      if (this.serviceCatalogForm) {
        this.form = this.serviceCatalogForm
        return Promise.resolve(this.form)
      }
      getFormApi(
        this.$constants.SERVICE_CATALOG,
        this.service.id,
        {
          archived: isArchived,
        },
        this.isPortalLogin
      ).then((data) => {
        this.form = data
      })
    },
    handleFormSubmitted(update, forceUpdate = false, ignoreDialog = false) {
      const fieldIds = flattenFields(this.form.fields).map((f) => f.id)
      const noteRequiredFields = this.serviceCatalogNoteRequiredFields.map(
        (i) => `${i}`
      )
      const dialogFields = this.serviceCatalogDialogFields.map((i) => `${i}`)
      if (
        Intersection(noteRequiredFields, Object.keys(update)).length &&
        forceUpdate === false &&
        this.handleCheckUpdatedFieldAvailableInNoteRequiredField(
          noteRequiredFields,
          update
        )
      ) {
        this.pendingChanges = update
        this.$refs.noteDrawer.openForm()
        return Promise.resolve()
      } else {
        const checkIsDialogRulesQualifide =
          this.$refs.dialogRulesConfirmModalRef &&
          this.$refs.dialogRulesConfirmModalRef.checkIsDialogRulesQualifide(
            update,
            dialogFields
          ) &&
          this.handleCheckUpdatedFieldAvailableInNoteRequiredField(
            dialogFields,
            update
          )
        if (!forceUpdate && checkIsDialogRulesQualifide) {
          if (ignoreDialog) {
            this.$refs.dialogRulesConfirmModalRef.handleHide()
          } else {
            this.$refs.dialogRulesConfirmModalRef.handleShow()
            this.pendingChanges = update
            return Promise.resolve()
          }
        }
      }
      this.processing = true
      return updateApi(
        this.$constants.REQUEST,
        {
          id: this.resource.id,
          ...('noteData' in update ? { ...update.noteData } : {}),
          ...Pick(update, fieldIds),
        },
        this.isPortalLogin
      )
        .then(() => {
          this.$emit('refresh')
        })
        .finally(() => (this.processing = false))
    },
    handleEditCustomField() {
      if (!this.disabled && !this.service.archived) {
        this.$emit('edit-request-info-tab')
      }
    },
  },
}
</script>
