<template>
  <component
    :is="inputComponent"
    :value="value"
    :as-input="asInput"
    :allow-clear="allowClear"
    :mandatory="!allowClear"
    v-bind="attrs"
    :disabled="disabled"
    :module-name="parentModuleName"
    :only-leaf-node-selectable="onlyLeafNodeSelectable"
    :show-load-indicator="false"
    ignore-heirarchy-config
    v-on="listeners"
    @requesterIdSelected="$emit('customAsSystemRequesterIdSelected', $event)"
    @change="$emit('change', $event)"
  />
</template>

<script>
import Omit from 'lodash/omit'
export default {
  name: 'CustomAsSystemPicker',
  model: {
    event: 'change',
  },
  props: {
    moduleName: { type: String, default: undefined },
    fieldReferenceType: { type: String, required: true },
    value: { type: [Number, String, Array], default: undefined },
    asInput: {
      type: Boolean, // eslint-disable-next-line
      default: true,
    },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    allowClear: {
      type: Boolean,
      // eslint-disable-next-line
      default: true,
    },
    onlyLeafNodeSelectable: { type: Boolean, default: false },
    // hiddenOptionsKeys: {
    //   type: Array,
    //   default() {
    //     return []
    //   },
    // },
    // visibleOptionsKeys: {
    //   type: Array,
    //   default() {
    //     return []
    //   },
    // },
  },
  computed: {
    componentMap() {
      return {
        technician_group: 'FlotoTechnicianGroupPicker',
        requester_group: 'FlotoRequesterGroupPicker',
        department: 'FlotoDepartmentPicker',
        location: 'FlotoLocationPicker',
        category: 'FlotoCategoryPicker',
        requester: 'FlotoRequesterPicker',
        technician: 'FlotoTechnicianPicker',
      }
    },
    parentModuleName() {
      if (
        ['category'].indexOf(this.fieldReferenceType) >= 0 &&
        [
          this.$constants.ASSET_HARDWARE,
          this.$constants.ASSET_SOFTWARE,
          this.$constants.ASSET_NON_IT,
          this.$constants.ASSET_CONSUMABLE,
        ].indexOf(this.moduleName) >= 0
      ) {
        return this.$constants.ASSET
      }
      return this.moduleName
    },
    isDropdownField() {
      return this.inputComponent.indexOf('Picker') >= 0
    },
    inputComponent() {
      return this.componentMap[this.fieldReferenceType]
    },
    attrs() {
      const {
        type,
        additionalOptions,
        changeTechnicianOnGroupChange,
        ...attrs
      } = this.$attrs
      if (
        ['category', 'department', 'location'].indexOf(
          this.fieldReferenceType
        ) >= 0
      ) {
        attrs.fullPath = true
      }
      if (this.applyFormRules && this.fieldReferenceType === 'requester') {
        attrs.fullObject = true
      }
      attrs['group-id'] = 0
      attrs['validate-archived-value'] = false
      return attrs
    },
    listeners() {
      return Omit(this.$listeners, ['requesterSelected', 'requesterIdSelected'])
    },
  },
}
</script>
