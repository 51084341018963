var render = function render(){var _vm=this,_c=_vm._self._c;return (/\d+$/.test(_vm.name))?_c('img',_vm._b({style:({ 'max-height': _vm.size === 'lg' ? '24px' : '20px' }),attrs:{"src":_vm.name}},'img',_vm.$attrs,false)):_c('div',_vm._b({staticClass:"custom-icon-container flex",class:{
    ['w-6']: _vm.size === 'lg',
    ['w-5']: _vm.size === 'md',
    ['w-4']: _vm.size === 'sm',
    ['w-3']: _vm.size === 'xs',
  },style:({
    minWidth: `${_vm.iconWidths[_vm.size]}`,
  })},'div',_vm.$attrs,false),[(_vm.Icons[_vm.name])?_c(_vm.Icons[_vm.name],{tag:"component",staticClass:"w-full"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }