var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.showAllCurrencyOptions || _vm.disabled || _vm.multiple || _vm.valueInput
      ? 'FlotoDropdownPicker'
      : 'MSelect',_vm._b({tag:"component",style:(_vm.disabled ? { whiteSpace: 'pre-wrap' } : {}),attrs:{"value":_vm.value,"options":_vm.options,"disabled":_vm.disabled ||
    (!_vm.showAllCurrencyOptions &&
      _vm.options.length <= 1 &&
      !_vm.multiple &&
      !_vm.valueInput),"multiple":_vm.multiple,"mandatory":_vm.mandatory,"not-found-content":null,"searchable":_vm.searchable || _vm.multiple},on:{"change":_vm.handleChange}},'component',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }