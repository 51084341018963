import { getRootPluaralTranslator } from '@/src/utils/get-module-translator'
import { transformRecursive } from './recursive'
const __rootTc = getRootPluaralTranslator()

export function transformLocationRecursive(locations) {
  return transformRecursive(locations, transformLocation)
}

export function transformNameWithArchive(item) {
  const postFix = () => {
    if (item.removed) {
      return `(${__rootTc('archived')})`
    }
    return ''
  }
  return {
    nameWithArchive: `${item.name} ${postFix()}`.trim(),
  }
}

export function transformLocation(location) {
  const textArchiveNameProps = transformNameWithArchive(location)
  return {
    id: location.id,
    parentId: location.parentId,
    value: location.id,
    label: textArchiveNameProps.nameWithArchive,
    order: location.objOrder,
    name: textArchiveNameProps.nameWithArchive,
    description: location.description,
    barCodePrefix: location.barCodePrefix,
    disabled: location.disabled,
    archived: location.removed,
    children: [],
    canDelete: location.deleteableOob,
    canEdit: location.updatebleOob,
    latitude: location.latitude,
    longitude: location.longitude,
  }
}
export function transformLocationForServer(location) {
  return {
    // objOrder: location.order,
    parentId: location.parentId,
    name: location.name,
    description: location.description,
    barCodePrefix: location.barCodePrefix,
    latitude: location.latitude,
    longitude: location.longitude,
  }
}
