var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FieldContainer',_vm._g(_vm._b({attrs:{"field":_vm.field,"section-id":_vm.sectionId}},'FieldContainer',_vm.containerAttrs,false),_vm.containerListeners),[_c('FlotoFormItem',{attrs:{"label":_vm.field.title,"rules":_vm.validationsRules}},[(_vm.disabled)?_c('FlotoReadMore',{attrs:{"height":20 * 3}},[_c('FlotoRichEditor',_vm._g(_vm._b({attrs:{"rows":8,"disabled":_vm.disabled,"affix-toolbar":false},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'FlotoRichEditor',{
          ..._vm.attrs,
          ...(_vm.controlPreview ? { key: _vm.guid } : { key: _vm.renderCount }),
        },false),_vm.listeners))],1):_c('FlotoRichEditor',_vm._g(_vm._b({attrs:{"rows":8,"read-only":_vm.controlPreview,"disabled":_vm.controlPreview,"affix-toolbar":false},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'FlotoRichEditor',{
        ..._vm.attrs,
        ...(_vm.controlPreview ? { key: _vm.guid } : { key: _vm.renderCount }),
      },false),_vm.listeners))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }