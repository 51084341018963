var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FieldContainer',_vm._g(_vm._b({attrs:{"field":_vm.field,"section-id":_vm.sectionId}},'FieldContainer',_vm.containerAttrs,false),_vm.containerListeners),[(_vm.disabled)?_c('FlotoFormItem',{attrs:{"label":_vm.field.title,"rules":_vm.validationsRules}},[_vm._v(" "+_vm._s(_vm.currentValue || '---')+" ")]):_c('FlotoFormItem',_vm._g(_vm._b({class:{
      'w-full': _vm.field.attributes.widthClass !== 'w-full',
      'w-1/4': _vm.field.attributes.widthClass === 'w-full',
    },attrs:{"type":"number","read-only":true,"step":_vm.field.attributes.allowDecimal ? 0.1 : 1,"rules":_vm.validationsRules,"precision":_vm.field.attributes.allowDecimal ? 2 : 0,"label":_vm.field.title,"placeholder":_vm.field.attributes.placeholder,"disabled":_vm.disabled ||
      _vm.mode === 'builder' ||
      _vm.field.attributes.systemManagedField ||
      _vm.isDisable},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'FlotoFormItem',_vm.attrs,false),_vm.listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }