import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const clusterTabPropertyFieldMap = () => ({
  clusters_tab: {
    title: __mtc('clusters_tab'),
    fields: [
      {
        key: generateId(),
        paramName: 'name',
        name: __mtc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'clusterName',
        name: __mtc('cluster_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'UUID',
        name: __mtc('uuid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'version',
        name: __mtc('version'),
        columnKey: 'nutanixClusterVersion',
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'internalSubnet',
        name: __mtc('internal_subnet'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'externalSubnet',
        name: __mtc('external_subnet'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'architecture',
        name: __mtc('architecture'),
        inputType: 'text',
      },
    ],
  },
})
