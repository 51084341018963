var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hierarchy-item flex items-center min-w-0",on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"title-container flex flex-1 items-center min-w-0",class:{
      'mb-2': _vm.useMargin,
      'cursor-move': !_vm.disabled,
      'with-bg': _vm.useBg,
      seperator: _vm.useSeparator,
      'active-item text-primary': _vm.active,
      'text-neutral': !_vm.active,
    },staticStyle:{"margin-right":"0"}},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }