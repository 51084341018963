var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ResizableTable',{class:{ 'flex-1': !_vm.items.length && _vm.showNoData },attrs:{"disabled":!_vm.shouldShowHeader,"sticky-headers":"","use-scroll":false,"min-height":0},on:{"resize-done":function($event){_vm.columnWidths = $event}}},[_c('table',{staticClass:"w-full item-list-table"},[(_vm.shouldShowHeader)?_c('thead',[_c('tr',[(_vm.selectable)?_c('td',{staticClass:"checkbox sticky"},[(_vm.items.length > 0)?_c('MCheckbox',{attrs:{"checked":_vm.selectedItemIds.length === _vm.items.length},on:{"change":_vm.toggleSelectAll}}):_vm._e()],1):_vm._e(),_vm._l((_vm.columns),function(column,index){return _c('td',{key:column.key,staticClass:"sticky",class:column.class,style:(_vm.columnStyle(column, index)),attrs:{"data-initial-width":column.width,"align":column.align}},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"min-w-0 flex-1 text-ellipsis",attrs:{"id":`list-table-${column.name}`}},[_vm._v(" "+_vm._s(column.name)+" ")]),(column.sortable)?_c('MIcon',{staticClass:"cursor-pointer",attrs:{"name":_vm.sortedColumn
                  ? _vm.sortedColumn.replace('-', '') === column.key
                    ? /^-/.test(_vm.sortedColumn)
                      ? 'sort-up'
                      : 'sort-down'
                    : 'sort'
                  : 'sort'},on:{"click":function($event){return _vm.applySort(column.key)}}}):_vm._e()],1)])})],2)]):_vm._e(),_c('tbody',[_vm._l((_vm.items),function(item,index){return _vm._t("row",null,{"item":item,"columnWidths":_vm.columnWidths,"onRowClick":_vm.onRowClick,"index":index,"update":($events) => _vm.update({ ...$events, type: item.type }, item)},_vm.rowSlotData)})],2)]),(!_vm.items.length && _vm.showNoData)?_c('FlotoNoData',{attrs:{"size":_vm.messageSize}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }