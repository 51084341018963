<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="['description', 'signature'].indexOf(field.inputType) >= 0"
      :label="field.title"
      :rules="validationsRules"
    >
      <FlotoRichEditor
        v-model="currentValue"
        v-bind="attrs"
        :rows="8"
        :affix-toolbar="false"
        :control-preview="disabled || mode === 'builder' || isDisable"
        :read-only="isDisable"
        :disabled="isDisable"
        v-on="listeners"
      />
    </FlotoFormItem>
    <FlotoFormItem v-else-if="field.inputType === 'access_level'" id="scope">
      <template v-slot:label>
        <div class="mb-1 flex">
          <label>
            {{ ` ${$tc('scope')}` }}
          </label>
          <MTooltip>
            <template v-slot:trigger>
              <MIcon class="text-primary mx-2" name="info-circle" size="lg" />
            </template>
            {{ $tc('scope_info') }}
          </MTooltip>
        </div>
      </template>
      <ScopeRadioGroup
        v-model="currentValue"
        :disabled="attrs['access_level_disabled']"
      />
    </FlotoFormItem>
    <PasswordInput
      v-else-if="field.inputType === 'password'"
      id="password"
      v-model="currentValue"
      :label="$t('password')"
      vid="password"
      :rules="{ required: true }"
      :wrapper-col="{ xs: 5 }"
      :placeholder="$t('password')"
    />
    <PasswordInput
      v-else-if="field.inputType === 'confirmPassword'"
      id="confirmPassword"
      v-model="currentValue"
      :label="$t('confirm_password')"
      :rules="{
        confirmed: 'password',
        required: true,
      }"
      :wrapper-col="{ xs: 5 }"
      :placeholder="$t('confirm_password')"
    />
    <FlotoFormItem
      v-else-if="isSwitchField"
      :label="field.title"
      :rules="validationsRules"
    >
      <MSwitch
        v-model="currentValue"
        v-bind="attrs"
        :disabled="
          disabled ||
          mode === 'builder' ||
          isDisable ||
          attrs[`${field.inputType}_disabled`]
        "
        size="small"
        v-on="listeners"
      />
      <div
        v-if="field.inputType === 'mfa_enrolled' && formValue.mfaEnrolled"
        class="ml-2"
      >
        <a @click="$emit('mfa_enrolled_reset')">
          {{ $tc('reset') }}
          {{ $tc('two_factor_authentication') }}
        </a>
      </div>
    </FlotoFormItem>
    <FlotoFormItem
      v-else-if="inputComponent !== 'MInput'"
      :label="field.title"
      :rules="validationsRules"
    >
      <component
        :is="inputComponent"
        v-model="currentValue"
        :class="{
          block: isDropdownField,
          'w-full': !isDropdownField || field.attributes.widthClass === 'w-1/2',
          'w-1/2': isDropdownField && field.attributes.widthClass === 'w-full',
          'pr-2': isDropdownField && field.attributes.widthClass === 'w-full',
        }"
        as-input
        :allow-clear="allowClear"
        :mandatory="!allowClear && mode !== 'builder'"
        :preview="disabled || isDisable || mode === 'builder'"
        v-bind="attrs"
        :disabled="
          disabled ||
          mode === 'builder' ||
          isDisable ||
          attrs[`${field.inputType}_disabled`]
        "
        mandatory-selection
        :hidden-options-keys="hiddenOptionsKeys"
        :visible-options-keys="visibleOptionsKeys"
        :filter-data="filterData"
        :time-unit="
          field.inputType === 'notify_before'
            ? formValue.notifyBeforeHoursTimeUnit || 'hours'
            : field.inputType === 'estimated_time'
            ? formValue.estimatedTimeUnit || 'hours'
            : undefined
        "
        @update:timeUnit="handleTimeUnitSync"
        v-on="listeners"
      />
      <a
        v-if="field.inputType === 'template' && currentValue"
        @click="handleResetTemplate"
      >
        <MIcon name="undo" /> {{ $t('reset_to_default') }}
      </a>
    </FlotoFormItem>
    <FlotoFormItem
      v-else
      v-model="currentValue"
      :type="field.inputType === 'description' ? 'textarea' : undefined"
      :label="field.title"
      :rules="validationsRules"
      v-bind="attrs"
      :disabled="disabled || mode === 'builder' || isDisable"
      v-on="listeners"
    />
  </FieldContainer>
</template>

<script>
import Moment from 'moment'
import { authComputed } from '@state/modules/auth'
import ReleaseRiskTypePicker from '@components/data-picker/release-risk-type-picker'
import ReleaseTypePicker from '@components/data-picker/release-type-picker'
import ReleaseReasonTypePicker from '@components/data-picker/release-reason-type-picker'
import ScopeRadioGroup from '@components/data-picker/scope-radio-group'
import FormField from '../../mixins/form-field'
import ProjectRiskTypePicker from '@components/data-picker/project-risk-type-picker'
import CompanyPicker from '@components/data-picker/company-picker'
import VendorPicker from '@components/data-picker/vendor-picker'
import SliderPicker from '@components/numeric-slider/slider-picker'
import FormatSelector from '@components/date-format/format-selector'
import { getValidationRulesForEstimatedTime } from '@components/task/utils'
import ManagerPicker from '@components/data-picker/manager-picker'
import PasswordInput from '@components/password-input'

export default {
  name: 'System',
  components: {
    ReleaseRiskTypePicker,
    ReleaseTypePicker,
    ReleaseReasonTypePicker,
    ProjectRiskTypePicker,
    CompanyPicker,
    VendorPicker,
    SliderPicker,
    FormatSelector,
    ScopeRadioGroup,
    ManagerPicker,
    PasswordInput,
  },
  mixins: [FormField],
  props: {
    formValue: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...authComputed,
    componentMap() {
      return {
        technician_group: 'FlotoTechnicianGroupPicker',
        department: 'FlotoDepartmentPicker',
        template: 'FlotoTemplatePicker',
        priority: 'FlotoPriorityPicker',
        impact: 'FlotoImpactPicker',
        urgency: 'FlotoUrgencyPicker',
        location: 'FlotoLocationPicker',
        tags: 'FlotoTagsPicker',
        ccemail: 'FlotoTagsPicker',
        category: 'FlotoCategoryPicker',
        description: 'FlotoRichEditor',
        display_name: 'MInput',
        subject: 'MInput',
        // refactor requester_email to requester
        requester: 'FlotoRequesterEmail',
        requesterid: 'FlotoRequesterPicker',
        name: 'MInput',
        status: 'FlotoStatusPicker',
        attachment: 'FlotoAttachment',
        technician: 'FlotoTechnicianPicker',
        technicianid: 'FlotoTechnicianPicker',
        due_by: 'FlotoDatePicker',
        rating: 'MRate',
        risk_type:
          this.attrs['module-name'] === this.$constants.RELEASE
            ? 'ReleaseRiskTypePicker'
            : this.attrs['module-name'] === this.$constants.PROJECT
            ? 'ProjectRiskTypePicker'
            : 'FlotoRiskTypePicker',
        change_type: 'FlotoChangeTypePicker',
        project_type: 'FlotoProjectTypePicker',
        release_type: 'ReleaseTypePicker',
        reason_type:
          this.attrs['module-name'] === this.$constants.RELEASE
            ? 'ReleaseReasonTypePicker'
            : 'FlotoReasonTypePicker',
        target_environment: 'FlotoTargetEnvironmentPicker',
        start_date: 'FlotoDatePicker',
        start_time: 'FlotoDatePicker',
        end_date: 'FlotoDatePicker',
        end_time: 'FlotoDatePicker',
        known_error: 'FlotoDropdownPicker',
        nature_of_problem: 'FlotoDropdownPicker',
        affected_services: 'FlotoBusinessServicePicker',
        company: 'CompanyPicker',
        transition_model: 'FlotoTransitionModelPicker',
        vendor: 'VendorPicker',
        task_type: 'FlotoTaskTypePicker',
        notify_before: 'FlotoEstimatedTimePicker',
        estimated_time: 'FlotoEstimatedTimePicker',
        task_completion: 'SliderPicker',
        task_duration: 'MInput',
        // user form fields
        user_email: 'MInput',
        log_on_name: 'MInput',
        contact_no: 'MInput',
        alt_contact_no_1: 'MInput',
        alt_contact_no_2: 'MInput',
        timezone: 'FlotoTimeZonePicker',
        vip: 'MRadioGroup',
        restrict_data_access_to_own_company: 'MSwitch',
        manager: 'ManagerPicker',
        user_group:
          this.attrs['user-type'] === 'requester'
            ? 'FlotoRequesterGroupPicker'
            : 'FlotoTechnicianGroupPicker', // @TODO handle requester group
        support_level: 'FlotoSupportLevelPicker',
        allow_to_login: 'MSwitch',
        verified: 'MSwitch',
        do_not_disturb: 'MSwitch',
        roles: 'FlotoRolePicker',
        allow_delegate_approval: 'MSwitch',
        allow_delegate_work: 'MSwitch',
        allow_delegate_team_approval: 'MSwitch',
        allow_delegate_team_work: 'MSwitch',
        mfa_enrolled: 'MSwitch',
        asset_access_level: 'ScopeRadioGroup', // @TODO asset access level radio
        date_time_format: 'FormatSelector',
        date_format: 'FormatSelector',
        time_format: 'FormatSelector',
      }
    },
    allowClear() {
      return (
        [
          'template',
          'priority',
          'impact',
          'urgency',
          'status',
          'known_error',
          'risk_type',
          'task_type',
          'roles',
        ].indexOf(this.field.inputType) === -1
      )
    },
    isDropdownField() {
      return (this.inputComponent || '').indexOf('Picker') >= 0
    },
    isSwitchField() {
      return (this.inputComponent || '').indexOf('MSwitch') >= 0
    },
    inputComponent() {
      return this.componentMap[this.field.inputType] || ''
    },
    inputType() {
      if (this.field.inputType === 'description') {
        return 'textarea'
      }
      if (this.field.inputType === 'subject') {
        return 'text'
      }
      if (this.field.inputType === 'email') {
        return 'text'
      }
      if (this.field.inputType === 'display_name') {
        return 'text'
      }
      if (this.field.inputType === 'task_duration') {
        return 'text'
      }
      return undefined
    },
    attrs() {
      const { type, ...attrs } = this.$attrs
      if (this.field.inputType === 'attachment') {
        attrs.buttonText = this.field.attributes.buttonText
      }
      if (this.field.inputType === 'technician') {
        attrs.changeTechnicianOnGroupChange = true
      }
      if (this.field.inputType === 'technicianid') {
        attrs['group-id'] = 0
      }
      if (
        ['template', 'vendor', 'transition_model'].indexOf(
          this.field.inputType
        ) >= 0
      ) {
        attrs['validate-archived-value'] = false
      }
      // if (this.field.inputType === 'subject' && this.isPortalLogin) {
      //   attrs.autoFocus = true
      // }
      if (this.field.inputType === 'rating') {
        attrs.allowHalf = !!this.field.attributes.allowHalf
      }
      if (this.field.inputType === 'due_by') {
        attrs.minDate = Moment().valueOf()
      }
      if (
        [this.$constants.CHANGE, this.$constants.RELEASE].indexOf(
          attrs['module-name']
        ) >= 0 &&
        this.field.inputType === 'start_date'
      ) {
        attrs.maxDate = this.formValue.endDate
      }
      if (
        [this.$constants.CHANGE, this.$constants.RELEASE].indexOf(
          attrs['module-name']
        ) >= 0 &&
        this.field.inputType === 'end_date'
      ) {
        attrs.minDate = this.formValue.startDate
      }
      if (this.field.inputType === 'ccemail') {
        attrs.type = 'email'
      }
      // refactor requester_email to requester
      if (this.field.inputType === 'requester') {
        attrs.showHint =
          this.field.attributes.showHint === undefined
            ? true
            : this.field.attributes.showHint
        // @TODO remove autofocus for value mapping rendering in create request
        // attrs.autoFocus = !this.isPortalLogin
      }
      if (this.field.inputType === 'nature_of_problem') {
        attrs.options = [
          { text: this.$t('proactive'), key: 'proactive' },
          { text: this.$t('reactive'), key: 'reactive' },
        ]
        attrs.asInput = true
      }
      if (this.field.inputType === 'vip') {
        attrs.options = [
          { text: this.$t('yes'), value: true },
          { text: this.$t('no'), value: false },
        ]
        attrs.asButton = true
      }
      if (this.field.inputType === 'known_error') {
        attrs.options = [
          { text: this.$t('yes'), key: 'true' },
          { text: this.$t('no'), key: 'false' },
        ]
        attrs.asInput = true
      }
      if (
        ['roles', 'affected_services', 'user_group', 'support_level'].indexOf(
          this.field.inputType
        ) >= 0
      ) {
        attrs.multiple = true
      }
      // When the field is for the user module and input type is company
      // allow multiple selection if the user type is technician
      if (
        this.field.moduleName === this.$constants.USER &&
        this.field.inputType === 'company'
      ) {
        attrs.multiple = this.formValue.userType === 'technician'
      }
      if (
        ['category', 'department', 'location'].indexOf(this.field.inputType) >=
        0
      ) {
        attrs.fullPath = true
      }
      if (this.applyFormRules && this.field.inputType === 'requesterid') {
        attrs.fullObject = true
        attrs['validate-archived-value'] = false
      }
      if (this.field.inputType === 'task_completion') {
        attrs.min = 0
        attrs.max = 100
      }
      if (
        ['notify_before', 'estimated_time'].indexOf(this.field.inputType) >= 0
      ) {
        attrs['allowed-units'] = ['hours', 'minutes', 'days']
      }
      if (['date_time_format'].indexOf(this.field.inputType) >= 0) {
        attrs['format'] = 'dateTime'
      }
      if (['date_format'].indexOf(this.field.inputType) >= 0) {
        attrs['format'] = 'date'
      }
      if (['time_format'].indexOf(this.field.inputType) >= 0) {
        attrs['format'] = 'time'
      }
      if (['manager'].indexOf(this.field.inputType) >= 0) {
        attrs['user-id'] = attrs['manager-user-id'] || 0
      }
      if (
        ['timezone'].indexOf(this.field.inputType) >= 0 &&
        this.mode === 'builder'
      ) {
        attrs['usePicker'] = true
      }
      return attrs
    },
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      let rules = {
        required: this.isRequired || false,
      }
      if (this.field.inputType === 'requester') {
        rules.short_text = true
      }
      if (this.field.inputType === 'description') {
        rules.description = true
      }
      if (this.field.inputType === 'subject') {
        rules.subject = true
      }
      if (this.field.inputType === 'task_duration') {
        rules.duration_days = true
        rules.min_value = 0
        rules.max_value = true
      }
      if (this.field.inputType === 'task_completion') {
        rules.min_value = 0
        rules.max_value = 100
      }
      if (this.field.inputType === 'notify_before') {
        rules = {
          ...rules,
          ...getValidationRulesForEstimatedTime(
            this.formValue.notifyBeforeHoursTimeUnit
          ),
        }
      }
      if (this.field.inputType === 'estimated_time') {
        rules = {
          ...rules,
          ...getValidationRulesForEstimatedTime(
            this.formValue.estimatedTimeUnit
          ),
        }
      }
      if (
        this.field.inputType === 'rating' ||
        (this.field.inputType === 'risk_type' && this.isRequired) ||
        (this.field.inputType === 'change_type' && this.isRequired) ||
        (this.field.inputType === 'project_type' && this.isRequired) ||
        (this.field.inputType === 'reason_type' && this.isRequired) ||
        (this.field.inputType === 'release_type' && this.isRequired) ||
        (this.field.inputType === 'category' && this.isRequired) ||
        (this.field.inputType === 'department' && this.isRequired) ||
        (this.field.inputType === 'location' && this.isRequired) ||
        (this.field.inputType === 'technician' && this.isRequired) ||
        (this.field.inputType === 'technician_group' && this.isRequired) ||
        (this.field.inputType === 'target_environment' && this.isRequired) ||
        (this.field.inputType === 'company' && this.isRequired) ||
        (this.field.inputType === 'requesterid' && this.isRequired) ||
        (this.field.inputType === 'vendor' && this.isRequired) ||
        (this.field.inputType === 'notify_before' && this.isRequired) ||
        (this.field.inputType === 'task_completion' && this.isRequired) ||
        (this.field.inputType === 'start_time' && this.isRequired) ||
        (this.field.inputType === 'end_time' && this.isRequired)
      ) {
        rules.nonzero = true
      }
      if (this.field.inputType === 'task_duration' && this.isRequired) {
        rules.nonzero_string = true
      }
      if (
        this.field.inputType === 'display_name' &&
        this.field.moduleName === this.$constants.PROJECT
      ) {
        rules.short_text = true
      }
      if (
        this.field.inputType === 'name' &&
        this.field.moduleName === this.$constants.USER
      ) {
        rules.short_text = true
      }
      if (this.field.inputType === 'user_email') {
        rules.email = true
      }
      if (
        ['contact_no', 'alt_contact_no_1', 'alt_contact_no_2'].indexOf(
          this.field.inputType
        ) >= 0
      ) {
        rules.new_contact_number = true
      }
      return rules
    },
  },
  watch: {
    // only for task module
    currentValue() {
      if (
        ['notify_before', 'estimated_time'].indexOf(this.field.inputType) >= 0
      ) {
        if (
          !this.formValue.notifyBeforeHoursTimeUnit &&
          this.field.inputType === 'notify_before'
        ) {
          this.handleTimeUnitSync('hours')
        }
        if (
          !this.formValue.estimatedTimeUnit &&
          this.field.inputType === 'estimated_time'
        ) {
          this.handleTimeUnitSync('hours')
        }
      }
    },
  },
  methods: {
    handleResetTemplate() {
      this.$emit('reset-form')
    },
    handleTimeUnitSync($event) {
      if (this.field.inputType === 'notify_before') {
        return (this.formValue.notifyBeforeHoursTimeUnit = $event)
      }
      if (this.field.inputType === 'estimated_time') {
        return (this.formValue.estimatedTimeUnit = $event)
      }
    },
  },
}
</script>
