var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FlotoContentLoader',{attrs:{"loading":false}},[_c('div',{staticClass:"flex flex-col flex-1 mt-4"},[_c('div',{staticClass:"basic-details"},[_c('MRow',{staticClass:"px-4"},[_c('MCol',{attrs:{"size":10}},[_c('h5',[_vm._v(_vm._s(`${_vm.$tc('contact')} ${_vm.$tc('details')}`))])]),_c('MCol',{staticClass:"text-right",attrs:{"size":2}},[(!_vm.mainUser.id)?_c('AddUpdateRequester',{attrs:{"default-channel":_vm.defaultChannel},on:{"done":_vm.handleDone}}):_c('AddRequesterDrawer',{attrs:{"user":_vm.mainUser},on:{"done":_vm.handleDone},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{attrs:{"name":"pencil","size":"lg","variant":"primary"}})]},proxy:true}])})],1)],1),_c('MRow',{staticClass:"px-4 mt-2"},[_c('MCol',{attrs:{"size":12}},[_c('div',{staticClass:"text-neutral mb-1"},[_vm._v(" "+_vm._s(_vm.$tc('phone_numbers'))+" ")]),(
              _vm.mainUser.contactNo ||
                _vm.mainUser.altContactNo1 ||
                _vm.mainUser.altContactNo2
            )?_c('div',{staticClass:"flex flex-wrap"},[_vm._v(" "+_vm._s([ _vm.mainUser.contactNo, _vm.mainUser.altContactNo1, _vm.mainUser.altContactNo2, ] .filter((item) => item && item) .join(', '))+" ")]):_c('div',[_vm._v(" "+_vm._s('---')+" ")])]),_c('MCol',{staticClass:"mt-4"},[_c('div',{staticClass:"text-neutral"},[_vm._v(" "+_vm._s(`${_vm.$tc('last')} ${_vm.$tc('called')}`)+" ")]),(_vm.defaultChannel.lastCallTime)?_c('div',[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.defaultChannel.lastCallTime))+" ("+_vm._s(_vm._f("timeago")(_vm.defaultChannel.lastCallTime))+") ")]):_c('div',[_vm._v(" "+_vm._s('---')+" ")])])],1),_c('MDivider')],1),_c('div',{staticClass:"recent-list flex flex-1 h-0"},[_c('div',{staticClass:"flex flex-col flex-1 border border-b-1 border-t-0 border-l-0 border-r-0 border-solid border-neutral-lighter overflow-x-hidden h-auto"},[_c('h5',{staticClass:"px-4"},[_vm._v(_vm._s(_vm.$tc('coops_recent_calls')))]),(_vm.recentCalls.length)?_c('div',{staticClass:"flex flex-col flex-1 overflow-y-scroll overflow-x-hidden h-0 px-4"},_vm._l((_vm.recentCalls),function(item,index){return _c('div',{key:index,staticClass:"recent-item flex border border-b-1 border-t-0 border-l-0 border-r-0 border-solid border-neutral-lightest py-2 w-full"},[_c('div',{staticClass:"left"},[_c('MTooltip',{staticClass:"mr-2",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticStyle:{"position":"relative"}},[_c('MIcon',{staticClass:"text-neutral",attrs:{"name":"phone-alt","size":"lg"}}),_c('MIcon',{staticClass:"text-neutral",staticStyle:{"position":"absolute","top":"1px","right":"-4px","font-size":"10px"},attrs:{"size":"lg","name":(item.callType || '').toLowerCase() === 'incoming'
                          ? 'long-arrow-left'
                          : 'long-arrow-right'}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$tc((item.callType || '').toLowerCase()))+" "+_vm._s(_vm.$tc('call'))+" ")])],1),_c('div',{staticClass:"right flex flex-col flex-1"},[_c('div',{staticClass:"flex justify-between"},[_c('span',[_vm._v(" "+_vm._s(item.requesterNumber)+" ")])]),_c('div',{staticClass:"text-neutral flex"},[_c('small',{staticClass:"mr-2"},[_c('MIcon',{attrs:{"name":"user"}}),_vm._v(" "+_vm._s(item.assignee)+" ")],1),_c('small',{staticClass:"mr-2"},[_c('MIcon',{attrs:{"name":"clock"}}),_vm._v(" "+_vm._s(_vm._f("datetime")(item.callTime))+" ")],1)])])])}),0):_c('div',[_c('FlotoNoData',{attrs:{"size":"small"}})],1)])]),_c('div',{staticClass:"count-box"},[_c('MRow',{staticClass:"dashboard-view-container mt-4",attrs:{"gutter":0}},[_c('MCol',{staticClass:"mb-6",attrs:{"size":12}},[_c('MRow',{attrs:{"gutter":0}},[_c('MCol',{staticClass:"mr-4 ml-4 border border-solid border-neutral-lighter rounded",attrs:{"size":5}},[_c('h1',{staticClass:"mb-0 ml-2"},[_vm._v(" "+_vm._s(_vm.waitingCallCount || 0)+" ")]),_c('h5',{staticClass:"mb-0 ml-2 text-ellipsis font-normal"},[_vm._v(_vm._s('Wait Call Queue'))])]),_c('MCol',{staticClass:"border border-solid border-neutral-lighter rounded",attrs:{"size":5}},[_c('h1',{staticClass:"mb-0 ml-2"},[_vm._v(" "+_vm._s(_vm.onHoldCount || 0)+" ")]),_c('h5',{staticClass:"mb-0 ml-2 text-ellipsis font-normal"},[_vm._v(_vm._s('Hold Calls'))])])],1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }