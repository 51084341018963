import lazyLoadView from '@router/lazy-loader'
import Constant from '@constants'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      titleFn(route, translator, pluralTranslator) {
        const moduleText =
          ['product', 'vendor'].indexOf(route.params.moduleName) >= 0
            ? 'supplier'
            : route.params.moduleName === 'feedback'
            ? 'request'
            : route.params.moduleName === 'software_license'
            ? 'asset'
            : route.params.moduleName
        return route.params.moduleName === Constant.USER
          ? pluralTranslator('user', 2)
          : `${translator(moduleText)} ${translator('management')}`
      },
      nonLinked: true,
    },
    children: [
      {
        path: ':moduleName',
        name: `${routeNamePrefix}`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "form" */ './views/builder')
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${translator(route.params.moduleName)} ${
              [
                Constant.USER,
                Constant.ASSET,
                Constant.PRODUCT,
                Constant.VENDOR,
                Constant.CONTRACT,
                Constant.PURCHASE,
              ].indexOf(route.params.moduleName) >= 0
                ? route.query.fieldType === 'price'
                  ? pluralTranslator('price_fields')
                  : pluralTranslator('custom_fields')
                : translator('form')
            }`
          },
        },
      },
    ],
  },
]
