var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-1 flex-col task-container"},[_c('FlotoCrudContainer',{ref:"crudContainer",attrs:{"scrollable":false,"fetch-fn":_vm.getTasks,"update-fn":_vm.updateTask,"create-fn":_vm.addTask,"delete-fn":_vm.deleteTask,"default-item":_vm.defaultTask,"show-no-data":_vm.showNoData,"prepend-new-item":""},scopedSlots:_vm._u([(_vm.totalStages > 0)?{key:"add-controls",fn:function(){return [_c('MRow',{staticClass:"items-center justify-center"},[_c('MCol',{staticClass:"flex items-center justify-center"},[_c('b',[_vm._v(_vm._s(_vm.$tc('task'))+" "+_vm._s(_vm.$tc('summary')))])])],1),_c('MRow',{staticClass:"mb-4 items-center justify-center px-6"},[_c('MCol',{staticClass:"counter-steps",style:({ maxWidth: `${_vm.totalStages * 150}px` })},[_c('MSteps',{attrs:{"active":_vm.activeStage - 1,"status":_vm.activeStage === _vm.stages.length ? 'finish' : 'process'}},_vm._l((_vm.stages),function(stageInfo,index){return _c('MStep',{key:index,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('CounterStep',{attrs:{"status":index + 1 > _vm.activeStage
                      ? 'pending'
                      : index + 1 < _vm.activeStage
                      ? 'success'
                      : 'progress',"active":_vm.currentActiveStage === index + 1}},[_c('a',{staticClass:"block",on:{"click":function($event){_vm.currentActiveStage = index + 1}}},[_vm._v(" "+_vm._s(stageInfo.completed)+"/"+_vm._s(stageInfo.total)+" ")])])]},proxy:true}],null,true)})}),1)],1)],1),_c('MDivider')]},proxy:true}:null,(!_vm.disabled)?{key:"form-header",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t( item.id ? `${_vm.$tc('edit')}: ${item.name}` : `${_vm.$tc('add')} ${_vm.$tc('task')}` ))+" ")]}}:null,{key:"form-items",fn:function({ item: task }){return [_c('TaskForm',{attrs:{"task":task,"take-range":"","take-status":""}})]}},{key:"form-actions",fn:function({ submit, cancel, item, processing }){return [_c('MButton',{attrs:{"id":"add-update-btn","loading":processing},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.$t(item.id ? 'update' : 'add'))+" ")]),_c('MButton',{staticClass:"mx-1",attrs:{"id":"cancel-btn","variant":"default"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}},{key:"item",fn:function({
        item: task,
        edit,
        update,
        activateItem,
        remove,
        isLastItem,
      }){return [_c('Task',{key:task.id,attrs:{"task":task,"mark-bg-color":"","allow-edit":"","allow-delete":"","disabled":_vm.disabled || _vm.activeStage < _vm.currentActiveStage,"details-disabled":_vm.isFormRuleQualifiedForEditOnLoad,"is-in-active-task":_vm.activeStage < _vm.currentActiveStage},on:{"view":function($event){return activateItem(task)},"edit":function($event){return _vm.$emit('edit', task)},"remove":remove,"update":update}}),(!isLastItem)?_c('MDivider'):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }