var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col flex-1"},[_c('div',{staticClass:"font-semibold py-1"},[_vm._v(_vm._s(_vm.stage.text))]),_c('div',[(_vm.running && _vm.stage.key !== 'close' && _vm.stage.key !== 'approval')?[(!_vm.resource.prevStatusId)?_c('label',{staticClass:"mb-2 text-neutral"},[_vm._v(" "+_vm._s(_vm.$tc('select_status'))+" ")]):_vm._e(),(_vm.resource.prevStatusId)?_c('FlotoStatusPicker',{attrs:{"value":_vm.statusId,"stage":_vm.stage.key,"module-name":_vm.moduleName,"disabled":""}}):_c('FlotoStatusPicker',_vm._b({attrs:{"disabled":_vm.disabled || _vm.singleFieldRulesStateFn('statusId').disable,"value":_vm.statusId,"stage":_vm.stage.key,"module-name":_vm.moduleName,"as-input":"","allow-clear":false},on:{"change":_vm.handleChange}},'FlotoStatusPicker',{
          hiddenOptionsKeys:
            _vm.singleFieldRulesStateFn('statusId').hiddenOptionsKeys || [],

          visibleOptionsKeys:
            _vm.singleFieldRulesStateFn('statusId').visibleOptionsKeys || [],
        },false))]:_vm._e(),(_vm.running && _vm.stage.key === 'approval')?[_c('FlotoStatusPicker',{attrs:{"disabled":"","value":_vm.statusId,"stage":_vm.stage.key,"module-name":_vm.moduleName}})]:_vm._e(),(_vm.running && _vm.resource.backToPlanning && !_vm.disabled)?_c('div',[_c('a',{on:{"click":_vm.backToPlanning}},[_c('MIcon',{attrs:{"name":"long-arrow-left"}}),_vm._v(" "+_vm._s(_vm.$t('back'))+" "+_vm._s(_vm.$t('to_lower'))+" "+_vm._s(_vm.$t('planning'))+" ")],1)]):_vm._e(),(_vm.completed)?_c('FlotoStatusPicker',{attrs:{"disabled":"","value":_vm.prevStatusId,"allow-clear":false,"stage":_vm.stage.key,"module-name":_vm.moduleName}}):_vm._e()],2),(
      _vm.resource[`${_vm.stage.key}EndTime`] &&
      (_vm.completed || (_vm.running && _vm.stage.key === 'close'))
    )?_c('div',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.resource[`${_vm.stage.key}EndTime`]))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }