var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.asInput && !_vm.textOnly)?_c('MTag',{class:{ disabled: _vm.disabled, 'text-ellipsis': true },style:({ ..._vm.currentStyle, maxWidth: '150px', display: 'inline-flex' }),attrs:{"closable":false,"variant":_vm.selectedItem && _vm.selectedItem.color ? undefined : 'neutral-lighter',"title":_vm.selectedItem
      ? _vm.selectedItem.text || _vm.selectedItem.name || _vm.selectedItem.label
      : _vm.placeholder}},[_vm._t("prefix-text",null,{"item":_vm.selectedItem}),_c('span',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(_vm.selectedItem ? _vm.selectedItem.text || _vm.selectedItem.name || _vm.selectedItem.label : _vm.placeholder)+" ")]),_vm._t("suffix-text")],2):(_vm.textOnly)?_c('span',{staticClass:"truncate-text",attrs:{"title":_vm.selectedItem
      ? _vm.selectedItem.text || _vm.selectedItem.name || _vm.selectedItem.label
      : ''}},[_vm._v(" "+_vm._s(_vm.selectedItem ? _vm.selectedItem.text || _vm.selectedItem.name || _vm.selectedItem.label : _vm.placeholder)+" ")]):_c('div',{on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('click')}}},[_c('MInput',{staticClass:"dropdown-trigger-input",class:[{ 'dropdown-open': _vm.isOpen }, _vm.inputClasses],attrs:{"read-only":"","disabled":_vm.disabled,"size":_vm.size,"value":_vm.selectedItem
        ? _vm.selectedItem.text || _vm.selectedItem.name || _vm.selectedItem.label
        : undefined,"placeholder":_vm.placeholder},on:{"focus":_vm.handleFocus}},[(_vm.selectedItem && _vm.selectedItem.color)?_c('FlotoDot',{attrs:{"slot":"prefix","bg":_vm.selectedItem.color},slot:"prefix"}):_vm._e(),(_vm.isActiveHover && _vm.allowClear && !_vm.disabled)?_c('MIcon',{staticClass:"cursor-pointer",attrs:{"slot":"suffix","type":"fas","name":"times-circle","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('reset', undefined)}},slot:"suffix"}):_c('MIcon',{staticClass:"cursor-pointer text-neutral-light",attrs:{"slot":"suffix","type":"fal","name":"chevron-down","size":"sm"},slot:"suffix"})],1),_vm._t("bellow-input")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }