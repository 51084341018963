var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MTooltip',{attrs:{"disabled":!_vm.tooltipText},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"counter-step",class:{
        'bg-secondary-green': _vm.status === 'success',
        'bg-secondary-red': _vm.status === 'error',
        'bg-neutral-light': _vm.status === 'pending',
        'bg-primary': _vm.status === 'progress',
        active: _vm.active,
      },style:({
        textAlign: 'center',
      })},[_c('div',{class:{ active: _vm.active }},[_vm._t("default")],2)])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.tooltipText)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }