var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MRow',{attrs:{"gutter":0}},[_c('MCol',{attrs:{"lg":12,"sm":12,"auto-size":""}},[_c('MRow',[_c('MCol',{attrs:{"size":12}},[(_vm.disabled)?_c('FlotoFormItemReadOnly',{attrs:{"label":_vm.$t('street')}},[_vm._v(" "+_vm._s(_vm.formData.street || '---')+" ")]):_c('FlotoFormItem',{attrs:{"id":"street-input","vid":`${_vm.type}-street`,"disabled":_vm.disabled,"placeholder":_vm.$t('street'),"label":_vm.$t('street'),"rules":{
            required: _vm.isRequired('street'),
            // eslint-disable-next-line
            short_text: true,
          }},model:{value:(_vm.formData.street),callback:function ($$v) {_vm.$set(_vm.formData, "street", $$v)},expression:"formData.street"}})],1)],1),_c('MRow',[_c('MCol',{attrs:{"size":12}},[(_vm.disabled)?_c('FlotoFormItemReadOnly',{attrs:{"label":_vm.$t('landmark')}},[_vm._v(" "+_vm._s(_vm.formData.landmark || '---')+" ")]):_c('FlotoFormItem',{attrs:{"id":"landmark-input","vid":`${_vm.type}-landmark`,"disabled":_vm.disabled,"placeholder":_vm.$t('landmark'),"label":_vm.$t('landmark'),"rules":{
            required: _vm.isRequired('landmark'),
            // eslint-disable-next-line
            short_text: true,
          }},model:{value:(_vm.formData.landmark),callback:function ($$v) {_vm.$set(_vm.formData, "landmark", $$v)},expression:"formData.landmark"}})],1)],1),_c('MRow',[_c('MCol',{attrs:{"size":6}},[(_vm.disabled)?_c('FlotoFormItemReadOnly',{attrs:{"label":_vm.$t('city')}},[_vm._v(" "+_vm._s(_vm.formData.city || '---')+" ")]):_c('FlotoFormItem',{attrs:{"id":"city-input","vid":`${_vm.type}-city`,"disabled":_vm.disabled,"placeholder":_vm.$t('city'),"label":_vm.$t('city'),"rules":{
            required: _vm.isRequired('city'),
            // eslint-disable-next-line
            short_text: true,
          }},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}})],1),_c('MCol',{attrs:{"size":6}},[(_vm.disabled)?_c('FlotoFormItemReadOnly',{attrs:{"label":_vm.$t('pincode')}},[_vm._v(" "+_vm._s(_vm.formData.pinCode || '---')+" ")]):_c('FlotoFormItem',{attrs:{"id":"pin-code-input","vid":`${_vm.type}-pinCode`,"disabled":_vm.disabled,"placeholder":_vm.$t('pincode'),"label":_vm.$t('pincode'),"rules":{
            required: _vm.isRequired('pincode'),
            numeric: true,
            max: 6,
          }},model:{value:(_vm.formData.pinCode),callback:function ($$v) {_vm.$set(_vm.formData, "pinCode", $$v)},expression:"formData.pinCode"}})],1)],1),_c('MRow',[_c('MCol',{attrs:{"size":6}},[(_vm.disabled)?_c('FlotoFormItemReadOnly',{attrs:{"label":_vm.$t('state')}},[_vm._v(" "+_vm._s(_vm.formData.state || '---')+" ")]):_c('FlotoFormItem',{attrs:{"id":"state-input","vid":`${_vm.type}-state`,"disabled":_vm.disabled,"placeholder":_vm.$t('state'),"label":_vm.$t('state'),"rules":{
            required: _vm.isRequired('state'),
            // eslint-disable-next-line
            short_text: true,
          }},model:{value:(_vm.formData.state),callback:function ($$v) {_vm.$set(_vm.formData, "state", $$v)},expression:"formData.state"}})],1),_c('MCol',{attrs:{"size":6}},[(_vm.disabled)?_c('FlotoFormItemReadOnly',{attrs:{"label":_vm.$t('country')}},[_vm._v(" "+_vm._s(_vm.formData.country || '---')+" ")]):_c('FlotoFormItem',{attrs:{"id":"country-input","vid":`${_vm.type}-country`,"disabled":_vm.disabled,"placeholder":_vm.$t('country'),"label":_vm.$t('country'),"rules":{
            required: _vm.isRequired('country'),
            // eslint-disable-next-line
            short_text: true,
          }},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }