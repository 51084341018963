<template>
  <MRow :gutter="28">
    <MCol>
      <MRow :gutter="0">
        <MCol
          class="border-neutral-lighter border-b border-solid border-t-0 border-r-0 border-l-0"
        >
          <h6 class="text-primary font-normal">{{ $t('other_info') }}</h6>
        </MCol>
      </MRow>
      <slot name="before"></slot>
      <MRow class="my-3">
        <MCol>
          <div>
            <small class="text-neutral-light">
              {{ $tc('project_risk') }}
            </small>
          </div>
          <div>
            <ProjectRiskTypePicker
              :value="value.riskTypeId"
              :class="{ 'read-only': disabled }"
              input-classes="material-input"
              :disabled="disabled"
              :placeholder="disabled ? '---' : undefined"
              as-input
              @change="handleChange({ riskTypeId: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow class="my-3">
        <MCol>
          <div>
            <small class="text-neutral-light">
              {{ $tc('location') }}
            </small>
          </div>
          <FlotoLocationPicker
            v-model="value.locationId"
            :class="{ 'read-only': disabled }"
            input-classes="material-input"
            :disabled="disabled"
            :placeholder="disabled ? '---' : undefined"
            as-input
            full-path
            @change="handleChange({ locationId: $event })"
          />
        </MCol>
      </MRow>
      <MRow class="my-3">
        <MCol>
          <div>
            <small class="text-neutral-light">
              {{ $tc('vendor') }}
            </small>
          </div>
          <VendorPicker
            v-model="value.vendorId"
            :class="{ 'read-only': disabled }"
            input-classes="material-input"
            :disabled="disabled"
            :placeholder="disabled ? '---' : undefined"
            as-input
            full-path
            @change="handleChange({ vendorId: $event })"
          />
        </MCol>
      </MRow>
      <MRow
        v-if="!allowedFields || allowedFields.indexOf('projectSource') >= 0"
        class="my-3"
      >
        <MCol>
          <div>
            <small class="text-neutral-light">
              {{ $tc('source') }}
            </small>
          </div>
          <div>
            <ProjectSourcePicker
              :value="value.projectSource"
              input-classes="material-input"
              :class="{ 'read-only': true }"
              disabled
              :placeholder="disabled ? '---' : undefined"
              as-input
              @change="handleChange({ projectSource: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow class="my-3">
        <MCol
          class="border-neutral-lighter border-b border-solid border-t-0 border-r-0 border-l-0"
        >
          <div>
            <small class="text-neutral-light">
              {{ $tc('planning') }} {{ $tc('start_date') }}
            </small>
          </div>
          <div v-if="value.planningStartDate" class="mt-2">
            {{ value.planningStartDate | datetime }}
          </div>
          <div v-else class="mt-2 mb-1">{{ '---' }}</div>
        </MCol>
      </MRow>
      <MRow class="my-3">
        <MCol
          class="border-neutral-lighter border-b border-solid border-t-0 border-r-0 border-l-0"
        >
          <div>
            <small class="text-neutral-light">
              {{ $tc('implementation') }} {{ $tc('start_date') }}
            </small>
          </div>
          <div v-if="value.implementationStartDate" class="mt-2">
            {{ value.implementationStartDate | datetime }}
          </div>
          <div v-else class="mt-2 mb-1">{{ '---' }}</div>
        </MCol>
      </MRow>
      <MRow v-if="value.closedTime > 0" class="my-3">
        <MCol
          class="border-neutral-lighter border-b border-solid border-t-0 border-r-0 border-l-0"
        >
          <div>
            <small class="text-neutral-light">
              {{ $tc('closed_date') }}
            </small>
          </div>
          <div v-if="value.closedTime" class="mt-2">
            {{ value.closedTime | datetime }}
          </div>
          <div v-else class="mt-2 mb-1">{{ '---' }}</div>
        </MCol>
      </MRow>
      <MRow v-if="value.updatedTime > 0" class="my-3">
        <MCol
          class="border-neutral-lighter border-b border-solid border-t-0 border-r-0 border-l-0"
        >
          <div>
            <small class="text-neutral-light">
              {{ $tc('last_updated_date') }}
            </small>
          </div>
          <div v-if="value.updatedTime" class="mt-2">
            {{ value.updatedTime | datetime }}
          </div>
          <div v-else class="mt-2 mb-1">{{ '---' }}</div>
        </MCol>
      </MRow>
      <MRow class="my-3">
        <MCol class="material-input">
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('created_by') }}
            </small>
          </div>
          <div id="created-by" class="ant-input text-neutral">
            <FlotoUserDrawer :user-id="value.createdBy" />
          </div>
        </MCol>
      </MRow>
    </MCol>
  </MRow>
</template>

<script>
import ProjectRiskTypePicker from '@components/data-picker/project-risk-type-picker'
import VendorPicker from '@components/data-picker/vendor-picker.vue'
import ProjectSourcePicker from '@components/data-picker/project-source-picker.vue'
export default {
  name: 'Sidebar',
  components: { ProjectRiskTypePicker, VendorPicker, ProjectSourcePicker },
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, required: true },
    moduleName: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    allowedFields: { type: Array, default: undefined },
  },
  data() {
    return {}
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>

<style lang="less" scoped>
.bordered-bottom {
  @apply border-solid border-neutral-lighter border-b border-t-0 border-l-0 border-r-0;
}
</style>
