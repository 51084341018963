<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { DepartmentMethods } from '@state/modules/department'
import { LocationMethods } from '@state/modules/location'
import { PriorityActions } from '@state/modules/priority'
import { ImpactMethods } from '@state/modules/impact'
import { UrgencyMethods } from '@state/modules/urgency'
import { SourceMethods } from '@state/modules/source'
import { TaskMethods } from '@state/modules/task'
import { TechnicianMethods } from '@state/modules/technician'
import { PreferenceComputed } from '@state/modules/preference'
import { CurrencyMethods } from '@state/modules/currency'
export default {
  name: 'OrganizationModule',
  page() {
    return {
      title: this.$t('organization'),
    }
  },
  beforeRouteEnter(to, from, next) {
    // vm is this
    next((vm) => {
      if (
        to.name === 'admin.organization.account' &&
        vm.myAllowedModules.indexOf('admin.organization.account') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.branding' &&
        vm.myAllowedModules.indexOf('admin.organization.branding') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.department' &&
        vm.myAllowedModules.indexOf('admin.organization.departments') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.location' &&
        vm.myAllowedModules.indexOf('admin.organization.locations') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.announcement' &&
        vm.myAllowedModules.indexOf('admin.organization.announcements') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.leave-management' &&
        vm.myAllowedModules.indexOf('admin.organization.leave_management') ===
          -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.system-preference' &&
        vm.myAllowedModules.indexOf('admin.organization.system_preference') ===
          -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.security' &&
        vm.myAllowedModules.indexOf('admin.organization.security') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.priority' &&
        vm.myAllowedModules.indexOf('admin.organization.priority') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.impact' &&
        vm.myAllowedModules.indexOf('admin.organization.impact') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.urgency' &&
        vm.myAllowedModules.indexOf('admin.organization.urgency') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.task-type' &&
        vm.myAllowedModules.indexOf('admin.task_management') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.proxy-server-config' &&
        vm.myAllowedModules.indexOf(
          'admin.organization.proxy_server_configuration'
        ) === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'admin.organization.privacy-setting' &&
        vm.myAllowedModules.indexOf(
          'admin.organization.manage_privacy_setting'
        ) === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        to.name === 'msp' &&
        vm.myAllowedModules.indexOf(
          'admin.organization.managed_services_providers'
        ) === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (vm.myAllowedModules.indexOf('admin') === -1) {
        return vm.$router.replace({ name: '404' })
      }
      return true
    })
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === 'admin.organization.department' && this.fetchDepartment) {
      this.fetchDepartment()
    }
    if (from.name === 'admin.organization.location' && this.fetchLocation) {
      this.fetchLocation()
    }
    if (from.name === 'admin.organization.priority' && this.fetchPriority) {
      this.fetchPriority()
    }
    if (from.name === 'admin.organization.impact' && this.fetchImpact) {
      this.fetchImpact()
    }
    if (from.name === 'admin.organization.urgency' && this.fetchUrgency) {
      this.fetchUrgency()
    }
    if (from.name === 'admin.organization.source' && this.fetchSource) {
      this.fetchSource()
    }
    if (from.name === 'admin.organization.task-type' && this.fetchTaskType) {
      this.fetchTaskType()
    }
    if (
      from.name === 'admin.organization.leave-management' &&
      this.fetchTaskType
    ) {
      this.fetchTechnicians()
    }
    if (from.name === 'admin.organization.account' && this.fetchCurrency) {
      this.fetchCurrency()
    }
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
  },
  created() {
    this.hasPermission = true
  },
  methods: {
    ...DepartmentMethods,
    ...LocationMethods,
    ...PriorityActions,
    ...ImpactMethods,
    ...UrgencyMethods,
    ...SourceMethods,
    ...TaskMethods,
    ...TechnicianMethods,
    ...CurrencyMethods,
  },
}
</script>
