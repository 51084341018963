import {
  transformPrefixInfo,
  transformPrefixInfoForServer,
} from '@src/data/category.js'

export function transformHierarchyConfig(config) {
  return {
    id: config.id,
    allowedRequestLeafNode: config.allowedRequestLeafNode,
    allowedProblemLeafNode: config.allowedProblemLeafNode,
    allowedChangeLeafNode: config.allowedChangeLeafNode,
    allowedReleaseLeafNode: config.allowedReleaseLeafNode,
    allowedAssetLeafNode: config.allowedAssetLeafNode,
    reportHierarchy: config.reportHierarchy,
    requestDefaultCategoryPrefix: config.requestDefaultCategoryPrefix,
    companyWisePrefix:
      (config.companyWisePrefix || []).map(transformPrefixInfo) || [],
    requestStartingNumber: config.requestStartingNumber,
    requestNumberPadding: config.requestNumberPadding,
    curruentRequestNumber: config.curruentRequestNumber,
  }
}

export function transformHierarchyConfigForServer(config) {
  return {
    allowedRequestLeafNode: config.allowedRequestLeafNode,
    allowedProblemLeafNode: config.allowedProblemLeafNode,
    allowedAssetLeafNode: config.allowedAssetLeafNode,
    allowedChangeLeafNode: config.allowedChangeLeafNode,
    allowedReleaseLeafNode: config.allowedReleaseLeafNode,
    reportHierarchy: config.reportHierarchy,
    requestDefaultCategoryPrefix: (
      config.requestDefaultCategoryPrefix || ''
    ).toUpperCase(),
    companyWisePrefix:
      (config.companyWisePrefix || []).map(transformPrefixInfoForServer) || [],
    requestStartingNumber: config.requestStartingNumber,
    requestNumberPadding: config.requestNumberPadding,
    curruentRequestNumber: config.curruentRequestNumber,
  }
}
