var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-container chat-message-input-container flex items-center"},[_c('FlotoRichEditor',{key:_vm.renderCount,ref:"editorRef",staticClass:"flex-1",attrs:{"min-rows":1,"max-height":50,"affix-toolbar":false,"placeholder":_vm.$t('start_typing'),"disallowed-extensions":[
      'paragraph',
      'font-family',
      'text-align',
      'bullet-list',
      'ordered-list',
      'horizontal-rule',
      'table',
      'video',
      'image',
      'full-screen',
      'undo',
      'redo',
    ]},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('MIcon',{staticClass:"mx-2 send-icon",attrs:{"name":"send-msg","size":"lg","tabindex":"-1","disabled":_vm.isDisabled},on:{"click":_vm.sendMessage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }