var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FlotoFixedView',{attrs:{"gutter":0,"no-height":_vm.scrollable}},[_c('FlotoPaginatedList',{ref:"list",attrs:{"allow-keyboard-navigation":_vm.allowKeyboardNavigation,"immediate":_vm.immediate,"default-sort":_vm.defaultSort,"fetch-fn":_vm.fetchFn,"page-size":_vm.currentPageSize,"page":_vm.page,"paging":_vm.paging,"max-allowed-selection":_vm.maxAllowedSelection,"default-selected-item-ids":_vm.defaultSelectedItemIds},on:{"itemsReceived":_vm.handleNewItemsReceived,"selection-change":function($event){return _vm.$emit('selection-change', $event)},"select-item":function($event){return _vm.$emit('select-item', $event)}},scopedSlots:_vm._u([{key:"default",fn:function({
      loading,
      loadingMore,
      items,
      removeItem,
      appendItem,
      replaceItem,
      reset,
      prependItem,
      loadMoreItems,
      shouldRenderPagination,
      paginationMode,
      nextPage,
      navigateToPage,
      previousPage,
      pageInfo,
      error,
      replaceAllItems,
      toggleSelectItem,
      toggleSelectAll,
      selectedItemIds,
      selectedItemFullDetails,
      currentPageSelectedItemIds,
      sortedColumn,
      applySort,
      changePageSize,
      currentCursorPosition,
      currentOffset,
      // toggleExpand,
    }){return [_c('div',{staticClass:"flex flex-col min-w-0 w-full min-h-0",staticStyle:{"flex":"1"}},[_c('FlotoCrud',{ref:"crud",attrs:{"update-fn":_vm.updateFn,"create-fn":_vm.createFn,"delete-fn":_vm.deleteFn,"get-fn":_vm.getFn,"default-item":_vm.defaultItem},on:{"form-hide":function($event){return _vm.$emit('form-hide')}},scopedSlots:_vm._u([{key:"default",fn:function({
          activateItem,
          editingItem,
          create,
          edit,
          submitForm,
          remove,
          hideForm,
          processing,
          activeItem,
          resetActiveItem,
          setProcessing,
          resetForm,
        }){return [_c('div',{staticClass:"flex flex-col h-100"},[_c('div',{class:{ 'px-4': _vm.scrollable }},[_vm._t("add-controls",null,{"create":create,"loading":loading,"appendItem":appendItem,"refreshList":reset,"activateItem":activateItem,"activeItem":activeItem,"sortedColumn":sortedColumn,"applySort":applySort,"items":items,"resetActiveItem":resetActiveItem})],2),_vm._t("form",function(){return [_c('FlotoDrawerForm',{ref:"drawerFormRef",attrs:{"width":_vm.formWidth,"open":editingItem !== null},on:{"show":function($event){return setProcessing(false)},"submit":function($event){return _vm.handleFormSubmit(
                  submitForm,
                  editingItem,
                  hideForm,
                  editingItem.id
                    ? replaceItem
                    : _vm.prependNewItem
                    ? prependItem
                    : appendItem
                )},"cancel":hideForm},scopedSlots:_vm._u([(editingItem)?{key:"header",fn:function(){return [_vm._t("form-header",null,{"item":editingItem})]},proxy:true}:null,(editingItem)?{key:"actions",fn:function({ hide, submit, ...formSlotData }){return [_vm._t("form-actions",null,{"processing":processing,"item":editingItem,"cancel":hide,"submit":submit,"resetForm":_vm.getResetFormHandler(
                      resetForm,
                      items.find((i) => i.id === editingItem.id)
                    )},formSlotData)]}}:null],null,true)},[(editingItem)?_vm._t("form-items",null,{"item":editingItem,"resetForm":_vm.getResetFormHandler(
                    resetForm,
                    items.find((i) => i.id === editingItem.id)
                  )}):_vm._e()],2)]},{"editingItem":editingItem,"submit":_vm.getSubmitEditingItemFormHandler(
                submitForm,
                editingItem,
                hideForm,
                replaceItem,
                prependItem,
                appendItem
              ),"cancel":hideForm,"defaultItem":_vm.defaultItem}),_vm._t("before-list"),_vm._t("bulk-action-section",function(){return [(selectedItemIds.length && _vm.$scopedSlots['bulk-actions'])?_c('div',{class:{ 'px-4': _vm.scrollable }},[_c('div',{staticClass:"p-2 flex items-center bulk-action-header"},[(currentPageSelectedItemIds.length)?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"mr-2",on:{"click":function($event){return toggleSelectAll(false)}}},[_c('MIcon',{attrs:{"name":"minus-square","size":"lg"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('unselect'))+" ")]):(_vm.maxAllowedSelection === 0 && items.length > 0)?_c('MCheckbox',{staticClass:"mr-2",attrs:{"checked":currentPageSelectedItemIds.length === items.length &&
                    items.length > 0},on:{"change":toggleSelectAll}}):_vm._e(),_vm._t("bulk-actions",null,{"refreshList":reset,"toggleSelectAll":toggleSelectAll,"selectedItemIds":selectedItemIds,"selectedItemFullDetails":selectedItemFullDetails,"items":items}),_c('span',{staticClass:"mx-1 justify-self-end",class:{
                    'text-secondary-yellow': selectedItemIds.length > 100,
                  }},[_vm._v(" (Total "+_vm._s(selectedItemIds.length)+" records selected. "+_vm._s(selectedItemIds.length > 100 ? 'At a time, the selection of a maximum of 100 records is allowed' : '')+" "),_c('a',{on:{"click":function($event){return toggleSelectAll(false, true)}}},[_vm._v("Unselect All")]),_vm._v(") ")])],2)]):_vm._e()]},{"refreshList":reset,"toggleSelectAll":toggleSelectAll,"selectedItemIds":selectedItemIds,"items":items}),_c('div',{staticClass:"flex flex-1 flex-col min-h-0"},[_c('FlotoContentLoader',{attrs:{"loading":loading},scopedSlots:_vm._u([{key:"waiting",fn:function(){return [(loading)?_vm._t("loading",function(){return [_c('MRow',{staticClass:"w-full",class:{ 'px-4': _vm.scrollable }},[_c('MCol',{attrs:{"xxl":12}},[_c('MPlaceholder',{attrs:{"width":"100%","height":200,"primary-color":"var(--loader-primary-color)","secondary-color":"var(--loader-secondary-color)"}})],1)],1)]}):_vm._e()]},proxy:true}],null,true)},[_c('MRow',{staticClass:"flex-1 flex-no-wrap overflow-hidden min-h-0",attrs:{"gutter":0}},[_c('MCol',{staticClass:"min-w-0"},[_c(_vm.scrollable ? 'FlotoScrollView' : 'div',{tag:"component",attrs:{"show-duration":_vm.$attrs['scroll-show-duration']}},[_c('div',{staticClass:"flex flex-1 flex-col",class:{ 'px-4': _vm.scrollable }},[(!loading && !error)?_vm._t("list-items",function(){return [(
                            selectedItemIds.length &&
                            !_vm.$scopedSlots['bulk-actions']
                          )?_c('span',{staticClass:"py-1",class:{
                            'text-secondary-yellow':
                              selectedItemIds.length > 100,
                          }},[_vm._v(" Total "+_vm._s(selectedItemIds.length)+" records selected. "+_vm._s(selectedItemIds.length > 100 ? 'At a time, the selection of a maximum of 100 records is allowed' : '')+" "),_c('a',{on:{"click":function($event){return toggleSelectAll(false, true)}}},[_vm._v(" Unselect All ")])]):_vm._e(),(_vm.asTable)?_c('ResizableTable',_vm._b({attrs:{"use-scroll":!_vm.scrollable,"disabled":_vm.isDisabled(selectedItemIds),"sticky-headers":""}},'ResizableTable',items.length === 0 ? { minHeight: 0 } : {},false),[_c('table',{key:"tabular-list",staticClass:"item-list-table w-full"},[(
                                !(
                                  selectedItemIds.length &&
                                  _vm.$scopedSlots['bulk-actions']
                                ) && _vm.hideTableHeaders !== true
                              )?_c('thead',[_c('tr',[(_vm.selectable)?_c('td',{staticClass:"checkbox sticky"},[(currentPageSelectedItemIds.length)?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"mr-2",on:{"click":function($event){return toggleSelectAll(false)}}},[_c('MIcon',{attrs:{"name":"minus-square","size":"lg"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('unselect'))+" ")]):(
                                      _vm.maxAllowedSelection === 0 &&
                                      items.length > 0
                                    )?_c('MCheckbox',{attrs:{"checked":currentPageSelectedItemIds.length ===
                                        items.length && items.length > 0},on:{"change":toggleSelectAll}}):_vm._e()],1):_vm._e(),_vm._l((_vm.columns),function(column){return _c('td',{key:column.key,staticClass:"text-ellipsis sticky",class:{
                                    [column.class || '']: true,
                                    'non-resizable':
                                      column.resizable === false,
                                  },style:(_vm.columnStyle(column)),attrs:{"data-initial-width":column.width,"align":column.align ? column.align : undefined}},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"min-w-0 flex-1 text-ellipsis"},[_vm._v(" "+_vm._s(column.name)+" ")]),(column.sortable)?_c('MIcon',{staticClass:"cursor-pointer",attrs:{"name":sortedColumn
                                          ? sortedColumn.replace('-', '') ===
                                            column.key
                                            ? /^-/.test(sortedColumn)
                                              ? 'sort-up'
                                              : 'sort-down'
                                            : 'sort'
                                          : 'sort'},on:{"click":function($event){return applySort(column.key)}}}):_vm._e()],1)])})],2)]):_vm._e(),(items.length)?_c('SortableList',{attrs:{"value":items,"tag":"tbody","disabled":!_vm.sortable,"handle":".drag-handle"},on:{"drag-start":function($event){return _vm.$emit('drag-start')},"drag-end":function($event){return _vm.$emit('drag-end')},"update":function($event){return _vm.handleItemsOrderChange(
                                  $event,
                                  items,
                                  replaceAllItems
                                )}}},[_vm._l((items),function(item,index){return [_c('tr',{key:item.id,class:{
                                    'active-cursor':
                                      _vm.allowKeyboardNavigation &&
                                      index === currentCursorPosition,
                                  },attrs:{"id":item.name}},[(_vm.selectable)?_c('td',{staticClass:"checkbox"},[_c('MCheckbox',{attrs:{"checked":selectedItemIds.indexOf(item.id) >=
                                          0 ||
                                        _vm.defaultSelected.indexOf(item.id) >= 0,"disabled":(_vm.selectedDisabled &&
                                          _vm.defaultSelected.indexOf(item.id) >=
                                            0) ||
                                        item.disableSelection},on:{"change":function($event){return toggleSelectItem(item)}}})],1):_vm._e(),_vm._l((_vm.columns),function(column){return _vm._t(column.key,function(){return [_c('td',{key:column.key,staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item[column.key])+" ")])]},{"activateItem":activateItem,"index":index,"edit":() => edit(item),"create":(payload) => create(payload),"refreshList":reset,"activeItem":activeItem,"isFirstItem":index === 0,"isLastItem":index === items.length - 1,"update":(payload) =>
                                        submitForm(payload).then((response) =>
                                          replaceItem(response)
                                        ),"remove":() =>
                                        remove(item).then(() =>
                                          removeItem(item)
                                        ),"item":item,"items":items,"resetActiveItem":resetActiveItem,"currentCursorPosition":currentCursorPosition,"currentOffset":currentOffset,"toggleSelectAll":toggleSelectAll,"processing":processing})})],2)]})],2):_vm._e()],1)]):_vm._e(),(items.length && !_vm.asTable && !loading)?_c('div',{staticClass:"flex-1"},[_vm._l((items),function(item,index){return _vm._t("item",null,{"index":index,"isFirstItem":index === 0,"isLastItem":index === items.length - 1,"activateItem":activateItem,"edit":() => edit(item),"create":(payload) => create(payload),"activeItem":activeItem,"resetActiveItem":resetActiveItem,"currentCursorPosition":currentCursorPosition,"currentOffset":currentOffset,"replaceAllItems":replaceAllItems,"update":(payload) =>
                                submitForm(payload).then((response) => {
                                  replaceItem(response)
                                  if (response.id === (activeItem || {}).id) {
                                    activateItem(response)
                                  }
                                }),"remove":() => remove(item).then(() => removeItem(item)),"item":item,"sortedColumn":sortedColumn,"applySort":applySort,"toggleSelectItem":() => toggleSelectItem(item),"isSelected":selectedItemIds.indexOf(item.id) >= 0})})],2):_vm._e()]},null,{
                          submit: _vm.getSubmitEditingItemFormHandler(
                            submitForm,
                            editingItem,
                            hideForm,
                            replaceItem,
                            prependItem,
                            appendItem
                          ),
                          items,
                          edit,
                          create,
                          activeItem,
                          activateItem,
                          sortedColumn,
                          applySort,
                          update: (payload, item) => {
                            return submitForm(payload, item).then(
                              (response) => {
                                replaceItem(response, item)
                                if (response.id === (activeItem || {}).id) {
                                  activateItem(response, item)
                                }
                              }
                            )
                          },
                          appendItem,
                          prependItem,
                          replaceItem,
                          removeItem,
                          remove: (x) => remove(x).then(() => removeItem(x)),
                          reset,
                          replaceAllItems,
                          resetActiveItem,
                          toggleSelectItem,
                          toggleSelectAll,
                          selectedItemIds,
                          navigateToPage,
                          changePageSize,
                          pageInfo,
                          currentCursorPosition,
                          currentOffset,
                        }):_vm._e(),(
                          !items.length && !error && _vm.showNoData && !loading
                        )?_vm._t("no-data",function(){return [_c('FlotoNoData',{attrs:{"size":_vm.messageSize}})]}):_vm._e(),(error)?_vm._t("error",function(){return [_c('FlotoLoadError',{attrs:{"size":_vm.messageSize,"authorization-error":error.response && error.response.status === 403}},[_c('MButton',{on:{"click":reset}},[_vm._v(" "+_vm._s(_vm.$t('reload'))+" ")])],1)]},{"refresh":reset,"error":error}):_vm._e()],2)])],1)],1),(
                  (!_vm.perPage || paginationMode === 'loadmore') &&
                  pageInfo.total > 0 &&
                  (shouldRenderPagination || paginationMode === 'paging') &&
                  !loading
                )?_vm._t("pagination",function(){return [_c('MRow',{staticClass:"my-2",class:{ 'px-4': _vm.scrollable },attrs:{"gutter":0}},[_c('MCol',{class:{
                      'text-center': paginationMode === 'loadmore',
                      'text-right': paginationMode === 'paging',
                    }},[(
                        shouldRenderPagination &&
                        paginationMode === 'loadmore' &&
                        !loadingMore
                      )?_c('MButton',{attrs:{"loading":loading},on:{"click":loadMoreItems}},[_vm._v(" "+_vm._s(_vm.$t('load_more'))+" ")]):_vm._e(),(paginationMode !== 'loadmore')?_c('MPagination',{attrs:{"hide-on-single-page":false,"size":"small","page-size":pageInfo.pageSize,"show-size-changer":"","total":pageInfo.total,"show-total":(total, range) =>
                          _vm.$t('pagination_info', {
                            from: range[0],
                            to: range[1],
                            total,
                            item: _vm.resourceName || _vm.$tc('record', 2),
                          })},on:{"change":navigateToPage,"update:page-size":changePageSize},scopedSlots:_vm._u([{key:"default",fn:function(slotData){return [_c('span',[_vm._v(_vm._s(slotData.value)+" / "+_vm._s(_vm.$tc('page')))])]}}],null,true),model:{value:(pageInfo.current),callback:function ($$v) {_vm.$set(pageInfo, "current", $$v)},expression:"pageInfo.current"}}):_vm._e()],1)],1)]},{"paginationMode":paginationMode,"loadMoreItems":loadMoreItems,"nextPage":nextPage,"previousPage":previousPage,"navigateToPage":navigateToPage,"changePageSize":changePageSize,"pageInfo":pageInfo}):_vm._e(),_vm._t("after-list",null,{"selectedItemIds":selectedItemIds,"refreshList":reset}),(loadingMore && paginationMode === 'loadmore')?_c('MRow',{staticClass:"w-full mt-4",class:{ 'px-4': _vm.scrollable },attrs:{"gutter":0}},[_c('MCol',{attrs:{"xxl":12}},[_vm._t("loading",function(){return [_c('MPlaceholder',{attrs:{"width":"100%","height":200,"primary-color":"var(--loader-primary-color)","secondary-color":"var(--loader-secondary-color)"}})]})],2)],1):_vm._e()],2)],1),_vm._t("default",null,null,{
              activeItem,
              activateItem,
              resetActiveItem,
              editingItem,
              appendItem,
              prependItem,
              replaceItem,
              removeItem,
              toggleSelectItem: () => toggleSelectItem(_vm.item),
              selectedItemIds,
              remove: (item) => remove(item).then(() => removeItem(item)),
              reset,
              items,
              update: (payload) =>
                submitForm(payload).then((response) => {
                  replaceItem(response)
                  if (response.id === (activeItem || {}).id) {
                    activateItem(response)
                  }
                }),
              loading,
            })],2)]}}],null,true)})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }