var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MRow',{staticClass:"mb-4 rounded min-w-0",attrs:{"gutter":0}},[_c('MCol',{staticClass:"rounded px-2 m-border min-w-0"},[_c('div',{staticClass:"my-2 flex items-center mb-2"},[_c('h5',{staticClass:"flex-1 mb-0",class:{
          'text-primary':
            _vm.moduleName !== _vm.$constants.CHANGE ||
            _vm.moduleName !== _vm.$constants.RELEASE,
          'text-netural-light':
            _vm.moduleName === _vm.$constants.CHANGE ||
            _vm.moduleName === _vm.$constants.RELEASE,
        }},[_vm._t("title")],2),(!_vm.disabled)?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{attrs:{"id":"edit-close-btn","shape":"circle","variant":"transparent","shadow":false},on:{"click":_vm.toggleEditMode}},[_c('MIcon',{class:{
                'text-neutral-light': !_vm.isEditing,
                'text-secondary-red': _vm.isEditing,
              },attrs:{"name":_vm.isEditing ? 'times-circle' : 'pencil'}})],1)]},proxy:true}],null,false,222488998)},[_vm._v(" "+_vm._s(!_vm.isEditing ? _vm.$tc('edit') : _vm.$tc('cancel'))+" ")]):_vm._e()],1),(!_vm.isEditing && _vm.data.text)?_c('MDivider',{staticClass:"my-2"}):_vm._e(),_c('Transition',{attrs:{"name":"placeholder"}},[(_vm.isEditing)?_c('div',{staticClass:"flex flex-col"},[_c('FlotoForm',{staticClass:"flex items-end w-full",attrs:{"layout":"vertical"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"submit",fn:function(){return [_c('MButton',{staticClass:"ml-1 mb-4",staticStyle:{"width":"30px","min-width":"30px","height":"30px"},attrs:{"id":"submit-btn","loading":_vm.processing,"shape":"circle","type":"submit"}},[(!_vm.processing)?_c('MIcon',{attrs:{"name":"paper-plane","size":"sm"}}):_c('span')],1)]},proxy:true}],null,false,2018710600)},[_c('FlotoFormItem',{staticClass:"w-full overflow-hidden",attrs:{"id":"text-input","rules":"required","validation-label":_vm.$t('text')}},[_c('FlotoRichEditor',{staticClass:"flex-1 mb-1",attrs:{"auto-focus":"","name":_vm.$t('text'),"rows":8,"affix-toolbar":false,"placeholder":_vm.$t('start_typing')},model:{value:(_vm.data.text),callback:function ($$v) {_vm.$set(_vm.data, "text", $$v)},expression:"data.text"}})],1)],1),_c('MDivider',{staticClass:"mb-2"}),_c('div',{staticClass:"mb-2"},[_c('FlotoAttachment',{attrs:{"as-link":""},model:{value:(_vm.data.fileAttachments),callback:function ($$v) {_vm.$set(_vm.data, "fileAttachments", $$v)},expression:"data.fileAttachments"}})],1)],1):_c('MRow',[_c('MCol',{attrs:{"size":12}},[_c('FlotoReadMore',{attrs:{"height":8 * 20}},[_c('FlotoRichEditor',{attrs:{"value":_vm.data.text,"disabled":""}})],1)],1),_c('MCol',{attrs:{"size":12}},[_c('FlotoAttachment',{attrs:{"disabled":""},model:{value:(_vm.data.fileAttachments),callback:function ($$v) {_vm.$set(_vm.data, "fileAttachments", $$v)},expression:"data.fileAttachments"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }