import { getRootPluaralTranslator } from '@/src/utils/get-module-translator'
import { transformRecursive } from './recursive'
const __rootTc = getRootPluaralTranslator()

export function transformDepartmentRecursive(departments) {
  return transformRecursive(departments, transformDepartment)
}

export function transformNameWithArchive(item) {
  const postFix = () => {
    if (item.removed) {
      return `(${__rootTc('archived')})`
    }
    return ''
  }
  return {
    nameWithArchive: `${item.name} ${postFix()}`.trim(),
  }
}

export function transformDepartment(department) {
  const textArchiveNameProps = transformNameWithArchive(department)
  return {
    id: department.id,
    parentId: department.parentId,
    children: [],
    value: department.id,
    label: textArchiveNameProps.nameWithArchive,
    order: department.objOrder,
    name: textArchiveNameProps.nameWithArchive,
    disabled: department.disabled,
    archived: department.removed,
    description: department.description,
    departmentHead: department.departmentHead,
    departmentHeadName: department.departmentHeadName,
    canDelete: department.deleteableOob,
    canEdit: department.updatebleOob,
  }
}
export function transformDepartmentForServer(department) {
  return {
    parentId: department.parentId,
    name: department.name,
    description: department.description,
    departmentHead: department.departmentHead,
    departmentHeadName: department.departmentHeadName,
  }
}
