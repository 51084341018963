var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.textOnly)?_c('span',[_vm._v(_vm._s(_vm.value.join(',')))]):((_vm.value || []).filter(Boolean).length)?_c('div',{staticClass:"flex-1 min-w-0 flex",class:{ 'flex-wrap': _vm.wrap, 'flex-col': _vm.vertical }},[_vm._l((_vm.value.slice(0, _vm.maxItems)),function(item,index){return _c('MTag',{key:`${index}-${item}`,staticClass:"mr-1 inline-flex min-w-0 mb-1 mt-1",attrs:{"closable":_vm.closable,"variant":_vm.variant},on:{"close":function($event){_vm.$emit('close', _vm.getDropdownItemValue(item))}}},[_c('span',{staticClass:"text-ellipsis md-text-sm mx-1",attrs:{"title":typeof item === 'object' ? item.text || item.name || item.label : item}},[_vm._v(" "+_vm._s(typeof item === 'object' ? item.text || item.name || item.label : item)+" ")])])}),(_vm.value.slice(_vm.maxItems).length)?[_c(_vm.usePopover ? 'MPopover' : 'MPopper',_vm._b({tag:"component",staticStyle:{"line-height":"22px"},attrs:{"disabled":_vm.disabled}},'component',
        _vm.usePopover
          ? {
              placement: 'bottom',
              transitionName: 'none',
              overlayClassName: 'readable-content-overlay1 picker-overlay',
            }
          : {
              overlayClassName: 'readable-content-overlay1 picker-overlay',
              placement: 'bottom',
            }
      ,false),[_c('MTag',{staticClass:"mr-1 inline-flex min-w-0 mb-1 mt-1 cursor-pointer",attrs:{"slot":"trigger","closable":false,"variant":_vm.variant},slot:"trigger"},[_vm._v(" +"+_vm._s(_vm.value.slice(_vm.maxItems).length)+" ")]),_c('div',{staticClass:"ant-menu ant-menu-vertical ant-menu-root ant-menu-light ant-dropdown-menu"},_vm._l((_vm.value.slice(_vm.maxItems)),function(item,index){return _c('div',{key:`${item}-${index}`,staticClass:"ant-menu-item scroll-dropdown-menu-item ant-dropdown-menu-item value-active-item"},[_vm._v(_vm._s(typeof item === 'object' ? item.text || item.name || item.label : item))])}),0)],1)]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }