import IsEqual from 'lodash/isEqual'
import isPlainObject from 'lodash/isPlainObject'

export const getChangedProperties = (source, destination) => {
  const keysToCompare = Object.keys(source)
  const response = Object.assign({}, destination)
  keysToCompare.forEach((key) => {
    if (
      (Array.isArray(source[key]) || isPlainObject(source[key])) &&
      IsEqual(source[key], destination[key])
    ) {
      delete response[key]
      return true
    }
    if (source[key] === destination[key]) {
      delete response[key]
      return true
    }
  })
  return response
}

export const getValueExistProperties = (data) => {
  const updatedData = {}
  Object.keys(data).forEach((key) => {
    const value = data[key]
    const valueType = typeof value
    if (
      (valueType === 'string' && value !== '') ||
      (valueType === 'number' && value !== 0) ||
      (Array.isArray(value) && value.length > 0)
    ) {
      updatedData[key] = value
    }
  })
  return updatedData
}
