<template>
  <div
    data-field-type="section"
    class="section w-full"
    :class="{
      'sortable-chosen': currentEditingItem.guid === field.guid,
      builder: mode === 'builder',
    }"
  >
    <template v-if="$attrs['disabled']">
      <MCollapse
        :accordion="true"
        :bordered="false"
        class="flex-1 size-large collapsable"
      >
        <MCollapsePanel :key="field.title" ref="MCollapsePanelRef">
          <template v-slot:header>
            <div class="section-header">
              <div class="section-name-collapsable">
                <span>{{ field.title }}</span>
                <ActionButtons
                  v-if="mode === 'builder'"
                  :allow-split-field="false"
                  :blocked-btns="['duplicate']"
                  @edit="$emit('field-edit')"
                  @remove="$emit('field-remove')"
                />
              </div>
            </div>
          </template>
          <div class="section-body-collapsable">
            <slot />
          </div>
        </MCollapsePanel>
      </MCollapse>
    </template>
    <template v-else>
      <MDivider />
      <div class="section-header">
        <div class="section-name">
          <span>{{ field.title }}</span>
          <ActionButtons
            v-if="mode === 'builder'"
            :allow-split-field="false"
            :blocked-btns="['duplicate']"
            @edit="$emit('field-edit')"
            @remove="$emit('field-remove')"
          />
        </div>
      </div>
      <div class="section-body">
        <slot />
      </div>
      <MDivider />
    </template>
  </div>
</template>

<script>
import ActionButtons from '../action-buttons/buttons'

export default {
  name: 'Section',
  components: { ActionButtons },
  props: {
    field: { type: Object, required: true },
    mode: { type: String, default: 'builder' },
  },
  computed: {
    currentEditingItem() {
      return this.$attrs['current-editing-item'] || {}
    },
  },
}
</script>

<style lang="less" scoped>
.section {
  &.builder {
    @apply border-dashed rounded border border-neutral-lighter border-t-0 border-b-0;
  }

  .section-header {
    .section-name {
      font-size: var(--text-lg) !important;

      @apply flex justify-between mb-2 px-4 py-1 text-primary;
    }

    .section-name-collapsable {
      font-size: var(--text-regular) !important;

      @apply flex justify-between items-center px-2 text-primary;
    }
  }

  .section-body {
    min-height: 4rem;

    @apply p-2;
  }

  .section-body-collapsable {
    min-height: 4rem;

    @apply p-2;
  }

  .ant-collapse.size-large .ant-collapse-item > .ant-collapse-header {
    padding: 10px 0 10px 32px !important;
  }
}
</style>
