var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MDropdown',{ref:"dropdownMenu",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{staticClass:"text-neutral",attrs:{"id":"action-btn","variant":"transparent","shadow":false,"shape":"circle"}},[_c('MIcon',{attrs:{"name":"ellipsis-v","size":"2x"}})],1)]},proxy:true},{key:"menu",fn:function(){return [_c('MMenu',{staticClass:"action-menu"},[(
          _vm.project.projectStatus === 'cancelled' ||
          _vm.project.projectStatus === 'closed'
        )?_c('MMenuItem',{attrs:{"id":"archive-item"}},[_c('Archive',{attrs:{"resource-id":_vm.project.id,"module-name":_vm.moduleName},on:{"trigger":_vm.hide,"done":_vm.handleArchive}})],1):_vm._e(),(
          _vm.project.projectStatus !== 'cancelled' &&
          _vm.project.projectStatus !== 'closed'
        )?_c('MMenuItem',{attrs:{"id":"cancel-project-item"}},[_c('CancelProject',{attrs:{"project":_vm.project,"module-name":_vm.moduleName,"update-api":_vm.updateApi},on:{"trigger":_vm.hide}})],1):_vm._e(),_c('MMenuItem',{attrs:{"id":"clone-project-item"}},[_c('FlotoLink',{attrs:{"to":_vm.$modules.getModuleRoute('project', 'create', {
              params: { cloningId: _vm.project.id },
            })}},[_c('MIcon',{staticClass:"action-menu-icon mr-1",attrs:{"name":"clone"}}),_c('span',{staticClass:"ml-1 action-menu-text"},[_vm._v(" "+_vm._s(_vm.$t('clone'))+" "+_vm._s(_vm.$tc('project')))])],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }