import SortBy from 'lodash/sortBy'
import { generateId } from '@utils/id'
export function transformShippingAddress(purchase) {
  return {
    shippingAddress: {
      street: purchase.sStreetLine1,
      landmark: purchase.sStreetLine2,
      city: purchase.sCity,
      pinCode: purchase.sZipCode,
      state: purchase.sState,
      country: purchase.sCountry,
    },
  }
}
export function transformShippingAddressForServer(address) {
  return {
    sStreetLine1: address.street,
    sStreetLine2: address.landmark,
    sCity: address.city,
    sZipCode: address.pinCode,
    sState: address.state,
    sCountry: address.country,
  }
}
export function transformBillingAddress(purchase) {
  return {
    billingAddress: {
      street: purchase.bStreetLine1,
      landmark: purchase.bStreetLine2,
      city: purchase.bCity,
      pinCode: purchase.bZipCode,
      state: purchase.bState,
      country: purchase.bCountry,
    },
  }
}
export function transformBillingAddressForServer(address) {
  return {
    bStreetLine1: address.street,
    bStreetLine2: address.landmark,
    bCity: address.city,
    bZipCode: address.pinCode,
    bState: address.state,
    bCountry: address.country,
  }
}

export const purchaseFormFields = []

export function transformPurchaseForList(purchase) {
  return {
    guid: generateId(),
    id: purchase.id,
    name: purchase.name,
    displayName: purchase.displayName,
    subject: purchase.displayName,
    statusId: purchase.statusId,
    orderNumber: purchase.orderNumber,
    technicianId: purchase.technicianId,
    requiredByTime: purchase.requiredByTime,
    vendorId: purchase.vendorId,
    currencyId: purchase.currencyId,
    totalCost: purchase.totalCost,
    orderedDate: purchase.orderedDate,
    receivedDate: purchase.receivedDate,
    purchaseCloseDate: purchase.purchaseCloseDate,
    requesterId: purchase.requesterId,
    approvalStatus: purchase.approvalStatus,
    costCenter: purchase.costCenter,
    glCode: purchase.glCode,
    shippingCost: purchase.shippingCost,
    totalInvoiceAmount: purchase.totalInvoiceAmount,
    totalPaymentAmount: purchase.totalPaymentAmount,
    tags: purchase.tags,
    invoiceReceived: purchase.invoiceReceived,
    paymentStatus: purchase.paymentStatus,
    printTemplateId: purchase.printTemplateId,
    fieldValueDetails: purchase.fieldValueDetails || {},
    ...(purchase.fieldValueDetails || {}),
    relationId: purchase.relationId,
    archived: purchase.removed,
    canDelete: purchase.deleteableOob,
    canEdit: purchase.updatebleOob,
    createdAt: purchase.createdTime,
    updatedAt: purchase.updatedTime,
    closedAt: purchase.closedDate,
    createdBy: purchase.createdById,
  }
}

export function transformPurchaseForDetail(purchase) {
  const listProps = transformPurchaseForList(purchase)
  return {
    ...listProps,
    description: purchase.description,
    remarks: purchase.remarks,
    terms: purchase.terms,
    signingAuthorities: SortBy(
      purchase.signingAuthorities || [],
      'orderId'
    ).map((i) => ({
      ...i,
      guid: generateId(),
    })),
    discount: purchase.discount,
    updatedTime: purchase.updatedTime,

    bStreetLine1: purchase.bStreetLine1,
    bStreetLine2: purchase.bStreetLine2,
    bCity: purchase.bCity,
    bState: purchase.bState,
    bCountry: purchase.bCountry,
    bZipCode: purchase.bZipCode,

    sStreetLine1: purchase.sStreetLine1,
    sStreetLine2: purchase.sStreetLine2,
    sCity: purchase.sCity,
    sState: purchase.sState,
    sCountry: purchase.sCountry,
    sZipCode: purchase.sZipCode,

    itemList: (purchase.itemList || []).map((i) => ({
      ...i,
      guid: generateId(),
    })),

    totalNet: purchase.totalNet,
    subTotal: purchase.subTotal,
    approvalType: purchase.approvalType,
    fileAttachments: purchase.fileAttachments,

    notes: purchase.notes,
    noteAttachments: purchase.noteAttachments,

    ...transformShippingAddress(purchase),
    ...transformBillingAddress(purchase),
  }
}

export function transformPurchaseForServer(purchase) {
  const removeExtraCalculated = Object.keys(
    purchase.fieldValueDetails || {}
  ).map((key) => `${key}Amount`)
  const transformedProps = [
    'fieldValueDetails',
    'approvalStatus',
    'shippingAddress',
    'billingAddress',
    'copyToBilling',
    'subject',
    'guid',
    'archived',
    'canDelete',
    'canEdit',
    'createdAt',
    'updatedAt',
    'closedAt',
    'signingAuthorities',
    ...(removeExtraCalculated || []),
  ]
  const obj = {
    ...(purchase.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...purchase.fieldValueDetails,
          },
        }
      : {}),
  }
  Object.keys(purchase).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = purchase[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: purchase[paramName],
        }
      }
    } else if (!transformedProps.includes(paramName)) {
      obj[paramName] = purchase[paramName]
    }
  })
  if ('description' in purchase) {
    obj.description = purchase.description || ''
  }
  if ('shippingAddress' in purchase) {
    const shippingAddress = transformShippingAddressForServer(
      purchase.shippingAddress
    )
    Object.keys(shippingAddress).forEach((key) => {
      obj[key] = shippingAddress[key]
    })
  }
  if ('billingAddress' in purchase) {
    const billingAddress = transformBillingAddressForServer(
      purchase.billingAddress
    )
    Object.keys(billingAddress).forEach((key) => {
      obj[key] = billingAddress[key]
    })
  }
  if ('signingAuthorities' in purchase) {
    const signingAuthoritiesForServer =
      (purchase.signingAuthorities || []).length > 0
        ? purchase.signingAuthorities.map((s, i) => ({
            ...s,
            signingPlace: s.signingPlace ? s.signingPlace : '',
            signingAuthority: s.signingAuthority ? s.signingAuthority : '',
            orderId: i + 1,
          }))
        : []
    obj.signingAuthorities = signingAuthoritiesForServer
  }
  return obj
}
