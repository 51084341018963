var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MRow',{staticClass:"justify-between items-center overflow-hidden flex-no-wrap",attrs:{"gutter":0}},[_c('MCol',{staticClass:"min-w-0 flex-1"},[_c(_vm.internalLink && _vm.link ? (_vm.openInNewTab ? 'a' : 'FlotoLink') : 'div',_vm._b({tag:"component"},'component',
        _vm.internalLink && _vm.link
          ? {
              ...(_vm.openInNewTab ? {} : { to: _vm.hrefLink }),
              ...(_vm.openInNewTab ? { target: '_blank', href: _vm.hrefLink } : {}),
            }
          : {}
      ,false),[_c('h5',{staticClass:"subject-title font-normal text-ellipsis"},[_vm._t("before-title"),_vm._v(" "+_vm._s(_vm.name)+" "+_vm._s(_vm.subject ? _vm.subject : '')+" "),(_vm.markResourceArchived)?_c('span',[_vm._v(_vm._s(`(${_vm.$tc('archived')})`))]):_vm._e()],2),_c('small',{staticClass:"text-neutral text-ellipsis block"},[_vm._v(" "+_vm._s(`${_vm.ownerName ? `${_vm.$t('by')}: ${_vm.ownerName},` : ''}`)+" "+_vm._s(_vm._f("datetime")(_vm.createdAt))+" ")])])],1),(_vm.externalLink && _vm.link)?_c('MCol',{staticClass:"hover-action-container text-right",attrs:{"auto-size":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('FlotoLink',{staticClass:"icon",attrs:{"target":"_blank","to":_vm.link}},[_c('MIcon',{attrs:{"name":"external-link"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }