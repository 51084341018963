var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShowAge)?_c('div',[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('ToolTipDeatils',{staticClass:"text-ellipsis",attrs:{"is-couter-stopped":_vm.isCouterStopped,"pendding-in-status":_vm.penddingInStatus,"is-over-due":_vm.isOverDue,"diff":_vm.$options.filters.duration(_vm.diff, undefined, _vm.isHumanize),"prefix":_vm.prefix,"is-show-age":""}})]},proxy:true}],null,false,3180086803)},[_c('div',{staticClass:"flex flex-wrap"},[_c('ToolTipDeatils',{attrs:{"is-couter-stopped":_vm.isCouterStopped,"pendding-in-status":_vm.penddingInStatus,"is-over-due":_vm.isOverDue,"diff":_vm.$options.filters.duration(_vm.diff, undefined, _vm.isHumanize),"is-show-age":"","prefix":_vm.prefix}},[_vm._t("after-text",null,{"toggle":_vm.toggleDatepicker})],2)],1)])],1):(_vm.compactView)?_c('div',[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{class:{
          'text-secondary-green': !_vm.isCouterStopped && !_vm.isOverDue,
          'text-secondary-red': _vm.isOverDue,
          'text-neutral-light': _vm.isCouterStopped,
        },attrs:{"name":"clock","size":_vm.iconSize}})]},proxy:true}])},[_c('div',{staticClass:"flex flex-wrap"},[_c('ToolTipDeatils',{attrs:{"is-couter-stopped":_vm.isCouterStopped,"pendding-in-status":_vm.penddingInStatus,"is-over-due":_vm.isOverDue,"diff":_vm.$options.filters.duration(_vm.diff, undefined, _vm.isHumanize),"prefix":_vm.prefix}},[_vm._t("after-text",null,{"toggle":_vm.toggleDatepicker})],2)],1)])],1):_c('div',{staticClass:"flex flex-1 items-center",class:{
    'dropdown-trigger': _vm.asDropdown,
    'secondary-trigger': _vm.type === 'secondary',
    'mr-4': _vm.useMargin,
  }},[_c('div',{staticClass:"relative",class:{ 'mx-1': !_vm.asDropdown },attrs:{"auto-size":""}},[_c('a',{class:{ 'cursor-default': _vm.disabled, 'cursor-pointer': !_vm.disabled },on:{"click":_vm.toggleDatepicker}},[_c('div',{class:{
          'bg-secondary-green': _vm.asDropdown && !_vm.isCouterStopped && !_vm.isOverDue,
          'bg-secondary-red': _vm.asDropdown && _vm.isOverDue,
          'bg-neutral-light': _vm.asDropdown && _vm.isCouterStopped,
          'rounded icon-container': _vm.asDropdown,
          'p-2': _vm.asDropdown && _vm.type !== 'secondary',
        }},[_c('MIcon',{class:{
            'text-white': _vm.asDropdown,
            'text-secondary-green':
              !_vm.asDropdown && !_vm.isCouterStopped && !_vm.isOverDue,
            'text-secondary-red': !_vm.asDropdown && _vm.isOverDue,
            'text-neutral-light': !_vm.asDropdown && _vm.isCouterStopped,
          },attrs:{"name":"clock","size":_vm.iconSize}})],1)]),(_vm.isDatepickerOpen)?_c('FlotoDatePicker',{staticClass:"due-date-picker",attrs:{"default-open":"","value":_vm.value},on:{"hide":_vm.hideDatepicker,"change":_vm.handleDateChange}}):_vm._e()],1),_c('div',{staticClass:"details flex flex-1 flex-col text-ellipsis py-1 mx-1",style:({ lineHeight: '14px' })},[_c('div',{staticClass:"flex flex-wrap items-center",class:{
        'text-secondary-green': _vm.asDropdown && !_vm.isCouterStopped && !_vm.isOverDue,
        'text-secondary-red': _vm.asDropdown && _vm.isOverDue,
        'text-neutral-light': _vm.asDropdown && _vm.isCouterStopped,
        'font-medium': _vm.asDropdown,
      }},[_vm._t("before-text",null,{"toggle":_vm.toggleDatepicker}),_c('div',{staticClass:"flex flex-1 text-ellipsis"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('ToolTipDeatils',{staticClass:"text-ellipsis",attrs:{"is-couter-stopped":_vm.isCouterStopped,"pendding-in-status":_vm.penddingInStatus,"is-over-due":_vm.isOverDue,"diff":_vm.$options.filters.duration(_vm.diff, undefined, _vm.isHumanize),"prefix":_vm.prefix}})]},proxy:true}])},[_c('div',{staticClass:"flex flex-wrap"},[_c('ToolTipDeatils',{attrs:{"is-couter-stopped":_vm.isCouterStopped,"pendding-in-status":_vm.penddingInStatus,"is-over-due":_vm.isOverDue,"diff":_vm.$options.filters.duration(_vm.diff, undefined, _vm.isHumanize),"prefix":_vm.prefix}},[_vm._t("after-text",null,{"toggle":_vm.toggleDatepicker})],2)],1)])],1),(!_vm.disabled && _vm.showIcon)?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"cursor-pointer ml-2 text-neutral",attrs:{"name":"pencil"},on:{"click":_vm.toggleDatepicker}})]},proxy:true}],null,false,3454219330)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]):_vm._e()],2),_vm._t("after-text",null,{"toggle":_vm.toggleDatepicker})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }