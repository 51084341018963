export function transformAdminAudit(audit) {
  return {
    text: audit.auditString,
    eventName: audit.displayName,
    links: audit.links || [],
    moduleName: audit.refModel,
    model: audit.model,
    createdAt: audit.createdTime,
    id: audit.id,
    owner: audit.createdById,
    ipAddress: audit.ipAddress,
    event: audit.auditEvent,
    technicianId: audit.performerId,
    userName: audit.userName,
    resourceName: audit.objName,
    resourceId: audit.refId,
    emailServerName: audit.emailServerName,
    emails: (audit.emails || '').split(','),
    emailSubject: audit.emailSubject,
    emailStatus: audit.emailStatus,
    emailMessageId: audit.emailMessageId,
  }
}

export function transformScheduledOperationAudit(audit) {
  return {
    id: audit.id,
    operation: audit.operation,
    childModel: audit.childModel,
    startDate: audit.startDate,
    endDate: audit.endDate,
    successRecords: audit.successRecords,
    successName: audit.successName,
    failureRecords: audit.failureRecords,
    failureName: audit.failureName,
  }
}

export function transformScheduledOperationAuditHistory(audit) {
  return {
    id: audit.id,
    refId: audit.refId,
    name: audit.name,
    subject: audit.subject,
    success: audit.success,
  }
}

export function transformDownloadAuditForServer(audit) {
  return {
    startDate: audit.startDate,
    endDate: audit.endDate,
    technicianIds: audit.technicianIds,
    ipAddress: audit.ipAddress,
    refModel: audit.refModel,
    refId: audit.refId,
    event: audit.event,
    displayName: audit.displayName,
    emailServerName: audit.emailServerName,
    emailStatus: audit.emailStatus,
    emailMessageId: audit.emailMessageId,
    auditString: audit.auditString,
    emailSubject: audit.emailSubject,
    emails: audit.emails,
    excludedModels: audit.excludedModels,
    type: 'ConfigurationActivityRest',
  }
}
