import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const runningProcessesFieldMap = () => ({
  process_component: {
    title: __mtc('process_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'processName',
        name: __mtc('process_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'pId',
        name: __mtc('pid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'command',
        name: __mtc('command'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'keyParameters',
        name: __mtc('key_parameters'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'pPid',
        name: __mtc('ppid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'listeningOn',
        name: __mtc('listening_on'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'connectingTo',
        name: __mtc('connecting_to'),
        inputType: 'text',
      },
    ],
  },
})
