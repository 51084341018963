<template>
  <MPopover
    ref="popoverRef"
    placement="bottomRight"
    :overlay-style="{ width: '350px' }"
    @show="handleOpen"
  >
    <template v-slot:trigger>
      <slot name="trigger">
        <MButton outline>
          {{ buttonText }}
          <MIcon class="mx-1" name="chevron-down" />
        </MButton>
      </slot>
    </template>
    <h5 class="text-primary">
      {{ header }}
    </h5>
    <FlotoForm @submit="handleFormSubmit">
      <FlotoFormItem
        v-if="takeEmails"
        :label="$tc('email_plural', 2)"
        rules="required|min:1"
      >
        <FlotoTagsPicker v-model="data.emails" type="email" />
      </FlotoFormItem>
      <FlotoFormItem v-if="currentOptions.length" :label="$tc('format', 2)">
        <ExportFormat v-model="data.exportFormat" :options="currentOptions" />
      </FlotoFormItem>
      <FlotoFormItem v-if="takeAttachment" :label="__t('export_report')">
        <MRadioGroup v-model="data.type" :options="attachmentOptions" />
      </FlotoFormItem>
      <template
        v-if="
          ['pdf', 'excel', 'word'].indexOf(data.exportFormat) >= 0 &&
          allowPasswordProtected
        "
      >
        <FlotoFormItem
          id="enabled-switch"
          :label="`${$tc('password')} ${$tc('protected')}`"
        >
          <MSwitch v-model="data.enabled" size="small" class="mr-2" />
        </FlotoFormItem>
        <PasswordInput
          v-if="data.enabled"
          v-model="data.protectedPassword"
          :validation-label="`${$tc('attachment')} ${$tc('password')}`"
          :rules="{ required: !attachmentPassword }"
          :label="`${$tc('attachment')} ${$tc('password')}`"
          :placeholder="`${$tc('attachment')} ${$tc('password')}`"
          :enabled-encryption="false"
          class="mb-2"
        >
          <template v-slot:prefix>
            <MIcon name="unlock-alt" />
          </template>
        </PasswordInput>
        <span v-if="attachmentPassword && data.enabled" class="md-text-xs mb-2">
          {{ $tc('attachment_password_info') }}
        </span>
      </template>

      <template v-slot:submit>
        <MDivider />
        <div class="flex justify-end">
          <MButton type="submit" :loading="processing">
            {{ buttonText }}
          </MButton>
          <MButton class="mx-1" variant="default" @click="hide">
            {{ $t('cancel') }}
          </MButton>
        </div>
      </template>
    </FlotoForm>
  </MPopover>
</template>

<script>
import Moment from 'moment'
import ExportFormat from '@components/export-format'
import PasswordInput from '@components/password-input'
import { PreferenceComputed } from '@state/modules/preference'

export default {
  name: 'ExportButton',
  components: { ExportFormat, PasswordInput },
  props: {
    buttonText: { type: String, required: true },
    header: { type: String, required: true },
    takeEmails: { type: Boolean, default: false },
    takeAttachment: { type: Boolean, default: false },
    // eslint-disable-next-line
    allowPasswordProtected: { type: Boolean, default: true },
    options: { type: Array, default: undefined },
    defaultData: {
      type: Object,
      default() {
        return {}
      },
    },
    downloadFn: { type: Function, default: undefined },
    fileName: { type: String, default: 'Download' },
  },
  data() {
    this.extension = {
      pdf: 'pdf',
      excel: 'xlsx',
      csv: 'csv',
      word: 'word',
    }
    this.currentOptions = this.options
      ? this.options
      : [
          { text: this.$t('pdf'), value: 'pdf' },
          { text: this.$t('excel'), value: 'excel' },
          { text: this.$t('csv'), value: 'csv' },
          { text: this.$t('word'), value: 'word' },
        ]
    return {
      processing: false,
      data: {
        emails: [],
        exportFormat: 'pdf',
        type: 'attachment',
        enabled: false,
        protectedPassword: '',
      },
    }
  },
  computed: {
    ...PreferenceComputed,
    attachmentOptions() {
      return [
        { text: this.__t('attachment'), value: 'attachment' },
        { text: this.__t('attachment_link'), value: 'link' },
      ]
    },
  },
  methods: {
    handleOpen() {
      this.data = {
        emails: [],
        exportFormat: 'pdf',
        type: 'attachment',
        enabled: false,
        protectedPassword: '',
        ...(this.defaultData || {}),
      }
    },
    handleFormSubmit() {
      if (this.downloadFn) {
        this.processing = true
        return this.downloadFn(
          this.data,
          this.extension[this.data.exportFormat]
        )
          .then((data) => {
            this.processing = false
            import('file-saver').then((FS) =>
              FS.saveAs(
                data,
                `${this.fileName}_${Moment().format('D-M-YYYY_H-m-s_a')}.${
                  this.extension[this.data.exportFormat]
                }`
              )
            )
            this.hide()
            return data
          })
          .catch(() => {
            this.processing = false
            this.hide()
          })
      } else {
        this.hide()
        setTimeout(() => {
          this.$emit(
            'submit',
            this.data,
            this.extension[this.data.exportFormat]
          )
        }, 100)
      }
    },
    hide() {
      this.processing = false
      this.$refs.popoverRef.hide()
    },
  },
}
</script>
