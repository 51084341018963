import Constants from '@constants'
import { isLoggedIn, isPortalLogin } from '@utils/auth'
import { nestFields } from '@data/form'
import { customFieldsForAllAssetType } from '@modules/asset/helpers/asset-custom-fields'
import { getFormApi } from './api'

function getFormModule(moduleName) {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    loading: true,
    form: {
      fields: [],
    },
  }

  vuexModule.getters = {
    form(state) {
      return Object.assign({}, state.form, {
        fields: nestFields(state.form.fields),
      })
    },
    loading(state) {
      return state.loading
    },
    attachmentFields(state) {
      return (state.form.fields || []).filter(
        (field) => field.type === 'attachment'
      )
    },
    hiddenFields(state) {
      return (state.form.fields || [])
        .filter((field) => field.markAsHidden)
        .map((f) => f.paramName)
    },
    portalFields(state) {
      return nestFields((state.form.fields || []).filter((f) => f.useOnPortal))
    },
    portalEditableFields(state) {
      return nestFields(
        (state.form.fields || []).filter(
          (f) =>
            f.requesterCanEdit ||
            (f.useOnPortal && f.attributes.systemManagedField) ||
            f.type === 'label' ||
            f.type === 'section' ||
            f.type === 'api' ||
            f.type === 'dynamic'
        )
      )
    },
    getFields(state) {
      return (
        args = {
          exclude: ['system'],
          only: [],
          excludeEmptySection: false,
          stages: [],
        }
      ) => {
        let fields = state.form.fields || []
        if (fields.length <= 0) {
          return fields
        }
        if (args.exclude && args.exclude.includes('system')) {
          // exclude system field
          fields = fields.filter((f) => f.isSystemField !== true)
        }
        if (args.exclude && args.exclude.length) {
          fields = fields.filter((f) => {
            if (f.inputType && args.exclude.includes(f.inputType)) {
              return false
            }
            if (f.type && args.exclude.includes(f.type)) {
              return false
            }
            return true
          })
        }
        if (args.only && args.only.length) {
          fields = fields.filter(
            (f) =>
              args.only.includes(f.type) ||
              (f.inputType && args.only.includes(f.inputType))
          )
        }
        if (args.excludeEmptySection) {
          fields = fields.filter(
            (f, index, allFields) =>
              f.type !== 'section' ||
              allFields.filter((subField) => subField.sectionId === f.id).length
          )
        }
        if (args.stages && args.stages.length) {
          fields = fields.filter((f) => args.stages.includes(f.stage))
        }
        return nestFields(fields)
      }
    },
    assetFields(state, getters) {
      return (
        args = {
          exclude: ['system'],
          only: [],
          excludeEmptySection: false,
          stages: [],
        }
      ) => {
        const fields = getters
          .getFields(args)
          .filter((f) => f.attributes.model === Constants.ASSET)
        return customFieldsForAllAssetType(fields, Constants.ASSET)
      }
    },
    cmdbFields(state, getters) {
      return (
        args = {
          exclude: ['system'],
          only: [],
          excludeEmptySection: false,
          stages: [],
        }
      ) => {
        const fields = getters
          .getFields(args)
          .filter((f) => f.attributes.model === Constants.CMDB)
        return customFieldsForAllAssetType(fields, Constants.CMDB)
      }
    },
  }

  vuexModule.mutations = {
    SET_FORM(state, data) {
      state.loading = false
      state.form = data
    },
  }

  vuexModule.actions = {
    init({ dispatch }) {
      if (isLoggedIn()) {
        dispatch('fetch')
      }
    },

    fetch({ commit, rootGetters }, params) {
      const availableModules = rootGetters['license/availableModules']
      if (
        [
          ...availableModules,
          Constants.USER,
          Constants.FEEDBACK,
          Constants.MSP_COMPANY,
          Constants.TASK,
          ...(availableModules.indexOf(Constants.ASSET) >= 0
            ? [Constants.SOFTWARE_LICENSE]
            : []),
          ...(moduleName === Constants.ASSET &&
          availableModules.indexOf(Constants.CMDB) >= 0
            ? [Constants.ASSET]
            : []),
        ].indexOf(moduleName) >= 0
      ) {
        getFormApi(moduleName, isPortalLogin(), params).then((data) => {
          commit('SET_FORM', Object.freeze(data))
        })
      }
    },

    /**
     * destroy all states when user is logged out
     */
    destroy({ commit }) {
      commit('SET_FORM', {})
    },
  }
  return vuexModule
}

export const modules = {
  request: getFormModule(Constants.REQUEST),
  problem: getFormModule(Constants.PROBLEM),
  change: getFormModule(Constants.CHANGE),
  release: getFormModule(Constants.RELEASE),
  asset: getFormModule(Constants.ASSET),
  user: getFormModule(Constants.USER),
  feedback: getFormModule(Constants.FEEDBACK),
  product: getFormModule(Constants.PRODUCT),
  vendor: getFormModule(Constants.VENDOR),
  contract: getFormModule(Constants.CONTRACT),
  purchase: getFormModule(Constants.PURCHASE),
  project: getFormModule(Constants.PROJECT),
  company: getFormModule(Constants.MSP_COMPANY),
  task: getFormModule(Constants.TASK),
  software_license: getFormModule(Constants.SOFTWARE_LICENSE),
}
