import { getRootPluaralTranslator } from '@utils/get-module-translator'

const __rootTc = getRootPluaralTranslator()

export const requesterEmailField = () => {
  return {
    id: 5,
    type: 'SystemFieldRest',
    isSystemField: true,
    // refactor requester_email to requester
    paramName: 'requester',
    inputType: 'requester',
    guid: 'ef44f47a-7f88-409c-a7a0-f18d53ccf6cb',
    title: __rootTc('requester'),
    order: 2,
    required: true,
    useOnPortal: true,
    requesterRequired: true,
    sectionId: 0,
    canDelete: false,
    canUpdate: true,
    stage: '__vue_devtool_undefined__',
    percentageField: '__vue_devtool_undefined__',
    hidden: false,
  }
}

export const requesterField = () => {
  return {
    id: 5,
    type: 'SystemFieldRest',
    isSystemField: true,
    // refactor requester_email to requester
    paramName: 'requesterId',
    inputType: 'requesterid',
    guid: 'ef44f47a-7f88-409c-a7a0-f18d53ccf6cb',
    title: __rootTc('requester'),
    order: 2,
    required: true,
    useOnPortal: true,
    requesterRequired: true,
    sectionId: 0,
    canDelete: false,
    canUpdate: true,
    stage: '__vue_devtool_undefined__',
    percentageField: '__vue_devtool_undefined__',
    hidden: false,
  }
}

export const requestStatusField = () => {
  return {
    id: 99999,
    moduleName: 'request',
    type: 'SystemFieldRest',
    isSystemField: true,
    paramName: 'statusId',
    inputType: 'status',
    guid: '1fb3b5dc-4808-478c-b720-d4ddc708f4e8',
    title: 'Status',
    attributes: { widthClass: 'w-full', inputWidth: 40 },
    order: 6,
    required: false,
    markAsHidden: false,
    useOnPortal: false,
    requesterGroups: [],
    sectionId: 0,
    canDelete: false,
    canUpdate: false,
    requesterCanEdit: false,
    requesterRequired: false,
    hidden: false,
    fields: [],
  }
}

export const changeExteranSystemField = () => [
  {
    id: 999991,
    moduleName: 'change',
    type: 'SystemFieldRest',
    isSystemField: true,
    paramName: 'statusId',
    inputType: 'status',
    guid: '1fb3b5dc-4808-478c-b720-d4ddc708f4e8',
    title: 'Status',
    attributes: { widthClass: 'w-full', inputWidth: 40 },
    order: 6,
    required: false,
    markAsHidden: false,
    useOnPortal: false,
    requesterGroups: [],
    sectionId: 0,
    canDelete: false,
    canUpdate: false,
    requesterCanEdit: false,
    requesterRequired: false,
    hidden: false,
    fields: [],
  },
  {
    id: 999992,
    moduleName: 'change',
    type: 'SystemFieldRest',
    isSystemField: true,
    paramName: 'changeImplementor',
    inputType: 'technician',
    guid: '1fb3b5dc-4808-478c-b720-d4ddc708f4f8',
    title: 'Implementor',
    attributes: { widthClass: 'w-full', inputWidth: 40 },
    order: 6,
    required: false,
    markAsHidden: false,
    useOnPortal: false,
    requesterGroups: [],
    sectionId: 0,
    canDelete: false,
    canUpdate: false,
    requesterCanEdit: false,
    requesterRequired: false,
    hidden: false,
    fields: [],
  },
  {
    id: 999993,
    moduleName: 'change',
    type: 'SystemFieldRest',
    isSystemField: true,
    paramName: 'changeReviewer',
    inputType: 'technician',
    guid: '1fb3b5dc-4808-478c-b720-d4ddc708f4g8',
    title: 'Reviewer',
    attributes: { widthClass: 'w-full', inputWidth: 40 },
    order: 6,
    required: false,
    markAsHidden: false,
    useOnPortal: false,
    requesterGroups: [],
    sectionId: 0,
    canDelete: false,
    canUpdate: false,
    requesterCanEdit: false,
    requesterRequired: false,
    hidden: false,
    fields: [],
  },
  {
    id: 999994,
    moduleName: 'change',
    type: 'SystemFieldRest',
    isSystemField: true,
    paramName: 'changeManager',
    inputType: 'technician',
    guid: '1fb3b5dc-4808-478c-b720-d4ddc708f4h8',
    title: 'Manager',
    attributes: { widthClass: 'w-full', inputWidth: 40 },
    order: 6,
    required: false,
    markAsHidden: false,
    useOnPortal: false,
    requesterGroups: [],
    sectionId: 0,
    canDelete: false,
    canUpdate: false,
    requesterCanEdit: false,
    requesterRequired: false,
    hidden: false,
    fields: [],
  },
]

export const assetExteranSystemField = () => [
  {
    id: 999991,
    moduleName: 'asset',
    type: 'SystemFieldRest',
    isSystemField: true,
    paramName: 'assetTypeId',
    inputType: 'assetType',
    guid: '1fb3b5dc-4808-478c-b720-d4ddc708f4i9',
    title: 'Asset Type',
    attributes: { widthClass: 'w-full', inputWidth: 40 },
    order: 6,
    required: false,
    markAsHidden: false,
    useOnPortal: false,
    requesterGroups: [],
    sectionId: 0,
    canDelete: false,
    canUpdate: false,
    requesterCanEdit: false,
    requesterRequired: false,
    hidden: false,
    fields: [],
  },
]

export const passwordConfirmPasswordSystemFiled = () => [
  {
    id: 9999999,
    moduleName: 'user',
    type: 'SystemFieldRest',
    isSystemField: true,
    paramName: 'password',
    inputType: 'password',
    guid: '13149ac5-209c-4274-a75c-a6c0c9af376e',
    title: 'Password',
    attributes: {
      widthClass: 'w-1/2',
      inputWidth: 100,
    },
    order: 2,
    required: true,
    markAsHidden: false,
    useOnPortal: false,
    requesterGroups: [],
    sectionId: 0,
    canDelete: false,
    canUpdate: true,
    requesterCanEdit: false,
    requesterRequired: true,
    hidden: false,
    fields: [],
  },
  {
    id: 99999999,
    moduleName: 'user',
    type: 'SystemFieldRest',
    isSystemField: true,
    paramName: 'confirmPassword',
    inputType: 'confirmPassword',
    guid: '13149ac5-209c-4274-a75c-a6c0c9af3780',
    title: 'Confirm Password',
    attributes: {
      widthClass: 'w-1/2',
      inputWidth: 100,
    },
    order: 2,
    required: true,
    markAsHidden: false,
    useOnPortal: false,
    requesterGroups: [],
    sectionId: 0,
    canDelete: false,
    canUpdate: true,
    requesterCanEdit: false,
    requesterRequired: true,
    hidden: false,
    fields: [],
  },
]
