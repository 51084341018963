import SortBy from 'lodash/sortBy'
import Constants from '@constants'
import { getRootPluaralTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import {
  transformQualificationForServer,
  transformQualification,
} from './qualification'
import { transformValueTypeForServer, transformValueType } from './value-type'

const rootTc = getRootPluaralTranslator()

export function ApprovalTypeOptions() {
  return [
    { text: rootTc('unanimous'), value: 'unanimous' },
    { text: rootTc('majority'), value: 'majority' },
    { text: rootTc('anyone'), value: 'anyone' },
    { text: rootTc('first_approval'), value: 'first_approval' },
  ]
}

export function transformApprovalWorkflow(approval) {
  return {
    id: approval.id,
    name: approval.name,
    moduleName: approval.refModel,
    assetTypeModuleName: approval.assetTypeModel,
    ...(approval.refId ? { parentId: approval.refId } : {}),
    qualifications: transformQualification(approval.qualification),
    actions: SortBy(approval.approvalActionStages || [], 'stage').map(
      transformApprovalAction
    ),
    ...(approval.workflowCategory
      ? {
          workflowCategory: approval.workflowCategory,
          ...(approval.workflowCategory === Constants.MAKER_CHECKER
            ? {
                moduleName: Constants.ASSET_UPDATE,
              }
            : {}),
        }
      : {}),
  }
}

function transformApprovalActionForServer(action, index) {
  let actionSet = []
  if (action.groupId && action.groupId !== 0) {
    actionSet.push({
      customField: false,
      fieldValue: {
        type: 'LongValueRest',
        value: action.groupId || 0,
      },
      setFieldActionType: 'to',
      fieldName: 'groupId',
      type: 'SetFieldActionRest',
      description: action.technicianGroupDescription,
    })
  }
  if (action.technicianId && action.technicianId !== 0) {
    actionSet.push({
      customField: false,
      fieldValue: {
        type: 'LongValueRest',
        value: action.technicianId || 0,
      },
      setFieldActionType: 'to',
      fieldName: 'technicianId',
      type: 'SetFieldActionRest',
      description: action.technicianDescription,
    })
  }
  if (
    (!action.groupId || action.groupId === 0) &&
    (!action.technicianId || action.technicianId === 0)
  ) {
    actionSet = []
  }

  return {
    stage: index + 1,
    subject: action.subject,
    description: action.description,
    approvalType: action.approvalType,
    groupIds: action.requesterGroups,
    fromRequesterGroup: {
      type: 'ListFieldValueRest',
      value: (action.fromRequesterGroup || []).map(
        (i) => transformValueTypeForServer('field', i).value
      ),
    },
    technicianGroupIds: action.technicianGroups,
    fromTechnicianGroup: {
      type: 'ListFieldValueRest',
      value: (action.fromTechnicianGroup || []).map(
        (i) => transformValueTypeForServer('field', i).value
      ),
    },
    approverIds: action.users,
    fromUsers: {
      type: 'ListFieldValueRest',
      value: (action.fromUsers || []).map(
        (i) => transformValueTypeForServer('field', i).value
      ),
    },
    includeAssetUser: action.includeAssetUser,
    includeManagerUser: action.includeManagerUser,
    includeAssigneeManagerUser: action.includeAssigneeManagerUser,
    includeDepartmetHead: action.includeDepartmetHead,
    id: action.id,
    actions: actionSet,
  }
}

function getActionValue(field, actionSet) {
  const i = actionSet.filter((i) => i.fieldName === field)
  return (i || []).length > 0 ? i[0].fieldValue.value : 0
}

function transformApprovalAction(action) {
  return {
    guid: generateId(),
    subject: action.subject,
    description: action.description,
    approvalType: action.approvalType,
    requesterGroups: action.groupIds,
    fromRequesterGroup: ((action.fromRequesterGroup || {}).value || []).map(
      (i) => transformValueType(i).value
    ),
    technicianGroups: action.technicianGroupIds,
    fromTechnicianGroup: ((action.fromTechnicianGroup || {}).value || []).map(
      (i) => transformValueType(i).value
    ),
    users: action.approverIds,
    fromUsers: ((action.fromUsers || {}).value || []).map(
      (i) => transformValueType(i).value
    ),
    includeAssetUser: action.includeAssetUser,
    includeManagerUser: action.includeManagerUser,
    includeAssigneeManagerUser: action.includeAssigneeManagerUser,
    includeDepartmetHead: action.includeDepartmetHead,
    id: action.id,
    ...(action.actions && action.actions.length >= 0
      ? {
          groupId: getActionValue('groupId', action.actions || []),
          technicianId: getActionValue('technicianId', action.actions || []),
        }
      : {
          groupId: 0,
          technicianId: 0,
        }),
  }
}

export function transformApprovalWorkflowForServer(approval) {
  return {
    name: approval.name,
    qualification: transformQualificationForServer(approval.qualifications),
    approvalActionStages: approval.actions.map((action, index) =>
      transformApprovalActionForServer(action, index)
    ),
    ...(approval.assetTypeModuleName
      ? { assetTypeModel: approval.assetTypeModuleName }
      : {}),
    ...(approval.workflowCategory
      ? { workflowCategory: approval.workflowCategory }
      : {}),
  }
}

export function transformApprovalSettings(settings) {
  return {
    approvalType: settings.approvalType,
    allowToCreateManualApproval: settings.approvalManualCreateAllowed,
    noteRequiredToReject: settings.approvalNoteRequiredForRejection,
    requestStatus: settings.approvalRequestDefaultStatus,
    requestApprovedStatus: settings.approvalRequestOnApprovedStatus,
    requestRejectedStatus: settings.approvalRequestOnRejectStatus,
    requestReferredBackStatus: settings.approvalRequestOnReferredBackStatus,
    problemStatus: settings.approvalProblemDefaultStatus,
    problemApprovedStatus: settings.approvalProblemOnApprovedStatus,
    problemRejectedStatus: settings.approvalProblemOnRejectStatus,
    problemReferredBackStatus: settings.approvalProblemOnReferredBackStatus,
    assetStatus: settings.approvalAssetDefaultStatus,
    assetApprovedStatus: settings.approvalAssetOnApprovedStatus,
    assetRejectedStatus: settings.approvalAssetOnRejectStatus,
    assetReferredBackStatus: settings.approvalAssetOnReferredBackStatus,
    ciStatus: settings.approvalCmdbDefaultStatus,
    ciApprovedStatus: settings.approvalCmdbOnApprovedStatus,
    ciRejectedStatus: settings.approvalCmdbOnRejectStatus,
    ciReferredBackStatus: settings.approvalCmdbOnReferredBackStatus,
    allowAddNoteOnApprovingRejectingApproval:
      settings.allowAddNoteOnApprovingRejectingApproval,
    skipRequesterAsApprover: settings.skipRequesterAsApprover,
    allowToUpdateSRFieldAfterApproval:
      settings.allowToUpdateSRFieldAfterApproval,
    responseRequiredFromAllApprovers: settings.responseRequiredFromAllApprovers,
    decisionTypesForResponseRequiredFromAllApprovers:
      settings.decisionTypesForResponseRequiredFromAllApprovers,
  }
}

export function transformApprovalSettingsForServer(settings) {
  return {
    approvalType: settings.approvalType,
    approvalManualCreateAllowed: settings.allowToCreateManualApproval,
    approvalNoteRequiredForRejection: settings.noteRequiredToReject,
    approvalRequestDefaultStatus: settings.requestStatus,
    approvalRequestOnApprovedStatus: settings.requestApprovedStatus,
    approvalRequestOnRejectStatus: settings.requestRejectedStatus,
    approvalRequestOnReferredBackStatus: settings.requestReferredBackStatus,
    approvalProblemDefaultStatus: settings.problemStatus,
    approvalProblemOnApprovedStatus: settings.problemApprovedStatus,
    approvalProblemOnRejectStatus: settings.problemRejectedStatus,
    approvalProblemOnReferredBackStatus: settings.problemReferredBackStatus,
    approvalAssetDefaultStatus: settings.assetStatus,
    approvalAssetOnApprovedStatus: settings.assetApprovedStatus,
    approvalAssetOnRejectStatus: settings.assetRejectedStatus,
    approvalAssetOnReferredBackStatus: settings.assetReferredBackStatus,
    approvalCmdbDefaultStatus: settings.ciStatus,
    approvalCmdbOnApprovedStatus: settings.ciApprovedStatus,
    approvalCmdbOnRejectStatus: settings.ciRejectedStatus,
    approvalCmdbOnReferredBackStatus: settings.ciReferredBackStatus,
    allowAddNoteOnApprovingRejectingApproval:
      settings.allowAddNoteOnApprovingRejectingApproval,
    skipRequesterAsApprover: settings.skipRequesterAsApprover,
    allowToUpdateSRFieldAfterApproval:
      settings.allowToUpdateSRFieldAfterApproval,
    responseRequiredFromAllApprovers: settings.responseRequiredFromAllApprovers,
    decisionTypesForResponseRequiredFromAllApprovers:
      settings.decisionTypesForResponseRequiredFromAllApprovers,
  }
}

export function transformApprovalForServer(approval) {
  return {
    subject: approval.subject,
    approvalStages: approval.actions.map((action, index) =>
      transformApprovalActionForServer(action, index)
    ),
    description: approval.description,
    approvalType: approval.approvalType,
    groupIds: approval.requesterGroups,
    approverIds: approval.users,
    runWorkFlow: approval.runWorkFlow,
  }
}

export function transformApproval(approval, index) {
  return {
    id: approval.id,
    subject: approval.subject,
    stageStatus: approval.stageStatus,
    activeStage: approval.currentStage,
    totalStage: approval.totalStage,
    approvalType: approval.approvalType,
    status: approval.approvalStatus,
    manual: approval.approvalWorkflowId === 0,
    createdAt: approval.createdTime,
    owner: approval.createdById,
    runWorkFlow: approval.runWorkFlow,
    archived: approval.removed,
    makerCheckerApproval: approval.makerCheckerApproval,
    approvalAskOnStatusId: approval.approvalAskOnStatusId,
    refModel: approval.refModel,
  }
}

export function transformApprovalStage(stage) {
  return {
    id: stage.id,
    subject: stage.subject,
    description: stage.description,
    status: stage.approvalStatus,
    approvalType: stage.approvalType,
    stats: {
      approved: stage.statusCount.approved,
      rejected: stage.statusCount.rejected,
      pending: stage.statusCount.pending,
      ignored: stage.statusCount.ignored,
      total: stage.totalApproval,
    },
    makerCheckerApproval: stage.makerCheckerApproval,
    approvalId: stage.approvalId,
  }
}

export function transformApprover(approver) {
  return {
    id: approver.id,
    email: approver.approverEmail,
    name: approver.approverName,
    approvalRequesterName:
      approver.approvalCreatorName || approver.approverName,
    approvalRequesterId: approver.approvalCreatorId || approver.createdById,
    status: approver.approverStatus,
    stageId: approver.refId,
    parentResource: approver.approvalModel,
    parentResourceId: approver.approvalParentId,
    updatedBy: approver.updatedById,
    updatedAt: approver.updatedTime,
    createdById: approver.createdById,
    userId: approver.userId,
    approvedUserId: approver.approvedUserId,
    makerCheckerApproval: approver.makerCheckerApproval,
    approvalId: approver.approvalId,
    approvalAskOnStatusId: approver.approvalAskOnStatusId,
    archived: approver.removed,
  }
}

export function transformApprovalAnalytics(analytics) {
  return {
    status: analytics.approvalStatus,
    stats: {
      approved: analytics.statusCount.approved,
      rejected: analytics.statusCount.rejected,
      pending: analytics.statusCount.pending,
      total: analytics.totalApproval,
      ignored: analytics.statusCount.ignored || 0,
      needed: analytics.totalApprovalNeeded || 0,
    },
    approvalType: analytics.approvalType,
  }
}

export function transformMyApproval(approval) {
  return {
    id: approval.approverId,
    name: approval.name,
    approvalRequesterName:
      approval.approvalCreatorName || approval.requesterName,
    email: approval.approverEmail,
    approverId: approval.approverId,
    approvalId: approval.approvalId,
    parentId: approval.refId,
    subject: approval.subject,
    description: approval.description,
    status: approval.status,
    createdAt: approval.createdDate,
    updatedAt: approval.updatedDate,
    updatedBy: approval.updatedById,
    approvalType: approval.approvalType,
    module: approval.refModel,
    approvedUserId: approval.approvedUserId,
    requesterId: approval.requesterId,
    makerCheckerApproval: approval.makerCheckerApproval,
  }
}
