import api from '@api'
import { transformCurrency } from '@data/currency'

export function fetchCurrencyApi(params) {
  return api
    .get(
      '/public/currency/enabled',
      {},
      {
        params: {
          offset: 0,
          size: 1000,
        },
        notify: false,
      }
    )
    .then((data) => {
      return (data.objectList || []).map(transformCurrency)
    })
}

export function fetchAllCurrencyApi(params) {
  return api
    .get(
      '/public/currency',
      {},
      {
        params: {
          offset: 0,
          size: 1000,
        },
        notify: false,
      }
    )
    .then((data) => {
      return (data.objectList || []).map(transformCurrency)
    })
}
