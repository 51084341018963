var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FlotoCrudContainer',_vm._b({ref:"list",attrs:{"as-table":"","scrollable":_vm.searchable || _vm.scrollable,"module-name":_vm.moduleName,"immediate":!_vm.searchable,"fetch-fn":_vm.fetchTicketFn,"columns":_vm.tableColumns,"selectable":_vm.selectable},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}},scopedSlots:_vm._u([(_vm.searchable)?{key:"add-controls",fn:function({ refreshList }){return [_c('MRow',{staticClass:"items-center"},[_c('MCol',[_c('h2',{staticClass:"font-normal inline-block mb-0"},[_vm._v(" "+_vm._s(_vm.$tc(_vm.moduleName, 2))+" ")]),_c('FlotoSearchSelector',{attrs:{"exclude-archived-spam-qual":"","allow-pin":false,"persist-search":false,"module-name":_vm.moduleName},model:{value:(_vm.currentSelectedSearch),callback:function ($$v) {_vm.currentSelectedSearch=$$v},expression:"currentSelectedSearch"}})],1)],1),_c('MRow',{staticClass:"my-4"},[_c('MCol',{class:{
          invisible:
            ((_vm.currentSelectedSearch || {}).systemName || '').indexOf(
              'spam'
            ) >= 0 ||
            ((_vm.currentSelectedSearch || {}).systemName || '').indexOf(
              'archive'
            ) >= 0 ||
            ((_vm.currentSelectedSearch || {}).systemName || '') ===
              'requests watched by me',
        }},[_c('FlotoSearchBar',{attrs:{"default-value":_vm.currentSelectedSearch ? _vm.currentSelectedSearch.qualifications : [],"should-notify-on-default-change":true,"allow-save-search":false,"persist-criteria":false,"module-name":_vm.moduleName},on:{"change":refreshList},model:{value:(_vm.searchCriteria),callback:function ($$v) {_vm.searchCriteria=$$v},expression:"searchCriteria"}})],1)],1)]}}:null,{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"subject",fn:function({ item }){return [_c('td',[_c('ResourceListTitle',_vm._b({attrs:{"name":item.name,"subject":item.subject,"requester-name":(item.requesterData || {}).name,"requester-email":(item.requesterData || {}).email,"created-at":item.createdAt,"open-in-new-tab":"","link":_vm.$modules.getModuleRoute('ticket', 'view', {
            params: { id: item.id, ticketType: _vm.moduleName },
          })}},'ResourceListTitle',_vm.$attrs,false))],1)]}},(_vm.hiddenColumns.indexOf('assigned_to') < 0)?{key:"assigned_to",fn:function({ item }){return [_c('td',[_c('FlotoTechnicianPicker',{attrs:{"value":item.technicianId,"disabled":""}})],1)]}}:null,{key:"status",fn:function({ item }){return [_c('td',[_c('FlotoStatusPicker',{attrs:{"module-name":_vm.moduleName,"placeholder":"---","value":item.statusId,"disabled":""}})],1)]}},{key:"priority",fn:function({ item }){return [_c('td',[_c('FlotoPriorityPicker',{attrs:{"value":item.priorityId,"placeholder":"---","disabled":""}})],1)]}},(_vm.hiddenColumns.indexOf('due_by') < 0)?{key:"due_by",fn:function({ item }){return [_c('td',[_c('FlotoDueDatePicker',{attrs:{"value":item.dueBy,"disabled":"","module-name":_vm.moduleName,"status-id":item.statusId}})],1)]}}:null,{key:"actions",fn:function({ item }){return [_c('td',{class:{
        'sticky-action': _vm.actionSticked.indexOf(item.id) >= 0,
        'hover-action-container': _vm.hoverableActions,
      }},[_vm._t("action",null,{"item":item,"stickAction":() => _vm.stickActionFor(item),"unstickAction":() => _vm.unStickActionFor(item)})],2)]}},{key:"default",fn:function(slotData){return [_vm._t("default",null,null,slotData)]}}],null,true)},'FlotoCrudContainer',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }