var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MPopover',_vm._g(_vm._b({ref:"dropdownPopoverRef",attrs:{"placement":_vm.popoverPlacment,"disabled":_vm.disabled,"transition-name":"slide-up","overlay-class-name":"picker-overlay reqeuster-email-overlay","overlay-style":{
    opacity: _vm.options.length ? 1 : 0,
  }},on:{"hide":_vm.handleHide,"show":_vm.handleShow},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._t("trigger",function(){return [_c('MInput',{attrs:{"value":_vm.searchText,"disabled":_vm.disabled,"auto-focus":_vm.autoFocus,"placeholder":_vm.placeholder},on:{"blur":function($event){return _vm.$emit('blur', _vm.searchText)},"update":_vm.handleChange}})]},{"hide":_vm.handleHide,"show":_vm.handleShow,"toggle":_vm.toggleDropdown})]},proxy:true}],null,true)},'MPopover',_vm.$attrs,false),_vm.listeners),[_c('div',{staticClass:"flex flex-col h-100 min-h-0"},[_c('div',{ref:"scrollContainer",staticClass:"flex h-100 min-h-0 flex-col"},[_vm._t("before-menu"),(_vm.isLargDataSet)?_c('DynamicScroller',{staticClass:"scroller",attrs:{"items":_vm.optionsToDisplay,"min-item-size":54,"key-field":'key',"emit-update":false},scopedSlots:_vm._u([{key:"default",fn:function({ item, index, active }){return [_c('DynamicScrollerItem',{class:{
              'scroll-dropdown-menu-item': true,
              [_vm.menuItemClass]: true,
              [_vm.menuItemSelectedClass]:
                index === _vm.currentIndex || active === index,
              [_vm.menuItemDisabledClass]: item.disabled,
              'value-active-item': Array.isArray(_vm.value)
                ? _vm.value.indexOf(item.key) >= 0
                : _vm.value === item.key,
            },attrs:{"item":item,"active":active,"size-dependencies":[item.text || item.name || item.label],"data-index":index,"data-active":active}},[_vm._t("menu-item",function(){return [_c('div',{staticClass:"dropdown-item flex items-center",class:{ 'font-semibold': _vm.searchQuery },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item)}}},[(item.color)?_c('FlotoDot',{attrs:{"bg":item.color}}):_vm._e(),_c('span',{staticClass:"mx-1 text-ellipsis",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(item.text || item.name || item.label)+" ")])],1)]},{"item":item,"selectItem":_vm.selectItem})],2)]}}],null,true)}):_c('FlotoScrollView',[_vm._t("default",function(){return [(_vm.options.length && _vm.loggedIn)?_c('MMenu',{ref:"menuContainer",class:_vm.menuClass},_vm._l((_vm.optionsToDisplay),function(item,index){return _c('MMenuItem',{key:String(item.id || item.value || item.key),class:{
                'scroll-dropdown-menu-item': true,
                [_vm.menuItemClass]: true,
                [_vm.menuItemSelectedClass]: index === _vm.currentIndex,
                [_vm.menuItemDisabledClass]: item.disabled,
                'value-active-item': Array.isArray(_vm.value)
                  ? _vm.value.indexOf(item.key) >= 0
                  : _vm.value === item.key,
              }},[_vm._t("menu-item",function(){return [_c('div',{staticClass:"text-ellipsis",on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item)}}},[_vm._v(" "+_vm._s(item.text || item.label || item.name)+" ")])]},{"item":item,"selectItem":_vm.selectItem})],2)}),1):_vm._e(),(_vm.showNoData && _vm.optionsToDisplay.length <= 0)?_vm._t("no-data",function(){return [(_vm.showNoData && _vm.optionsToDisplay.length <= 0)?_c('FlotoNoData',{attrs:{"size":"small"}}):_vm._e()]}):_vm._e()]})],2),_vm._t("after-menu")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }