<template>
  <MPopover
    ref="dropdownPopoverRef"
    :placement="popoverPlacment"
    :get-popup-container="popupContainer"
    v-bind="$attrs"
    :disabled="disabled"
    transition-name="slide-up"
    overlay-class-name="picker-overlay wide-picker-overlay"
    @hide="handleHide"
    @show="handleShow"
    v-on="listeners"
  >
    <template v-slot:trigger>
      <slot
        name="trigger"
        :show="handleShow"
        :hide="handleHide"
        :toggle="handleToggle"
        :current-item="selectedItem"
        :is-open="isDropdownOpen"
      >
        <DropdownTrigger
          v-if="!multiple"
          :toggle="handleToggle"
          :selected-item="selectedItem"
          :allow-clear="allowClear"
          :focus-event-brodcast="focusEventBrodcast"
          :is-open="isDropdownOpen"
          :text-only="textOnly"
          :disabled="disabled"
          :as-input="asInput"
          :size="size"
          v-bind="$attrs"
          :input-classes="inputClasses"
          :placeholder="placeholder"
          @reset="handleChange(undefined)"
        >
          <template v-slot:prefix-text="{ item }">
            <slot name="trigger-prefix-text" :item="item"></slot>
          </template>
          <template
            v-if="fullPath && !multiple && value && displayFullPath"
            v-slot:bellow-input
          >
            <div
              class="text-neutral-light md-text-xs"
              style="word-break: break-all"
            >
              {{ displayFullPath }}
            </div>
          </template>
        </DropdownTrigger>
        <MultipleTrigger
          v-else
          :allow-clear="allowClear"
          :focus-event-brodcast="focusEventBrodcast"
          :is-open="isDropdownOpen"
          :selected-items="selectedItem"
          :disabled="disabled"
          v-bind="$attrs"
          :placeholder="placeholder"
          :options="flattenRecursive(options)"
          @change="handleChange"
        />
      </slot>
    </template>
    <div class="flex flex-col h-100 min-h-0">
      <div ref="scrollContainer" class="flex h-100 min-h-0 flex-col">
        <div v-if="searchable" class="my-2 px-2">
          <MInput
            ref="searchBox"
            v-model="searchTerm"
            :placeholder="$tc('search')"
          >
            <template v-slot:suffix>
              <MIcon name="search" />
            </template>
          </MInput>
        </div>
        <div
          v-if="multiple && selectedItem.length"
          class="mb-2 px-2"
          :class="{
            'dropdown-selected-items-container': selectedItem.length > 4,
          }"
        >
          <div class="text-primary mb-1">
            {{ $tc('selected') }} {{ $tc('item', 2) }}
          </div>
          <MultipleTrigger
            :allow-clear="false"
            :selected-items="selectedItem"
            disabled
            as-tag
            can-remove-selected-items-pill
            display-all-selected-items-pill
            :options="flattenRecursive(options)"
            :as-input="false"
            v-bind="$attrs"
            :input-classes="inputClasses"
            @change="handleChange"
          />
          <MDivider class="mb-0 mt-1" />
        </div>
        <slot name="before-menu"></slot>
        <InfiniteTree
          v-if="isLargDataSet && options.length"
          ref="infinieTreeRef"
          class="py-1 px-1 tree-list-div"
          :data="options"
          :search-term="searchTerm"
          :with-bg="false"
          :row-height="40"
          :node-fields="['name']"
          :value="selectedItem"
          :multiple="multiple"
          :only-leaf-node-selectable="onlyLeafNodeSelectable"
          :hidden-options-keys="hiddenOptionsKeys"
          :visible-options-keys="visibleOptionsKeys"
          @change="changeHandler"
        >
          <template v-slot="{ item }">
            <slot name="item" :item="item" :disabled="onlyLeafNodeSelectable">
              <div class="flex items-center">
                <FlotoDot v-if="item.color" class="mr-2" :bg="item.color" />
                <span>
                  <MTooltip placement="topLeft">
                    <template v-slot:trigger>
                      <span class="min-w-0 text-ellipsis">{{ item.name }}</span>
                    </template>
                    {{ item.name }}
                  </MTooltip>
                </span>
              </div>
            </slot>
          </template>
        </InfiniteTree>
        <FlotoScrollView v-if="!isLargDataSet && currentOptions.length">
          <div class="px-2 py-1 ml-5 tree-list-view">
            <TreeList
              :options="currentOptions"
              :value="value"
              :highlight-term="searchTerm"
              :multiple="multiple"
              :show-no-data="showNoData"
              :level-margin="levelMargin"
              :only-leaf-node-selectable="onlyLeafNodeSelectable"
              @change="handleChange"
            >
              <template v-slot:item="{ item, select }">
                <slot
                  name="item"
                  :item="item"
                  :select="select"
                  :disabled="onlyLeafNodeSelectable"
                >
                </slot>
              </template>
            </TreeList>
          </div>
        </FlotoScrollView>
        <slot name="after-menu"></slot>
      </div>
    </div>
  </MPopover>
</template>

<script>
import Bus from '@utils/emitter'
import InfiniteTree from '@components/hierarchy/infinite-tree.vue'
import {
  flattenRecursive,
  findValueObject,
  searchRecursive,
  collapseAll,
  expandeSelected,
  findValuePathWithItems,
} from '@data/recursive'
import { authComputed } from '@state/modules/auth'
import CloneDeep from 'lodash/cloneDeep'
import DropdownTrigger from '../dropdown-trigger.vue'
import MultipleTrigger from './multiple-trigger.vue'
import TreeList from './tree-list'

export default {
  name: 'TreePicker',
  components: { DropdownTrigger, MultipleTrigger, InfiniteTree, TreeList },
  model: { event: 'change' },
  props: {
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return this.$tc('select')
      },
    },
    inputClasses: { type: [Array, Object, String], default: undefined },
    textOnly: { type: Boolean, default: false },
    // eslint-disable-next-line
    allowClear: { type: Boolean, default: true },
    size: { type: String, default: undefined },
    asInput: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    value: { type: [Array, Number, Object, String], default: undefined },
    unassignedValue: { type: [String, Number], default: 0 },
    // eslint-disable-next-line
    showNoData: { type: Boolean, default: true },
    // eslint-disable-next-line
    isLargDataSet: { type: Boolean, default: true },
    levelMargin: { type: Number, default: 20 },
    onlyLeafNodeSelectable: { type: Boolean, default: false },
    fullPath: { type: Boolean, default: false },
    hiddenOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
    visibleOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
    focusEventBrodcast: { type: Boolean, default: false },
    validateArchivedValue: { type: Boolean, default: false },
  },
  data() {
    this.flattenRecursive = flattenRecursive
    return {
      currentOptions: CloneDeep(this.options),
      searchTerm: '',
      isDropdownOpen: false,
    }
  },
  computed: {
    ...authComputed,
    popoverPlacment() {
      return this.isRtl ? 'bottomRight' : 'bottomLeft'
    },
    selectedItem() {
      if (this.multiple) {
        return this.value || []
      } else {
        if (this.value || this.value === 0) {
          return findValueObject(this.options, this.value)
        }
      }
      return undefined
    },
    displayFullPath() {
      if (this.fullPath && this.value && !this.multiple) {
        const path = findValuePathWithItems(this.options, this.value)
        return path.map((p) => p.name).join(' > ')
      }
      return null
    },
    listeners() {
      const { change, hide, show, click, ...listeners } = this.$listeners
      return listeners
    },
  },
  watch: {
    searchTerm(newValue) {
      if (!this.isLargDataSet) {
        if (newValue) {
          this.currentOptions = searchRecursive(newValue, this.options)
        } else {
          if (this.value) {
            this.currentOptions = expandeSelected(this.value, this.options)
          } else {
            this.currentOptions = collapseAll(this.options)
          }
        }
      }
    },
    currentOptions(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.dispatchEvent(new Event('resize'))
      }
    },
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.currentOptions = expandeSelected(newValue, this.options)
        } else {
          this.currentOptions = collapseAll(this.options)
        }
      },
    },
  },
  created() {
    if (this.focusEventBrodcast) {
      const openPopover = (id) => {
        if (id === this.$attrs.id) {
          this.handleShow(true)
        } else {
          this.handleHide(true)
        }
      }
      const closePopover = (id) => {
        if (id === this.$attrs.id) {
          this.handleHide(true)
        }
      }
      Bus.$on('app:popover:broadcast:open', openPopover)
      Bus.$on('app:popover:broadcast:close', closePopover)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('app:popover:broadcast:open', openPopover)
        Bus.$off('app:popover:broadcast:close', closePopover)
      })
    }
    if (this.value && this.validateArchivedValue) {
      const flattenedOptions = flattenRecursive(this.currentOptions)
      if (this.multiple) {
        const optionsIds = flattenedOptions.map((o) => o.id)
        const selected = this.value.filter((v) => optionsIds.indexOf(v) >= 0)
        if (this.value.length !== selected.length) {
          this.$emit('change', selected)
        }
      } else {
        const selected = flattenedOptions.find((f) => f.id === this.value)
        if (!selected) {
          this.$emit('change', 0)
        }
      }
    }
  },
  methods: {
    popupContainer() {
      const element = this.$attrs['get-popup-container']
        ? this.$attrs['get-popup-container']()
        : this.focusEventBrodcast
        ? this.$el.closest('.single-control')
        : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    },
    handleHide(skipBroadcast = false) {
      this.$emit('hide')
      if (this.$refs.dropdownPopoverRef) {
        this.$refs.dropdownPopoverRef.hide()
      }
      setTimeout(() => {
        this.isDropdownOpen = false
      }, 350)
      if (this.focusEventBrodcast && this.$attrs.id && !skipBroadcast) {
        Bus.$emit('app:single:dropdown:close', this.$attrs.id)
      }
    },
    handleShow(skipBroadcast = false) {
      if (this.disabled) {
        return
      }
      this.$emit('show')
      this.isDropdownOpen = true
      if (this.focusEventBrodcast && this.$attrs.id && !skipBroadcast) {
        Bus.$emit('app:single:dropdown:open', this.$attrs.id)
      }
      this.searchTerm = ''
      setTimeout(() => {
        this.$refs.searchBox && this.$refs.searchBox.focus()
      }, 100)
    },
    handleToggle() {
      if (this.isDropdownOpen) {
        this.handleHide()
      } else {
        this.handleShow()
        if (this.$refs.dropdownPopoverRef) {
          this.$refs.dropdownPopoverRef.show()
        }
      }
    },
    changeHandler(item) {
      // check leaf node only selection
      if (this.isDisabled(item) || this.isSelected(item)) {
        return
      }
      if (this.multiple) {
        const flattenedOptions = this.flattenRecursive(this.currentOptions)
        this.$emit(
          'selected',
          [...(this.value || []), item.id].map((id) =>
            flattenedOptions.find((o) => o.id === id)
          )
        )
        this.$emit('change', [...(this.value || []), item.id])
        this.$emit('blur')
      } else {
        this.$emit('selected', item)
        this.$emit('change', item.id || this.unassignedValue)
        this.$emit('blur')
      }
      if (!this.multiple) {
        this.handleHide()
      }
    },
    isSelected(option) {
      const value = this.value
      if (!value) {
        return false
      }
      return Array.isArray(value)
        ? value.indexOf(option.id) >= 0
        : value === option.id
    },
    isDisabled(option) {
      return (option.children || []).length && this.onlyLeafNodeSelectable
    },
    handleChange(value) {
      const flattenedOptions = this.flattenRecursive(this.currentOptions)
      if (this.multiple) {
        this.$emit(
          'selected',
          value.map((id) => flattenedOptions.find((o) => o.id === id))
        )
        this.$emit('change', value)
        this.$emit('blur')
      } else {
        this.$emit(
          'selected',
          flattenedOptions.find((o) => o.id === value)
        )
        this.$emit('change', value || this.unassignedValue)
        this.$emit('blur')
      }
      if (!this.multiple) {
        this.handleHide()
      }
    },
  },
}
</script>
<style lang="less">
.tree-list-div {
  .hierarchy-item {
    &:hover,
    &.active {
      color: var(--dropdown-selected-text);
      background: var(--dropdown-hover-bg);
    }

    &.disabled {
      background: unset;
      opacity: 0.5;
    }
  }
}
</style>
