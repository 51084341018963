var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FlotoScrollDropdown',_vm._g(_vm._b({ref:"dropdownRef",attrs:{"avoid-keyboard-navigation":"","overlay-class-name":"picker-overlay wide-picker-overlay","disabled":_vm.disabled},on:{"show":_vm.handleShow},scopedSlots:_vm._u([{key:"trigger",fn:function(triggerSlotData){return [(_vm.multiple)?_c('MultipleTrigger',_vm._b({attrs:{"toggle":triggerSlotData.toggle,"allow-clear":_vm.allowClear,"as-input":_vm.asInput,"text-only":_vm.textOnly,"focus-event-brodcast":false,"selected-items":_vm.selectedItemFormMultiple,"disabled":_vm.disabled,"as-tag":_vm.asTag,"placeholder":_vm.$t('select'),"options":_vm.options},on:{"change":_vm.handleChange}},'MultipleTrigger',_vm.attrs,false)):_c('DropdownTrigger',{attrs:{"toggle":triggerSlotData.toggle,"as-input":_vm.asInput,"text-only":_vm.textOnly,"allow-clear":_vm.allowClear,"disabled":_vm.disabled,"selected-item":_vm.selectedItem,"placeholder":_vm.$t('select')},on:{"reset":function($event){return _vm.handleChange(undefined)}}})]}}])},'FlotoScrollDropdown',_vm.attrs,false),_vm.listeners),[_c('div',{staticClass:"p-2 pb-0"},[_c('FlotoFormItem',{ref:"searchBox",staticClass:"search-box",attrs:{"auto-focus":false,"placeholder":_vm.$t('search')},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('MIcon',{attrs:{"name":"search"}})]},proxy:true}]),model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('FlotoScrollView',_vm._l((_vm.groupedOptions),function(group,key){return _c('div',{key:key},[_c('MMenu',{class:_vm.menuClass},[_c('MMenuItem',{class:{ 'cursor-default': true, [_vm.menuItemSelectedClass]: true }},[_c('h6',{staticClass:"py-0 my-0"},[_vm._v(_vm._s(_vm.$tc(key)))])]),_vm._l((group),function(item,index){return _c('MMenuItem',{key:item.key,class:{
            'scroll-dropdown-menu-item': true,
            [_vm.menuItemClass]: true,
            [_vm.menuItemDisabledClass]: item.disabled,
            [_vm.menuItemSelectedClass]: Array.isArray(_vm.value)
              ? _vm.value.indexOf(item.key) >= 0
              : _vm.value === item.key,
            'value-active-item': Array.isArray(_vm.value)
              ? _vm.value.indexOf(item.key) >= 0
              : _vm.value === item.key,
          },on:{"click":function($event){return _vm.selectItem(item)}}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }