<template>
  <FlotoContentLoader :loading="assetLoading">
    <FlotoForm ref="formRef" @submit="handleFormSubmitted">
      <MRow :gutter="0">
        <MCol :size="12">
          <MRow :gutter="0" class="flex-1">
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    v-if="moduleName === $constants.ASSET"
                    id="name-input"
                    v-model="formValue.displayName"
                    auto-focus
                    :label="$t('name')"
                    :placeholder="$t('name')"
                    rules="required|short_text"
                  />
                  <FlotoFormItem
                    v-if="moduleName === $constants.CMDB"
                    id="name-input"
                    v-model="formValue.ciDisplayName"
                    auto-focus
                    :label="$t('name')"
                    :placeholder="$t('name')"
                    rules="required|short_text"
                  />
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    v-if="moduleName === $constants.ASSET"
                    id="asset-type-picker"
                    :label="$tc('asset_type')"
                    rules="required|nonzero"
                  >
                    <FlotoAssetTypePicker
                      v-model="formValue.assetTypeId"
                      as-input
                      :available-asset-type="availableAssetType"
                    />
                  </FlotoFormItem>
                  <FlotoFormItem
                    v-if="moduleName === $constants.CMDB"
                    id="ci-type-picker"
                    :label="$tc('ci_type')"
                    rules="required|nonzero"
                  >
                    <FlotoCITypePicker v-model="formValue.ciTypeId" as-input />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="product-picker" :label="$tc('product')">
                    <ProductPicker v-model="formValue.productId" />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="managed-by-group-picker"
                    :label="`${$tc('managed_by')} ${$tc('group')}`"
                  >
                    <FlotoTechnicianGroupPicker
                      v-model="formValue.managedByGroupId"
                      mandatory-selection
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="managed-by-picker"
                    :label="$tc('managed_by')"
                  >
                    <FlotoTechnicianPicker
                      v-model="formValue.managedById"
                      mandatory-selection
                      change-technician-on-group-change
                      :group-id="formValue.managedByGroupId"
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="department-picker"
                    :label="$tc('department')"
                  >
                    <FlotoDepartmentPicker
                      v-model="formValue.assignedDepartmentId"
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isSoftware" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="location-picker" :label="$tc('location')">
                    <FlotoLocationPicker v-model="formValue.locationId" />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    v-if="moduleName === $constants.ASSET"
                    id="asset-group-picker"
                    :label="$tc('asset_group')"
                  >
                    <AssetGroupPicker
                      v-model="formValue.assetGroupId"
                      :module-name="moduleName"
                    />
                  </FlotoFormItem>
                  <FlotoFormItem
                    v-if="moduleName === $constants.CMDB"
                    id="ci-group-picker"
                    :label="$tc('ci_group')"
                  >
                    <AssetGroupPicker
                      v-model="formValue.ciGroupId"
                      :module-name="moduleName"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isSoftware && !isConsumable" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="used-by-picker" :label="$tc('used_by')">
                    <FlotoRequesterPicker
                      v-model="formValue.assignedUserIds"
                      multiple
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol
              v-if="!isConsumable && moduleName !== $constants.CMDB"
              :size="6"
            >
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="acquition-date-picker"
                    :label="`${$t('acquition')} ${$t('date')}`"
                  >
                    <FlotoDatePicker
                      v-model="formValue.acquitionTime"
                      :show-time="false"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isSoftware && !isConsumable" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="assignment-date-picker"
                    :label="`${$t('assignment')} ${$t('date')}`"
                  >
                    <FlotoDatePicker
                      v-model="formValue.assignmentTime"
                      :show-time="false"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isConsumable" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="status-picker" :label="$tc('status')">
                    <FlotoStatusPicker
                      v-if="moduleName === $constants.ASSET"
                      v-model="formValue.statusId"
                      :allow-clear="false"
                      mandatory
                      :module-name="moduleName"
                      as-input
                    />
                    <FlotoStatusPicker
                      v-if="moduleName === $constants.CMDB"
                      v-model="formValue.ciStatusId"
                      :allow-clear="false"
                      mandatory
                      :module-name="moduleName"
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isConsumable" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="impact-picker" :label="$tc('impact')">
                    <FlotoImpactPicker
                      v-model="formValue.impactId"
                      as-input
                      :allow-clear="false"
                      mandatory
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="moduleName !== $constants.CMDB" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="category-picker" :label="$tc('category')">
                    <FlotoCategoryPicker
                      v-model="formValue.categoryId"
                      :module-name="$constants.ASSET"
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol
              v-if="!isConsumable && moduleName !== $constants.CMDB"
              :size="6"
            >
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="barcode-input"
                    v-model="formValue.barCode"
                    :label="$tc('barcode')"
                    :placeholder="$tc('barcode')"
                  />
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="enableMsp" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="company-picker" :label="$tc('company')">
                    <CompanyPicker v-model="formValue.companyId" as-input />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="tags-picker" :label="$tc('tag', 2)">
                    <FlotoTagsPicker v-model="formValue.tags" />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="description-input"
                    :label="$tc('description')"
                    rules="description"
                  >
                    <FlotoRichEditor
                      v-model="formValue.description"
                      :affix-toolbar="false"
                      :min-rows="8"
                      :placeholder="$tc('description')"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol>
                  <component
                    :is="useFormHelpterProvider ? 'FormHelperProvider' : 'div'"
                  >
                    <FormConsumer
                      v-if="customFieldsForAll.length"
                      ref="formConsumerRef"
                      v-model="fieldValueDetails"
                      :module-name="moduleName"
                      :apply-form-rules="applyFormRules"
                      :form-fields="customFieldsForAll"
                      :avoid-default-value="Boolean(formValue.id)"
                      :focus-event-brodcast="false"
                      :with-submit="false"
                      @submit="handleFormConsumerSubmitFn"
                    />
                  </component>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem :label="$tc('attachment', 2)">
                    <FlotoAttachment v-model="formValue.fileAttachments" />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
          </MRow>
          <div
            v-if="
              (formValue.assetTypeId || formValue.ciTypeId) &&
              customFields.length
            "
          >
            <div
              v-for="assetTypeField in selectedAssetTypes.list"
              :key="assetTypeField.id"
            >
              <template
                v-if="
                  (availableCustomFieldForAssetType[assetTypeField.id] || [])
                    .length
                "
              >
                <MDivider dashed />
                <div class="mx-2 text-primary md-text-base my-2">
                  {{ assetTypeField.name }}
                </div>
                <FormConsumer
                  v-if="
                    (availableCustomFieldForAssetType[assetTypeField.id] || [])
                      .length
                  "
                  :key="assetTypeFormConsumerRenderCount"
                  v-model="formValue.fieldValueDetailsForAssetType"
                  :form-fields="
                    availableCustomFieldForAssetType[assetTypeField.id]
                  "
                  :avoid-default-value="Boolean(formValue.id)"
                  :with-submit="false"
                  :focus-event-brodcast="false"
                />
              </template>
            </div>
          </div>
        </MCol>
      </MRow>
      <MDivider class="mt-0" />
      <template v-slot:submit>
        <span />
      </template>
    </FlotoForm>
  </FlotoContentLoader>
</template>

<script>
import { FormComputed } from '@state/modules/form'
import FormConsumer from '@components/form-consumer.vue'
import ProductPicker from '@components/data-picker/product-picker'
import AssetGroupPicker from '@components/data-picker/asset-group-picker'
import { AssetTypeComputed } from '@state/modules/asset-type'
import { CITypeComputed } from '@state/modules/ci-type'
import { findValuePathWithItems } from '@data/recursive'
import { assetTypeModuleMap, assetTypeRest } from '../helpers/asset-type'
import {
  customFieldsForAllAssetType,
  customFieldAttributesTypePrefix,
  customFieldAttributesModuleName,
  customFieldForAssetType,
} from '../helpers/asset-custom-fields'
import CompanyPicker from '@components/data-picker/company-picker'
import { MspConfigComputed } from '@state/modules/msp-config/helpers'
import FormHelperProvider from '@components/form-helper/form-helper-provider.vue'

export default {
  name: 'AssetForm',
  components: {
    FormConsumer,
    ProductPicker,
    AssetGroupPicker,
    CompanyPicker,
    FormHelperProvider,
  },
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    processing: { type: Boolean, deafult: false },
    // eslint-disable-next-line
    withSubmit: { type: Boolean, default: true },
    availableAssetType: { type: [String, Array], default: undefined },
    moduleName: {
      type: String,
      default() {
        return this.$constants.ASSET
      },
    },
    applyFormRules: { type: Boolean, default: false },
    useFormHelpterProvider: { type: Boolean, default: false },
  },
  data() {
    return {
      internalProcessing: false,
      // @TODO set default value manualy for custom fields
      asset: Object.assign({}, this.value),
      assetTypeFormConsumerRenderCount: 1,
    }
  },
  computed: {
    ...FormComputed,
    ...AssetTypeComputed,
    ...CITypeComputed,
    ...MspConfigComputed,
    formValue: {
      get() {
        if (this.withSubmit) {
          return this.asset
        }
        return this.value
      },
      set(value) {
        if (this.withSubmit) {
          this.asset = value
        } else {
          this.$emit('change', value)
        }
      },
    },
    fieldValueDetails: {
      get() {
        return this.formValue.fieldValueDetails
      },
      set(value) {
        this.formValue = {
          ...this.formValue,
          fieldValueDetails: {
            ...this.formValue.fieldValueDetails,
            ...value,
          },
        }
      },
    },
    customFields() {
      const typePrefix = customFieldAttributesTypePrefix(this.moduleName)
      return (
        ((this.assetForm || {}).fields || []).filter(
          (f) =>
            f.attributes.model ===
              customFieldAttributesModuleName(this.moduleName) &&
            !f.attributes[`${typePrefix}PropertyType`] &&
            !f.attributes[`${typePrefix}ComponentType`]
        ) || []
      )
    },
    selectedAssetTypes() {
      const typePrefix = customFieldAttributesTypePrefix(this.moduleName)
      if (this.formValue[`${typePrefix}TypeId`]) {
        const collection = this[`${typePrefix}Types`]
        const assetTypeId = this.formValue[`${typePrefix}TypeId`]
        const path = findValuePathWithItems(collection, assetTypeId)
        const rootItem = path[0]
        return {
          list: path,
          ...rootItem,
          assetTypeRest: assetTypeModuleMap[rootItem.systemName],
        }
      }
      return {}
    },
    isSoftware() {
      if (this.selectedAssetTypes.assetTypeRest === assetTypeRest.SOFTWARE) {
        return true
      }
      return false
    },
    isConsumable() {
      if (this.selectedAssetTypes.assetTypeRest === assetTypeRest.CONSUMABLE) {
        return true
      }
      return false
    },
    customFieldsForAll() {
      return customFieldsForAllAssetType(this.customFields, this.moduleName)
    },
    availableCustomFieldForAssetType() {
      const typePrefix = customFieldAttributesTypePrefix(this.moduleName)
      if (!this.formValue[`${typePrefix}TypeId`]) {
        return {}
      }
      const fullPath = this.selectedAssetTypes.list
      const assetTypeFields = {}
      fullPath.forEach((p) => {
        assetTypeFields[p.id] = customFieldForAssetType(
          this.moduleName,
          this.customFields,
          [p.id]
        )
      })
      return assetTypeFields
    },
    globalProcessing() {
      return this.processing || this.internalProcessing
    },
  },
  watch: {
    'formValue.assetTypeId': function (newValue, preValue) {
      if (newValue !== preValue) {
        this.$nextTick(() => {
          this.formValue = {
            ...this.formValue,
            type: this.selectedAssetTypes.assetTypeRest,
            fieldValueDetailsForAssetType: {},
            fieldValueDetails: {
              ...this.formValue.fieldValueDetails,
              assetTypeId: newValue,
            },
          }
          this.handleAssetTypeUpdate({ assetTypeId: newValue })
        })
      }
    },
    'formValue.ciTypeId': function (newValue, preValue) {
      if (newValue !== preValue) {
        this.$nextTick(() => {
          this.formValue = {
            ...this.formValue,
            type: assetTypeModuleMap.Hardware,
            fieldValueDetailsForAssetType: {},
          }
        })
      }
    },
  },
  methods: {
    handleAssetTypeUpdate(change) {
      this.assetTypeFormConsumerRenderCount++
      if (!this.applyFormRules) {
        return
      }
      if (this.$refs.formConsumerRef) {
        setTimeout(() => {
          this.$refs.formConsumerRef.handleFieldBlurExternal(change)
        }, 100)
      }
    },
    handleFormConsumerSubmitFn(fieldValueDetails) {
      this.$emit('submit', {
        ...this.formValue,
        fieldValueDetails: {
          ...this.formValue.fieldValueDetails,
          ...fieldValueDetails,
        },
      })
    },
    handleFormSubmitted() {
      // const data = {
      //   type: this.selectedAssetTypes.assetTypeRest,
      //   ...this.formValue,
      // }
      if (this.applyFormRules) {
        if (this.$refs.formConsumerRef) {
          this.$refs.formConsumerRef.handleSubmit()
        } else {
          this.$emit('submit', this.formValue)
        }
      } else {
        this.$emit('submit', this.formValue)
      }
    },
    // handleFieldValueChange(c) {
    //   this.formValue = {
    //     ...this.formValue,
    //     fieldValueDetails: {
    //       ...this.formValue.fieldValueDetails,
    //       ...c,
    //     },
    //   }
    // },
    submit() {
      this.$refs.formRef.submit()
    },
  },
}
</script>
