<template>
  <component
    :is="
      showAllCurrencyOptions || disabled || multiple || valueInput
        ? 'FlotoDropdownPicker'
        : 'MSelect'
    "
    v-bind="$attrs"
    :value="value"
    :options="options"
    :disabled="
      disabled ||
      (!showAllCurrencyOptions &&
        options.length <= 1 &&
        !multiple &&
        !valueInput)
    "
    :multiple="multiple"
    :mandatory="mandatory"
    :not-found-content="null"
    :searchable="searchable || multiple"
    :style="disabled ? { whiteSpace: 'pre-wrap' } : {}"
    @change="handleChange"
  />
</template>

<script>
import IsEqual from 'lodash/isEqual'
import { OrganizationComputed } from '@state/modules/organization'
import { CurrencyComputed } from '@state/modules/currency'
import { fetchAllCurrencyApi } from '@state/modules/currency/api'

export default {
  name: 'CurrencyPicker',
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: [Number, String, Object, Array, Boolean],
      default: undefined,
    },
    showAllCurrencyOptions: { type: Boolean, default: false },
    // eslint-disable-next-line
    mandatory: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    valueInput: { type: Boolean, default: false },
  },
  data() {
    return {
      enabledCurrencyOptions: [],
      archivedOptions: [],
      allCurrencyOptions: [],
    }
  },
  computed: {
    ...CurrencyComputed,
    ...OrganizationComputed,
    options() {
      const allCurrencyOptions = this.allCurrencyOptions.map((c) => ({
        id: c.id,
        key: c.id,
        text: `${c.name} - ${c.currencyFullName}`,
        enabled: c.enabled,
      }))
      if (this.showAllCurrencyOptions) {
        return allCurrencyOptions
      }
      return this.enabledCurrencyOptions.concat(this.archivedOptions)
    },
  },
  watch: {
    options: {
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.setDefaultValue()
        }
      },
    },
    currencyOptions: {
      immediate: true,
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.enabledCurrencyOptions = this.currencyOptions
            .filter((f) => {
              return this.enabledCurrency.indexOf(f.id) >= 0
            })
            .map((c) => ({
              id: c.id,
              key: c.id,
              text: c.name,
            }))
        }
      },
    },
  },
  created() {
    if (this.showAllCurrencyOptions) {
      this.fetchAllCurrency()
    }
    if (this.value) {
      const v = Array.isArray(this.value) ? this.value : [this.value]
      const allEnabledCurrencyIds = this.enabledCurrencyOptions.map((u) => u.id)
      const missingItems = v.filter(
        (id) => allEnabledCurrencyIds.indexOf(id) === -1
      )
      if (missingItems.length) {
        this.fetchMissingItems(missingItems)
      }
    } else {
      this.setDefaultValue()
    }
  },
  methods: {
    setDefaultValue() {
      if (
        !this.multiple &&
        !this.value &&
        this.mandatory &&
        !this.showAllCurrencyOptions
      ) {
        let defaultValue = this.options[0]
        if (defaultValue) {
          this.$emit('change', defaultValue.key)
          this.$emit('set-default-value', defaultValue.key)
        }
      }
    },
    handleChange(value) {
      if (value === undefined || value === '') {
        this.$emit('change', undefined)
      }
      this.$emit('change', value)
    },
    fetchMissingItems(missingItems) {
      this.archivedOptions = this.currencyOptions
        .filter((c) => {
          return missingItems.indexOf(c.id) >= 0
        })
        .map((m) => ({
          id: m.id,
          key: m.id,
          text: m.name,
          name: m.name,
        }))
    },
    fetchAllCurrency() {
      fetchAllCurrencyApi().then((data) => {
        this.allCurrencyOptions = data
      })
    },
  },
}
</script>
