import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'
const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const applicationPropertyGroupFieldMap = () => ({
  application_property_group: {
    title: __mtc('application_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'ports',
        name: __mtc('ports'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'version',
        name: __mtc('version'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'parentCi',
        name: __mtc('parent_ci'),
        inputType: 'ciPicker',
      },
    ],
  },
})
