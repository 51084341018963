var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FlotoDrawer',{attrs:{"open":_vm.isOpen,"scrolled-content":false},on:{"hide":function($event){_vm.isOpen = false}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{staticClass:"text-neutral md-text-base",attrs:{"variant":"neutral-light","shadow":false,"shape":"circle"},on:{"click":function($event){_vm.isOpen = true}}},[_c('MIcon',{attrs:{"name":"keyboard"}})],1)]},proxy:true},{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shortcutHelpers.view_available'))+" ")]},proxy:true},{key:"actions",fn:function({ hide }){return [_c('MButton',{attrs:{"variant":"default"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]}}])},[_c('MRow',{staticClass:"h-100"},[_c('MCol',{staticClass:"flex flex-col h-100"},[_c('MRow',[_c('MCol',[_c('div',{staticClass:"text-neutral"},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t('enable'))+" "+_vm._s(_vm.$tc('shortcutHelpers.keyboard_shortcuts'))+" ")]),_c('MSwitch',{attrs:{"size":"small","checked":_vm.shortcutEnabled},on:{"change":_vm.handleToggleShortcuts}})],1)])],1),_c('MDivider',{staticClass:"my-2"}),_c('MRow',{staticClass:"min-h-0"},[_c('MCol',{staticClass:"flex flex-col h-100"},[_c('FlotoScrollView',[_c('table',{staticClass:"item-list-table w-full"},[_c('thead',[_c('tr',[_c('td',{staticClass:"sticky"},[_vm._v(" "+_vm._s(_vm.$tc('shortcut'))+" ")]),_c('td',{staticClass:"sticky"},[_vm._v(_vm._s(_vm.$t('description')))])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"sticky header-column",staticStyle:{"top":"30px"},attrs:{"colspan":"2"}},[_c('b',[_vm._v(_vm._s(_vm.$t('shortcutHelpers.navigation')))])])]),_vm._l((_vm.navigationShortcuts),function(shortcut,index){return _c('tr',{key:`${index}-navigation`},[_c('td',{domProps:{"innerHTML":_vm._s(shortcut.keyDescription)}}),_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(shortcut.description)+" ")])])}),(
                    _vm.hasRequestModule ||
                    _vm.hasProblemModule ||
                    _vm.hasChangeModule ||
                    (_vm.hasAssetModule && !_vm.isPortalLogin)
                  )?_c('tr',[_c('td',{staticClass:"sticky header-column",staticStyle:{"top":"30px"},attrs:{"colspan":"2"}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t( `shortcutHelpers.${ _vm.isPortalLogin ? 'grid-helpers' : 'grid-split-helpers' }`, { resource: _vm.isPortalLogin ? _vm.$tc('request') : '', } ))+" ")])])]):_vm._e(),(
                    _vm.hasRequestModule ||
                    _vm.hasProblemModule ||
                    _vm.hasChangeModule ||
                    (_vm.hasAssetModule && !_vm.isPortalLogin)
                  )?_vm._l((_vm.listViewShortcuts),function(shortcut,index){return _c('tr',{key:`${index}-list-view`},[_c('td',{domProps:{"innerHTML":_vm._s(shortcut.keyDescription)}}),_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(shortcut.description)+" ")])])}):_vm._e(),(
                    _vm.hasRequestModule || _vm.hasProblemModule || _vm.hasChangeModule
                  )?_c('tr',[_c('td',{staticClass:"sticky header-column",staticStyle:{"top":"30px"},attrs:{"colspan":"2"}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t('shortcutHelpers.detailed-view-helpers', { resource: _vm.isPortalLogin ? _vm.$tc('request') : _vm.licenseWiseText(true), }))+" ")])])]):_vm._e(),(
                    _vm.hasRequestModule || _vm.hasProblemModule || _vm.hasChangeModule
                  )?_vm._l((_vm.detailedViewShortcuts),function(shortcut,index){return _c('tr',{key:`${index}-detail-view`},[_c('td',{domProps:{"innerHTML":_vm._s(shortcut.keyDescription)}}),_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(shortcut.description)+" ")])])}):_vm._e()],2)])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }